(function () {
  'use strict';

  angular.module('erpRetail')
    .run(/*@ngInject*/ function (WidgetsFactory) {
      WidgetsFactory.register('loja-classificadores', 'loja-classificadores-edit', 'Loja classificadores');
    })
    .component('lojaClassificadores', {
      bindings: {
        item: '=',
        properties: '<?'
      },
      // templateUrl: 'app/components/widgets/lojaclassificadores/lojaclassificadores.html',
      templateUrl: 'widgets.lojaClassificadores',
      controller: /*@ngInject*/ function ($q, $scope, $filter, $state, configFactory, $rootScope, storage, plStorage, plCompsService, PopAlert, cgClass, ProdutosFactory,
                                          $localStorage, $stateParams) {
        var ctrl = this;
        var defaultOptions = {};
        var state = $state.current.name;
        var sessionSearch = state + '(search)';
        var sessionQ = state + '(q)';
        var sessionCurrentPage = state + '(currentPage)';
        var sessionSortOrder = state + '(sortorder)';
        var sessionLvClassificador = state + '(viewClassificador)';


        ctrl.stateParams = $stateParams;
        ctrl.options = {};
        ctrl.session = plStorage('session')('loja');
        ctrl.session.set('loja.classificador(currentPage)', 1);
        ctrl.classificadorList = [];
        ctrl.sortList = [];
        ctrl.promise = null;

        ctrl.ctrl = {};
        ctrl.produtos = {};
        ctrl.pageSize = 8;
        if ($localStorage.pageSize > 0)
          ctrl.pageSize = $localStorage.pageSize;

        ctrl.itemsPerRow = 4;
        ctrl.paginationOptions = {
          perPage: ctrl.pageSize,
          paginationRange: 8,
          lines: [4, 8, 12, 16, 20, 24]
        };
        ctrl.offset = 0;

        ctrl.search = ctrl.session.get(sessionSearch, ctrl.search);
        ctrl.filtrosEmpty = {};
        ctrl.filtros = angular.copy(ctrl.filtrosEmpty);

        ctrl.classificadoresList = configFactory.classificadoresList;
        ctrl.classificadores = configFactory.classificadores;
        ctrl.classificadorName = '';
        ctrl.classificador = '';

        ctrl.q = ctrl.session.get(sessionQ, ctrl.q);
        ctrl.currentPage = ctrl.session.get(sessionCurrentPage, ctrl.currentPage);
        ctrl.sortorder = ctrl.session.get(sessionSortOrder, ctrl.sortorder);
        ctrl.listViewClassificador = ctrl.session.get(sessionLvClassificador, 'box');

        ctrl.anexosClassificadores = null;
        ctrl.promiseAnexosClassificadores = null;

        ctrl.$onInit = init;
        ctrl.getTags = getTags;
        ctrl.loadClassificadorList = loadClassificadorList;
        ctrl.setListViewClassificador = setListViewClassificador;
        ctrl.getClassificador = getClassificador;
        ctrl.sortOrderChanged = sortOrderChanged;
        ctrl.getClassificadorCaption = getClassificadorCaption;
        ctrl.findSearchTags = findSearchTags;
        ctrl.filtroAdd = filtroAdd;
        ctrl.filtroRemove = filtroRemove;
        ctrl.filtroTagRemove = filtroTagRemove;
        ctrl.getFiltro = getFiltro;
        ctrl.orderFiltro = orderFiltro;
        ctrl.onPageChange = onPageChange;
        ctrl.loadTags = loadTags;
        ctrl.doSearch = doSearch;
        ctrl.shouldAffix = shouldAffix;
        ctrl.loadAnexos = loadAnexos;
        ctrl.clearFiltro = clearFiltro;
        ctrl.pesquisaTodos = pesquisaTodos;
        ctrl.loadAnexosClassificadores = loadAnexosClassificadores;
        ctrl.menuBuild = menuBuild;
        ctrl.menuBuild();
        $scope.$on('menu-produtos-classificadores', function (event, data) {
          ctrl.clearFiltro();
          ctrl.filtroAdd({tipo: data.classificadorTipo}, {key: data.id, value: data.name});
        });

        function menuBuild() {
          configFactory.getMenuBuild().then(function (data) {
              ctrl.menuProdutos = data;
            }
          );
        }

        function pesquisaTodos() {
          clearFiltro();
          pesquisaArtigos();
        }
          /////////////
        function clearFiltro() {
          ctrl.search = {
            familias: [],
            subFamilias: [],
            grandesFamilias: [],
            classes: [],
            categorias: [],
            tipos: [],
            tags: []
          };
          ctrl.currentPage = 1;
        }

        function processaClassificadores() {
          ctrl.filtros = angular.copy(ctrl.filtrosEmpty);
          var familias = ctrl.produtos.classificadores.familia;
          var subFamilias = ctrl.produtos.classificadores.subfa;
          var grandesFamilias = ctrl.produtos.classificadores.grandefamilia;
          var classes = ctrl.produtos.classificadores.classe;
          var categorias = ctrl.produtos.classificadores.categoria;
          var tipos = ctrl.produtos.classificadores.tipo;
          var tags = ctrl.produtos.classificadores.tags;
          var i;

          for (i = 0; i < familias.length; i++) {
            if (familias[i].nfamilia > 0 && familias[i].nome) {
              ctrl.filtros.familias.push({
                key: familias[i].nfamilia,
                value: familias[i].nome
              });
            }
          }

          for (i = 0; i < subFamilias.length; i++) {
            ctrl.filtros.subFamilias.push({
              key: subFamilias[i].nsubFa,
              value: subFamilias[i].nome
            });
          }

          for (i = 0; i < classes.length; i++) {
            ctrl.filtros.classes.push({
              key: classes[i].classe,
              value: classes[i].descricao
            });
          }

          for (i = 0; i < tipos.length; i++) {
            ctrl.filtros.tipos.push({
              key: tipos[i].codTpArt,
              value: tipos[i].nome
            });
          }

          for (i = 0; i < grandesFamilias.length; i++) {
            ctrl.filtros.grandesFamilias.push({
              key: grandesFamilias[i].ndepart,
              value: grandesFamilias[i].nome
            });
          }

          for (i = 0; i < categorias.length; i++) {
            if (categorias[i].descricao)
              ctrl.filtros.categorias.push({
                key: categorias[i].categoria,
                value: categorias[i].descricao
              });
          }

          //Os tags funcionam de forma diferente.
          //existe um dicionario em que a chave é o campo tagDefId (ctrl.artigoTags) e cada valor tem o array com as tags dessa definição

          for (i = 0; i < tags.length; i++) {
            var item = tags[i];
            var existe = false;
            var myNewItem = ctrl.filtros.tags[item.tagDefId];
            if (angular.isUndefined(myNewItem)) {
              myNewItem = {
                key: item.tagDefId,
                value: []
              };
              ctrl.filtros.tags[item.tagDefId] = myNewItem;
            }
            var stag = ctrl.findSearchTags({
              key: item.tagDefId,
              name: item.valor
            });
            for (var j = 0; j < item.list.length; j++) {
              var myTagValue = item.list[j];
              existe = false;
              if (stag)
                for (var n = 0; n < stag.values.length; n++) {
                  if (stag.values[n].value === myTagValue.valor) {
                    existe = true;
                    break;
                  }
                }
              if (existe) continue;
              var t = {
                key: j + 1,
                value: myTagValue.valor
              };
              myNewItem.value.push(t);
            }
          }
          ctrl.loadClassificadorList();
        }

        function getTags(id) {
          var myItem = ctrl.filtros.tags[id];
          if (myItem) {
            return myItem.value;
          }
          return null;
        }

        function getSearch() {
          var ret = angular.copy(ctrl.filtrosEmpty);
          //familias: [], subFamilias: [], grandesFamilias: [], classes: [], categorias: [], tipos: [], tags: []
          ctrl.search.familias.forEach(function (item) {
            ret.familias.push(item.key);
          });
          ctrl.search.subFamilias.forEach(function (item) {
            ret.subFamilias.push(item.key);
          });
          ctrl.search.grandesFamilias.forEach(function (item) {
            ret.grandesFamilias.push(item.key);
          });
          ctrl.search.classes.forEach(function (item) {
            ret.classes.push(item.key);
          });
          ctrl.search.categorias.forEach(function (item) {
            ret.categorias.push(item.key);
          });
          ctrl.search.tipos.forEach(function (item) {
            ret.tipos.push(item.key);
          });
          ctrl.search.tags.forEach(function (item) {
            var o = {
              id: item.key,
              name: item.value,
              tags: []
            };
            item.values.forEach(function (tag) {
              o.tags.push({
                name: tag.value
              });
            });
            if (o.tags.length > 0) {
              ret.tags.push(o);
            }

          });

          return ret;
        }

        function pesquisaArtigos() {
          ctrl.offset = 0;
          if (ctrl.currentPage > 1) {
            ctrl.offset = (ctrl.currentPage - 1) * ctrl.pageSize;
          }

          ctrl.session.set(sessionSearch, ctrl.search);
          ctrl.session.set(sessionQ, ctrl.q);
          ctrl.session.set(sessionCurrentPage, ctrl.currentPage);
          ctrl.session.set(sessionSortOrder, ctrl.sortorder);
          $localStorage.pageSize = ctrl.pageSize;

          var search = getSearch();

          var r = storage.pesquisa(ctrl.q, null, null, search.familias, search.subFamilias, search.grandesFamilias, search.classes,
            search.categorias, search.tipos, ctrl.sortorder, ctrl.offset, ctrl.pageSize, false, null, null, false, search.tags, true, ctrl.classificador);
          r.then(function (obj) {
            ctrl.produtos = obj.data;
            ctrl.produtos.data = ctrl.produtos.data.map(function (produto) {
              produto.qtd = produto.qtdMinEncome || 1;
              produto.collapsed = true;
              return produto;
            });
            processaClassificadores();
          })['finally'](function () {
            ctrl.promise = null;
          });
          ctrl.promise = r;
          return r;
        }

        function init() {
          ctrl.affix = {
            enabled: ctrl.shouldAffix(),
            style: {
              top: '1px',
              bottom: '1px'
            }
          };


          configFactory.getConfigSite().then(
            function (data) {
              ctrl.ctrl.config = data;
              ctrl.itemsPerRow = parseInt(data.siteLojaItemsPerRow) || 4;
              if (ctrl.item)
                ctrl.classificadorName = ctrl.item.data.classificador0;
              else
                ctrl.classificadorName = ctrl.ctrl.config.siteLojaClassificadoresClassificador;

              ctrl.classificador = storage.getClassificadorByName(ctrl.classificadorName);
              if (ctrl.classificador) {
                ctrl.classificador = ctrl.classificador.id;
              }

              ctrl.sortList = [
              {name: 'STORE_SORT_DESTAQUE', value: 'ArtigoEmPromo desc,artigonome'},
              {name: 'STORE_SORT_PRICE_ASC', value: 'PrecoComIva asc'},
              {name: 'STORE_SORT_PRICE_DESC', value: 'PrecoComIva desc'},
                {name: 'STORE_SORT_ALPHA_ASC', value: 'artigonome'},
                {name: 'STORE_SORT_ALPHA_DESC', value: 'artigonome desc'}
              ];
              ctrl.ctrl = {
                config: {},
                noimage: storage.noimage,
                getImagem: storage.getImagem,
                getImagemByDocId: storage.getImagemByDocId,
                loadAnexos: ctrl.loadAnexos,
                getAnexoUrl: ProdutosFactory.getAnexoUrl,
                addBasket: function (item) {
                  return storage.addBasket(item);
                },
                getDatetime: function () {
                  return $rootScope.getDatetime;
                },
                showDetail: function (item) {
                  return storage.showDetail(item);
                },
                showDetailClassificador: function (item) {
                  return storage.showDetailClassificador(item);
                }
              };
              ctrl.search = _.merge({}, {
                familias: [],
                subFamilias: [],
                grandesFamilias: [],
                classes: [],
                categorias: [],
                tipos: [],
                tags: []
              }, ctrl.search);
              ctrl.filtrosEmpty = {
                familias: [],
                subFamilias: [],
                grandesFamilias: [],
                classes: [],
                categorias: [],
                tipos: [],
                tags: []
              };
              ctrl.options = _.merge({}, defaultOptions, ctrl.properties);
              ctrl.setListViewClassificador(ctrl.listViewClassificador);

              ctrl.sortorder = ctrl.sortorder || 'ArtigoEmPromo desc,artigonome';

              if ($stateParams.classificadorTipo) {
                var myClassificador = configFactory.getClassificadorByTipoId($stateParams.classificadorTipo, 0);
                ctrl.filtroAdd(myClassificador, {key: $stateParams.id, value: ''});
              }

              pesquisaArtigos();
            });


        }

        function loadClassificadorList() {
          if (!storage.getClassificadorById(ctrl.classificador)) {
            PopAlert.showError('widgets.lojaClassificadores.mensagens.classificadorInvalido');
            return;
          }
          ctrl.produtos.page.total = ctrl.produtos.textosClassificadores.length;
          var min = ctrl.offset;
          var max = ctrl.offset + ctrl.pageSize;

          // Order list if necessary
           var classificadores = ctrl.produtos.textosClassificadores;
          // if (ctrl.sortorder && ctrl.sortorder.indexOf('nome') !== -1) {
          //   var reversed = ctrl.sortorder.indexOf('desc') !== -1;
          //   classificadores = $filter('orderBy')(classificadores, 'titulo', reversed);
          // }

          ctrl.classificadorList = [];
          angular.forEach(classificadores, function (item, index) {
            if (item.tabelasTextos && index >= min && index <= max) {
              var newItem = {
                tabela: ctrl.classificador,
                chave: item.tabelasTextos.nChave,
                ordem: 1,
                descricao: '',
                titulo: item.tabelasTextos ? item.tabelasTextos.titulo : null,
                note: item.tabelasTextos ? item.tabelasTextos.note : null,
                docId: item.imagem ? item.imagem.docId : null,
                imagens: item.imagens,
                nArtigo: item.nArtigo,
                anexos: [],
                promise: null,
                loaded: false,
                calculoPrecos: item.calculoPrecos
              };
              ctrl.classificadorList.push(newItem);
            }
          });
        }

        function setListViewClassificador(view) {
          if (view) {
            ctrl.listViewClassificador = view;
          }
          if (view === 'list') {
            ctrl.boxTemplateClassificador = 'classificador.list';
          } else {

            ctrl.boxTemplateClassificador = 'classificador.box';
          }
          ctrl.session.set(sessionLvClassificador, view);
        }

        function getClassificador(data) {
          return configFactory.getClassificador(data);
        }

        function sortOrderChanged(order) {
          if (order) {
            ctrl.sortorder = order;
          }
          pesquisaArtigos();
        }

        function getClassificadorCaption(data) {
          return configFactory.getClassificadorCaption(data);
        }

        function findSearchTags(obj) {
          var tags = ctrl.search.tags;
          for (var i = 0; i < tags.length; i++) {
            if (tags[i].key === obj.key) {
              return tags[i];
            }
          }
          obj.values = [];
          tags.push(obj);
          return obj;
        }

        function filtroAdd(itemClassificador, item) {
          if (item.clearFiltro)
            clearFiltro();
          var a;
          if (itemClassificador.tipo === 'tags') {
            var id = {
              key: itemClassificador.id,
              value: itemClassificador.caption
            };
            a = ctrl.findSearchTags(id).values;
          } else {
            a = ctrl.search[itemClassificador.tipo];
          }
          item._index = a.length;
          a.push(item);
          ctrl.currentPage = 1;
          pesquisaArtigos();
        }

        function filtroRemove(name, key) {
          var aux = ctrl.search[name];
          if (name === 'tags') {
            return;
          }
          var idx = aux.indexOf(key);
          if (idx > -1) {
            aux.splice(idx, 1);
          }
          ctrl.currentPage = 1;
          pesquisaArtigos();
        }

        function filtroTagRemove(taginfo, tag) {
          var aux = ctrl.findSearchTags(taginfo);
          if (!aux.values)
            return;
          var values = aux.values;
          var idx = values.indexOf(tag);
          if (idx > -1) {
            values.splice(idx, 1);
          }
          ctrl.currentPage = 1;
          pesquisaArtigos();
        }

        function getFiltro(classificador) {
          if (!ctrl.filtros) {
            return [];
          }
          var filtro = [];
          if (classificador.tipo === 'tags') {
            filtro = ctrl.getTags(classificador.id);
          } else {
            filtro = ctrl.filtros[classificador.tipo];
          }
          return ctrl.orderFiltro(filtro);
        }

        function orderFiltro(filtro) {
          if (!angular.isArray(filtro) || !filtro.length) {
            return filtro;
          }
          var toFilter = [];
          var orderBy = 'value';
          for (var i = 0; i < filtro.length; i++) {
            var current = filtro[i];
            if (!current && current !== 0 || !current.value && current.value !== 0) {
              continue;
            }
            var value = plCompsService.parseNumber(current.value);
            if (!isFinite(value)) {
              return $filter('orderBy')(filtro, ['value', 'key']);
            }
            if (value || value === 0) {
              orderBy = 'orderedValue';
              current.orderedValue = value;
              toFilter.push(current);
            }
          }
          return $filter('orderBy')(toFilter, [orderBy, 'key']);
        }

        function onPageChange(newPageNumber, perPage) {
          ctrl.currentPage = newPageNumber;
          ctrl.pageSize = perPage;
          pesquisaArtigos();
        }

        function loadTags(query) {
          var deferred = $q.defer();

          if (!ctrl.produtos || !ctrl.produtos.classificadores || !ctrl.produtos.classificadores.tags)
            return;

          var ret = [];
          var tags = angular.fromJson(ctrl.produtos.classificadores.tags);
          for (var i = 0; i < tags.length; i++) {
            var itemTag = tags[i];
            for (var j = 0; j < itemTag.list.length; j++) {
              var item = itemTag.list[j];
              if ((item.valor || item.valor === 0) && cgClass.accentFold(item.valor).toLowerCase().indexOf(query) >= 0) {
                ret.push({
                  id: ret.length + 1,
                  text: item.valor
                });
                if (ret.length === 10)
                  break;
              }
            }
          }
          deferred.resolve(ret);
          return deferred.promise;
        }

        function doSearch() {
          ctrl.currentPage = 1;
          pesquisaArtigos();
        }

        function shouldAffix() {
          var deferred = $q.defer();
          configFactory.getConfigSite().then(
            function (response) {
              try {
                var data = angular.fromJson(response.siteClassificadoresAffix);
                data = data.lojaclassificadores;
                ctrl.affix.enabled = data.enabled;
                if (data.top) {
                  ctrl.affix.style.top = data.top + 'px';
                }
                if (data.bottom) {
                  ctrl.affix.style.bottom = data.bottom + 'px';
                }
                deferred.resolve(ctrl.affix.enabled);
              } catch (ignored) {
                deferred.reject();
              }
            }
          );
          return deferred.promise;
        }

        function loadAnexos(artigo) {
          artigo.collapsed = !artigo.collapsed;
          if (!angular.isArray(artigo.anexos)) {
            artigo.anexos = [];
          }
          if (!artigo.anexos.length && !artigo.collapsed) {
            artigo.promise = ctrl.loadAnexosClassificadores().then(
              function (anexosClassificadores) {
                artigo.promise = ProdutosFactory.getAnexos(storage.tipoTabelaImagens.none.id, artigo.nArtigo).then(function (response) {
                  if (response.data.length) {
                    angular.forEach(response.data, function (ficheiro) {
                      if (!ficheiro.dodGDocClassifica || anexosClassificadores.indexOf(ficheiro.dodGDocClassifica.toString()) === -1) {
                        return;
                      }
                      if (!artigo.anexos[ficheiro.dodGDocClassifica]) {
                        artigo.anexos[ficheiro.dodGDocClassifica] = {
                          classificador: ficheiro.dodGDocClassifica,
                          descricao: ficheiro.descricaoClassificador,
                          files: []
                        };
                      }
                      artigo.anexos[ficheiro.dodGDocClassifica].files.push({
                        id: ficheiro.docId,
                        folderId: ficheiro.folderId,
                        nome: ficheiro.nomeFicheiro,
                        deleted: ficheiro.deleted
                      });
                    });
                  }
                })['finally'](function () {
                  artigo.loaded = true;
                  artigo.promise = null;
                });
              },
              function () {
                artigo.loaded = true;
                artigo.promise = null;
              }
            );
          }
        }

        function loadAnexosClassificadores() {
          var deferred = $q.defer();

          if (!ctrl.anexosClassificadores) {
            if (!ctrl.promiseAnexosClassificadores) {
              ctrl.promiseAnexosClassificadores = configFactory.getConfigSite();
              ctrl.promiseAnexosClassificadores.then(function (response) {
                  var data = response.siteClassificadorAnexos;
                  if (!data) {
                    data = '';
                  }
                  ctrl.anexosClassificadores = data.split(',');
                  ctrl.anexosClassificadores = ctrl.anexosClassificadores.map(function (anexo) {
                    return anexo.toString();
                  });
                  deferred.resolve(ctrl.anexosClassificadores);
                }
              )['finally'](function () {
                ctrl.promiseAnexosClassificadores = null;
              });
            }
          } else {
            deferred.resolve(ctrl.anexosClassificadores);
          }

          return deferred.promise;
        }
      }
    })
    .component('lojaClassificadoresEdit', {
      bindings: {
        item: '<'
      },
      templateUrl: 'app/components/widgets/lojaclassificadores/lojaclassificadores.edit.html',
      controller: /* ngInject */ function () {
        var ctrl = this;

        ctrl.zones = [];
        ctrl.classificadores = [];

        ctrl.init = init;
        ctrl.refresh = refresh;

        ctrl.init();

        /////////////

        function init() {
          ctrl.zones = [{nome: 'widgets.lojaClassificadores.zones.cima'},
            {nome: 'widgets.lojaClassificadores.zones.meio'},
            {nome: 'widgets.lojaClassificadores.zones.fundo'}
          ];
          ctrl.classificadores = [{nome: 'widgets.lojaClassificadores.classificadores.none'},
            {nome: 'widgets.lojaClassificadores.classificadores.tipoArtigo'},
            {nome: 'widgets.lojaClassificadores.classificadores.grandeFamilia'},
            {nome: 'widgets.lojaClassificadores.classificadores.familia'},
            {nome: 'widgets.lojaClassificadores.classificadores.subFamilia'},
            {nome: 'widgets.lojaClassificadores.classificadores.categoria'},
            {nome: 'widgets.lojaClassificadores.classificadores.classe'}];
        }

        function refresh() {
        }
      }
    });
})();
