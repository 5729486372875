(function () {
  'use strict';

  var today = moment().startOf('date');

  function CarrinhoController /*@ngInject*/($rootScope, $q, storage, Auth, $state, clienteService, paisesService, Utils, PopAlert,
                                            PagamentosFactory, configFactory, alteraModaraModal, TransportadorasFactory, documentoService) {
    var ctrl = this;

    ctrl.storage = storage;
    ctrl.quantity1 = 1;
    ctrl.condicoesPagamento = {};
    ctrl.meiosPagamento = [];
    ctrl.transportadoras = [];
    ctrl.definition = {};
    ctrl.steps = {};
    ctrl.currentStep = {};
    ctrl.clienteMoradasAlt = {};
    ctrl.paises = {};
    ctrl.affix = {};
    ctrl.affixResumo = {};
    ctrl.transportadora = {};
    ctrl.moradaEntrega = {};
    ctrl.moradaFaturacao = {};
    ctrl.plNavWizard = {};
    ctrl.form = {};
    ctrl.config = $rootScope.$config.data;
    ctrl.moradaEntregaDiferente = false;
    ctrl.mostraDataEntregaPrev = false;

    ctrl.$onInit = init;
    ctrl.removeBasket = removeBasket;
    ctrl.removeAllBasket = removeAllBasket;
    ctrl.calcBasket = calcBasket;
    ctrl.addBasket = addBasket;
    ctrl.removeQtdBasket = removeQtdBasket;
    ctrl.checkout2 = checkout2;
    ctrl.carrinhoTotal = carrinhoTotal;
    ctrl.carrinhoList = carrinhoList;
    ctrl.showDetail = showDetail;
    ctrl.setNCondPag = setNCondPag;
    ctrl.editMorada = editMorada;
    ctrl.newMoradaAlt = newMoradaAlt;
    ctrl.loadTransportadoras = loadTransportadoras;
    ctrl.loadMoralPickup = loadMoralPickup;
    ctrl.beforeStepChange = beforeStepChange;
    ctrl.changedStep = changedStep;
    ctrl.onEnvioChange = onEnvioChange;
    ctrl.validateStepCarrinho = validateStepCarrinho;
    ctrl.validateStepMoradas = validateStepMoradas;
    ctrl.validateStepEnvio = validateStepEnvio;
    ctrl.loadMeiosPagamento = loadMeiosPagamento;
    ctrl.validateStepResumo = validateStepResumo;
    ctrl.submitForm = submitForm;
    ctrl.getButtonNextCaption = getButtonNextCaption;
    ctrl.newOrder = newOrder;
    ctrl.getDocumento = getDocumento;
    ctrl.updateMoradas = updateMoradas;
    ctrl.moradaEntregaDiferenteChange = moradaEntregaDiferenteChange;
    ctrl.atualizaMorada = atualizaMorada;
    ctrl.affixTotal = affixTotal;
    ctrl.isDateDisabled = isDateDisabled;
    ctrl.changedDataEntregaPrev = changedDataEntregaPrev;
    ctrl.eventUser = $rootScope.$on('storage-loaded', function (event, args) {
      if (!angular.equals(Auth.currentUser, {}))
        atualizaMorada();

    });

    /////////////

    function init() {
      ctrl.currencyInfo = ctrl.config.simboloMoeda;
      ctrl.affix = {
        enabled: ctrl.changedStep(),
        style: {
          top: '1px',
          bottom: '1px'
        }
      };
      ctrl.affixResumo = {
        enabled: true,
        style: {
          top: '90px',
          bottom: '120px'
        }
      };

      ctrl.definition = {
        items: []
      };

      ctrl.stepEnvioIsVisible = false;

      var list = configFactory.transportadorasList;
      var i;
      for (i = 0; i < list.length; i++) {
        var item = list[i];
        if (item.visible) {
          ctrl.stepEnvioIsVisible = true;
          break;
        }
      }

      ctrl.steps = [
        {
          caption: 'carrinho.steps.step1', name: 'step1', icon: 'fa-shopping-cart', visible: true,
          // templateUrl: 'app/loja/carrinho/steps/step1.carrinho.html'
          templateUrl: 'carrinho.step1'
        },
        {
          caption: 'carrinho.steps.step2', name: 'step2', icon: 'fa-drivers-license', visible: true,
          // templateUrl: 'app/loja/carrinho/steps/step2.moradas.html'
          templateUrl: 'carrinho.step2'
        },
        {
          caption: 'carrinho.steps.step3', name: 'step3', icon: 'fa-truck fa-flip-horizontal', visible: ctrl.stepEnvioIsVisible,
          templateUrl: 'carrinho.step3'
          // templateUrl: 'app/loja/carrinho/steps/step3.envio.html'
        },
        {
          caption: 'carrinho.steps.step4', name: 'step4', icon: 'fa-file-text', visible: true,
          templateUrl: 'carrinho.step4'
          // templateUrl: 'app/loja/carrinho/steps/step4.resumo.html'
        },
        {
          caption: 'carrinho.steps.step5', name: 'step5', icon: 'fa-check-circle', visible: true,
          // templateUrl: 'app/loja/carrinho/steps/step5.encomenda.html'
          templateUrl: 'carrinho.step5'
        }
      ];

      if (storage.clienteData !== null && angular.isDefined(storage.clienteData)) {
        ctrl.moradaEntrega = storage.clienteData.moradaEntrega;
        ctrl.clienteMoradasAlt = storage.clienteData.clienteMoradasAlt;
        ctrl.moradaFaturacao = storage.clienteData.moradaFaturacao;
        ctrl.clienteMoradasAltEdit = storage.clienteData.clienteMoradasAltEdit;
      }
      ctrl.atualizaMorada();

      ctrl.paises = storage.paises;

      ctrl.mostraDataEntregaPrev = ctrl.config.checkout.mostraDataEntregaPrev || false;
      if (!ctrl.mostraDataEntregaPrev) {
        if (angular.isDefined(ctrl.storage.dataEntregaPrev)) {
          delete ctrl.storage.dataEntregaPrev;
        }
      } else {
        if (angular.isUndefined(ctrl.storage.dataEntregaPrev)) {
          ctrl.storage.dataEntregaPrev = today.clone().hour(12);
        }
      }
      ctrl.changedDataEntregaPrev(ctrl.storage.dataEntregaPrev);
      calcBasket();
    }

    function atualizaMorada() {
      if (storage.clienteData !== null && angular.isDefined(storage.clienteData)) {
        ctrl.moradaEntrega = storage.clienteData.moradaEntrega;
        ctrl.clienteMoradasAlt = storage.clienteData.clienteMoradasAlt;
        ctrl.moradaFaturacao = storage.clienteData.moradaFaturacao;
        ctrl.clienteMoradasAltEdit = storage.clienteData.clienteMoradasAltEdit;
      }
    }

    function newMoradaAlt(tipo) {
      return alteraModaraModal.alteraMorada().then(function (novaMorada) {
        var newMoradaAlt = {
          moralid: '{00000000-0000-0000-0000-000000000000}',
          nconta: ctrl.storage.clienteData.cliente.nconta,
          nome: novaMorada.nome,
          rua: '',
          cpost: novaMorada.cpost,
          localidade: novaMorada.localidade,
          nipc: novaMorada.nipc || '',
          observacoes: '',
          morada: novaMorada.morada,
          desativado: false,
          facidGuidId: ctrl.storage.clienteData.cliente.facidGuidId,
          contactoNome: novaMorada.contactoNome,
          contactoTelefone: novaMorada.contactoTelefone || '',
          codPaisIso: novaMorada.paisIso.codPaisIso
        };
        clienteService.newMoradaAlternativa(newMoradaAlt, function (data) {
            ctrl.clienteMoradasAlt.push(data);
            if (tipo === 'newMoradaFaturacao') {
              ctrl.moradaFaturacao = data;
            }
            if (tipo === 'newMoradaEntrega') {
              ctrl.moradaEntrega = data;
            }
          }
        );
      });
    }

    function updateMoradas(novaMorada) {
      if (angular.isUndefined(novaMorada.moralid) || (novaMorada.moralid === ctrl.moradaFaturacao.moralid)) {
        ctrl.moradaFaturacao = novaMorada;
      }
      if (novaMorada.moralid === ctrl.moradaEntrega.moralid) {
        ctrl.moradaEntrega = novaMorada;
      }
    }

    function editMorada(morada) {
      return alteraModaraModal.alteraMorada(morada).then(function (novaMorada) {
        var index = ctrl.clienteMoradasAlt.findIndex(function (item) {
          return item.moralid === novaMorada.moralid;
        });
        _.merge(ctrl.clienteMoradasAlt[index], novaMorada);
        if (ctrl.clienteMoradasAlt[index].isPrincipal === true) {
          var cliente = {
            nconta: ctrl.storage.clienteData.cliente.nconta,
            nome: ctrl.moradaFaturacao.contactoNome,
            morada: ctrl.moradaFaturacao.morada,
            codPostal: ctrl.moradaFaturacao.cpost,
            localidade: ctrl.moradaFaturacao.localidade,
            nif: ctrl.moradaFaturacao.nipc,
            facidGuidId: ctrl.storage.clienteData.cliente.facidGuidId,
            email: ctrl.storage.clienteData.cliente.email,
            telefone: ctrl.moradaFaturacao.contactoTelefone,
            nifReal: ctrl.moradaFaturacao.nipc,
            isContaIdentAlternativa: ctrl.storage.clienteData.cliente.isContaIdentAlternativa,
            codPaisIso: ctrl.moradaFaturacao.paisIso.codPaisIso,
            paisIso: {
              codPaisIso: ctrl.moradaFaturacao.paisIso.codPaisIso,
              nomePais: ctrl.moradaFaturacao.paisIso.nomePais
            }
          };
          clienteService.save(cliente, function (data) {
            ctrl.updateMoradas(data.data);
          });
        } else {
          delete ctrl.moradaEntrega.open;
          clienteService.updateMoradaAlternativa(novaMorada, novaMorada.moralid, function () {
            ctrl.updateMoradas(novaMorada);
          });
        }
      });
    }

    function setNCondPag(ncondpag) {
      storage.ncondpag = 0;
      for (var i = 0; i < storage.condicoesPagamentoList.length; i++) {
        if (storage.condicoesPagamentoList[i].ncondpag !== ncondpag)
          storage.condicoesPagamentoList[i].checked = false;
      }
      for (i = 0; i < storage.condicoesPagamentoList.length; i++) {
        if (storage.condicoesPagamentoList[i].checked) {
          storage.ncondpag = storage.condicoesPagamentoList[i].ncondpag;
          break;
        }
      }
      ctrl.calcBasket();
    }

    function removeBasket(item) {
      var index = storage.cesto.indexOf(item);
      storage.cesto.splice(index, 1);
      storage.calcBasket();
      PopAlert.showSuccess('carrinho.messagens.itemRemovedFromCart');
    }

    function removeAllBasket() {
      storage.cesto = [];
      storage.calcBasket();
    }

    function calcBasket() {
      storage.calcBasket();
    }

    function addBasket(artigo) {
      var aux = _.find(storage.cesto, function (rw) {
        return rw.nArtigo === artigo.nArtigo;
      });
      if (angular.isUndefined(aux)) {
        aux = artigo;

        aux.qtd = artigo.qtd || 1.0;
        storage.cesto.push(aux);
      } else if (artigo.qtd) {
        aux.qtd += artigo.qtd;
      } else {
        aux.qtd++;
      }
      PopAlert.showSuccess('carrinho.messagens.itemAddedToCart', {'nome': artigo.nome});
      storage.calcBasket();
    }

    function removeQtdBasket(artigo) {
      var aux = _.find(storage.cesto, function (rw) {
        return rw.nArtigo === artigo.nArtigo;
      });
      if (angular.isUndefined(aux)) {
        aux = artigo;
        aux.qtd = 1.0;
        storage.cesto.push(aux);
      } else {
        aux.qtd--;
      }
      storage.calcBasket();
    }

    function carrinhoTotal() {
      return storage.carrinhoTotal;
    }

    function carrinhoList() {
      return storage.cesto;
    }

    function showDetail(artigo) {
      storage.showDetail(artigo);
    }

    function loadTransportadoras() {
      var deferred = $q.defer();

      PagamentosFactory.transportadoras(ctrl.moradaEntrega.paisIso.codPaisIso, ctrl.moradaEntrega.cpost).then(function (data) {
        var i;
        var j;
        ctrl.transportadoras = [];
        ctrl.transportadora = {};
        storage.codTransportadora = 0;
        var list = configFactory.transportadorasList;
        for (i = 0; i < list.length; i++) {
          var item = list[i];
          if (!item.visible) continue;
          for (j = 0; j < data.length; j++) {
            var item1 = data[j];
            if (item.codTransportadora === item1.codTransportadora) {
              ctrl.transportadoras.push(item1);
              break;
            }
          }
        }

        if (ctrl.transportadoras.length > 0 && (angular.isUndefined(storage.codTransportadora) || storage.codTransportadora === 0)) {
          storage.codTransportadora = ctrl.transportadoras[0].codTransportadora;
          ctrl.transportadora = ctrl.transportadoras[0];
          ctrl.loadMoralPickup();
          ctrl.calcBasket();
        }
        deferred.resolve(true);
      });
      return deferred.promise;
    }

    function loadMoralPickup() {
      var deferred = $q.defer();
      ctrl.moradasAlternativasTransportadoras = [];
      if (ctrl.transportadora.nContaPickup) {
        TransportadorasFactory.moradasAlternativas(storage.codTransportadora).then(function (data) {
          ctrl.moradasAlternativasTransportadoras = data;
          if (data.length)
            ctrl.moradaPickup = data[0];
          deferred.resolve(true);
        });
      }
      return deferred.promise;
    }

    function validateStepCarrinho() {

      if (angular.isUndefined(ctrl.storage.cesto) || !ctrl.storage.cesto.length) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepCarrinho.carrinhoVazio'));
        return false;
      }

      if (!Auth.isLoggedIn()) {
        PopAlert.alert($rootScope.globalTranslate('CART_ERROR_MUST_BE_LOGGIN'), $rootScope.globalTranslate('CART_ERROR_MUST_BE_LOGGIN_TEXT'), function (e) {
          if (e) {
            $state.go('login', {returnstate: 'loja.carrinho'});
          }
        });
        return false;
      }
      return true;
    }

    function validateStepMoradas() {

      if ((angular.isUndefined(ctrl.moradaEntrega.paisIso) || !ctrl.moradaEntrega.paisIso.codPaisIso) || (angular.isUndefined(ctrl.storage.cesto) || !ctrl.storage.cesto.length)) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepMoradas.paisNaoDefinido'));
        return false;
      }

      if (!ctrl.moradaEntrega.cpost) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepMoradas.codigoPostalNaoDefinido'));
        return false;
      }

      if (!ctrl.moradaEntrega.cpost) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepMoradas.codigoPostalNaoDefinido'));
        return false;
      }

      if (!ctrl.storage.clienteData.cliente.completed) {
        PopAlert.alert($rootScope.globalTranslate('CONTACTS_ADDRESS_REQUIRED'), $rootScope.globalTranslate('CONTACTS_ADDRESS_REQUIRED_TEXT'), function (e) {
          if (e) {
            ctrl.editMorada(ctrl.moradaFaturacao);
          } else {
            PopAlert.showError($rootScope.globalTranslate('carrinho.stepMoradas.moradaNaoCompleta'));
          }
        });
        return false;
      }

      if (ctrl.moradaEntregaDiferente === false) {
        ctrl.moradaEntrega = ctrl.moradaFaturacao;
      }

      return true;
    }

    function validateStepEnvio() {
      if (!storage.codTransportadora) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepEnvio.transporteNaoDefinido'));
        return false;
      }

      if ((angular.isUndefined(ctrl.storage.cesto) || !ctrl.storage.cesto.length) || angular.isDefined(ctrl.transportadora) &&
        angular.isDefined(ctrl.transportadora.nContaPickup) && ctrl.transportadora.nContaPickup !== '' && !ctrl.moradaPickup) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepEnvio.localPickupNaoDefinido'));
        return false;
      }

      return true;
    }

    function validateStepResumo() {
      if (ctrl.mostraDataEntregaPrev) {
        var isValid = moment(ctrl.storage.dataEntregaPrev).isValid() && !ctrl.isDateDisabled(ctrl.storage.dataEntregaPrev);
        if (!isValid) {
          PopAlert.showError($rootScope.globalTranslate('carrinho.errors.invalidDataEntregaPrev'));
          return false;
        }
      }
      if (ctrl.meiosPagamento.length > 0 && !ctrl.nCaixa) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepResumo.meioPagamentoNaoDefinido'));
        return false;
      }
      return true;
    }

    function loadMeiosPagamento() {
      var deferred = $q.defer();
      documentoService.getMeiosPagamento().then(function (data) {
        ctrl.meiosPagamento = data;
        if (ctrl.meiosPagamento.tipo === 2) {
          ctrl.meiosPagamento.icon = '<i class="fa fa-credit-card"></i>';
        }
        if (ctrl.meiosPagamento.tipo === 8) {
          ctrl.meiosPagamento.icon = '<i class="fa fa-bank"></i>';
        }
        if (ctrl.meiosPagamento.tipo === 9) {
          ctrl.meiosPagamento.icon = '<i class="fa fa-paypal"></i>';
        }

        deferred.resolve(true);
      });
      return deferred.promise;
    }

    function beforeStepChange(currentStep, previousStep, type, nextStep) {
      if ((nextStep === ctrl.definition.items[1]) || (currentStep === ctrl.definition.items[0] && type === 'next')) {
        if (!ctrl.validateStepCarrinho()) return false;

        return true;//ctrl.loadTransportadoras();
      } else if ((nextStep === ctrl.definition.items[2]) || (currentStep === ctrl.definition.items[1] && type === 'next')) {
        if (!ctrl.validateStepMoradas()) return false;
        if (!ctrl.stepEnvioIsVisible)
          loadMeiosPagamento();
        return ctrl.loadTransportadoras();
      } else if (((nextStep === ctrl.definition.items[3]) || (currentStep === ctrl.definition.items[2] && type === 'next')) && ctrl.stepEnvioIsVisible) {
        if (!ctrl.validateStepEnvio()) return false;
        return loadMeiosPagamento();
      } else if ((nextStep === ctrl.definition.items[4]) || (currentStep === ctrl.definition.items[3] && type === 'next')) {
        if (!ctrl.validateStepResumo()) return false;
        var promise = ctrl.checkout2();
        return promise;
      }
    }

    function changedStep(currentStep, previousStep) {
      ctrl.currentStep = currentStep;
      ctrl.previousStep = previousStep;

      if (ctrl.currentStep) {
        ctrl.affixTotal();
      }
    }

    function affixTotal() {
      if (ctrl.currentStep.id === 'step1') {
        ctrl.affix.enabled = true;
        ctrl.affix.style.top = '90px';
        ctrl.affix.style.bottom = '120px';
        ctrl.affix.class = 'affix';
      }
      if (ctrl.currentStep.id === 'step4') {
        ctrl.affixResumo.enabled = true;
        ctrl.affixResumo.style.top = '90px';
        ctrl.affixResumo.style.bottom = '120px';
        ctrl.affixResumo.class = 'affix';

        ctrl.affix.enabled = false;
        ctrl.affix.style.top = '1px';
        ctrl.affix.style.bottom = '1px';
        ctrl.affix.class = '';
      }
      if (ctrl.currentStep.id !== 'step4' && ctrl.currentStep.id !== 'step1') {
        ctrl.affix.enabled = false;
        ctrl.affix.style.top = '1px';
        ctrl.affix.style.bottom = '1px';
        ctrl.affix.class = '';

        ctrl.affixResumo.enabled = false;
        ctrl.affixResumo.style.top = '1px';
        ctrl.affixResumo.style.bottom = '1px';
        ctrl.affixResumo.class = '';
      }
    }

    function onEnvioChange(item) {
      ctrl.transportadora = item;
      ctrl.moradaPickup = {};
      ctrl.calcBasket();
      ctrl.loadMoralPickup();
    }

    function submitForm() {
      ctrl.plNavWizard.nextStep();
    }

    function getButtonNextCaption() {
      if (ctrl.definition.items[3].active)
        return ctrl.config.finalizaCompra;
      else
        return $rootScope.globalTranslate('carrinho.seguinte');
    }

    function checkout2() {
      //Aqui tenho de validar tudo

      //Carrinho
      if (ctrl.storage.carrinhoTotal.nItens === 0) {
        PopAlert.showInfo($rootScope.globalTranslate('carrinho.stepResumo.carrinhoVazio'));
        return false;
      }
      //Moradas
      if (Utils.isNullOrEmpty(ctrl.moradaFaturacao)) {
        PopAlert.showInfo($rootScope.globalTranslate('carrinho.stepResumo.moradaFaturacaoInvalida'));
        return false;
      }
      if (Utils.isNullOrEmpty(ctrl.moradaEntrega)) {
        PopAlert.showInfo($rootScope.globalTranslate('carrinho.stepResumo.moradaEntregaInvalida'));
        return false;
      }
      //Envio
      if (storage.codTransportadora === 0 && ctrl.stepEnvioIsVisible) {
        PopAlert.showError($rootScope.globalTranslate('carrinho.stepResumo.meioEnvioInvalido'));
        return false;
      }
      if (!Utils.isNullOrEmpty(ctrl.transportadora.nContaPickup)) {
        if (Utils.isNullOrEmpty(ctrl.moradaPickup)) {
          PopAlert.showError($rootScope.globalTranslate('carrinho.stepResumo.moradaPickupInvalida'));
          return false;
        }
      }

      storage.cesto.cab = {
        nCaixa: ctrl.nCaixa,
        ncondpag: 0,
        codTransportadora: storage.codTransportadora,
        custoTransporte: 0,
        moradaFaturacao: ctrl.moradaFaturacao,
        moradaEntrega: ctrl.moradaEntrega,
        moradaPickup: ctrl.moradaPickup
      };

      var defered = $q.defer();

      PopAlert.confirm($rootScope.globalTranslate('CART_PAGE_FINALIZAR_COMPRA_CONFIRMAR'), $rootScope.globalTranslate('CART_PAGE_FINALIZAR_COMPRA'), function (e) {
        if (e) {
          documentoService.gerarDoc().then(function (data) {
            ctrl.doc = data;
            ctrl.activeDoc = ctrl.doc.MBFaccbId;

            ctrl.getDocumento();

            defered.resolve();
          },function (data) {
              var msg=data.data.Description||data.data;
              PopAlert.showError($rootScope.globalTranslate(msg));
              defered.reject();    
          });
        } else {
          
          defered.reject();
        }
      });
      //Meio de pagamento
      return defered.promise;
    }

    function newOrder() {
      return $state.go('loja.home', {id: 'inicio'});
    }

    function getDocumento() {
      var deferred = $q.defer();
      documentoService.getDoc(ctrl.doc.MBFaccbId)
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function moradaEntregaDiferenteChange() {
      if (ctrl.moradaEntregaDiferente === false) {
        ctrl.moradaEntrega = ctrl.moradaFaturacao;
      }
    }

    function isDateDisabled(value) {
      return moment(value).isBefore(today, 'date');
    }

    function changedDataEntregaPrev(value) {
      if (!angular.isArray(ctrl.storage.cesto) || !ctrl.storage.cesto.length) {
        return;
      }
      var isValid = ctrl.mostraDataEntregaPrev && !isDateDisabled(value);
      value = isValid ? moment(value) : undefined;
      for (var i = 0; i < ctrl.storage.cesto.length; i++) {
        var linha = ctrl.storage.cesto[i];
        linha.dataEntregaPrev = value;
      }
    }
  }

  angular.module('erpRetail')
    .controller('CarrinhoController', CarrinhoController)
    .component('carrinhoComponent', {
      templateUrl: 'app/loja/carrinho/carrinho.html',
      controllerAs: '$ctrl',
      controller: CarrinhoController
    });
}());
