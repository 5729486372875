(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('LoginController', /*@ngInject*/ function ($rootScope, $scope, Auth, $location, $window, $log, $state, configFactory,
                                                           $timeout, Utils, PopAlert, $transition$) {
      var ctrl = this;

      ctrl.formlogin = {};
      ctrl.user = {};
      ctrl.errors = {};
      ctrl.passwordValidator = Auth.passwordValidator;
      ctrl.enableSignUp = true;
      ctrl.promise = null;

      ctrl.$onInit = init;
      ctrl.login = login;
      ctrl.confirmaEmail = confirmaEmail;

      /////////////

      function init() {
        ctrl.params = $transition$.params();
        configFactory.getConfigSite().then(
          function (response) {
            // Trying to access sign up but it is disabled
            if (parseInt(response.siteSignupEnabled) === 0) {
              ctrl.enableSignUp = false;
            }
          }
        );
      }

      function login(isValid) {
        ctrl.formlogin.$setSubmitted();
        if (!isValid) {
          return;
        }

        var data = {
          email: ctrl.user.email,
          password: ctrl.user.password
        };
        ctrl.promise = Auth.login(data).then(
          function () {
            var params = $transition$.params();
            if (params.returnstate && params.returnstate !== 'signup') {
              return $state.go(params.returnstate, params.returnstateparams);
            }
            return $state.go('loja.home', {id: 'inicio'});
          },
          function (error) {
            ctrl.errors = {};
            if (Utils.isNullOrEmpty(error.data)) {
              PopAlert.showError('LOGIN_ERROR_CANT_LOGIN');
            }
            ctrl.errors.other = error.data.state;
            if (error.data.state === 'LOGIN_USER_UNVERIFIED') {
              ctrl.errors.sendemail = 'LOGIN_SEND_CONFIRMATION_MAIL';
            } else if (error.data.state === 'LOGIN_WRONG_PASSWORD') {
              ctrl.errors.recoveremail = 'LOGIN_RECOVER_MAIL';
            }
          }
        )['finally'](function () {
          ctrl.promise = null;
        });
      }

      function confirmaEmail() {
        Auth.sendEmailToConfirm(ctrl.user.email);
        return $state.go('user.confirm_email');
      }
    });
})();
