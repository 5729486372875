(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('apiService', function () {
      var factory = {
        buildQueryRaw: buildQueryRaw,
        buildQuery: buildQuery
      };
      return factory;

      function buildQueryRaw(params) {
        return {
          recordCount: params.recordCount,
          startOffset: params.startOffset,
          pesquisa: params.pesquisa,
          order: params.order
        };
      }

      function buildQuery(search, page, perPage, order) {
        var offset = 0;
        if (page > 1) {
          offset = (page - 1) * perPage;
        }
        return factory.buildQueryRaw({
          recordCount: perPage,
          startOffset: offset,
          pesquisa: search,
          order: order
        });
      }
    });
})();
