/**
 * Created by cg on 30/09/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('HomeController', /*@ngInject*/ function ($scope, storage, Auth) {

      var ctrl = this;

      ctrl.storage = storage;
      ctrl.user = {};
      ctrl.isLoggedIn = false;
      ctrl.isAdmin = false;  //TODO actualizar

      ctrl.refreshUser = refreshUser;
      ctrl.refresh = refresh;
      ctrl.onLoad = onLoad;
      ctrl.init = init;

      ctrl.init();

      ////////////////

      function init() {
        ctrl.onLoad();
      }

      function refreshUser() {
        ctrl.user = Auth.getCurrentUser();
        Auth.isLoggedInAsync(function (isLoggedIn) {
          ctrl.isLoggedIn = isLoggedIn;
          ctrl.isAdmin = Auth.isAdmin();
        });
      }

      function refresh() {
        ctrl.refreshUser();
        //storage.reload();
      }

      function onLoad() {
        ctrl.refresh();

        $scope.$on('event-login', function (event, args) {
          ctrl.refreshUser();
        });

        $scope.$on('event-user', function (event, args) {
          ctrl.refreshUser();
        });

        $scope.$on('event-logout', function (event, args) {
          ctrl.refreshUser();
        });
      }
    })

    .controller('PaginaShowController', /*@ngInject*/ function (pagina) {
      var ctrl = this;
      ctrl.pagina = pagina;
    });
}());
