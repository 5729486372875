(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('notificationInterceptor', function ($q, $injector) {
      function response(data) {
        // In case server doesn't return a JSON response
        if (angular.isObject(data) && data.headers) {
          var alertKey = data.headers('app-alert');
          if (angular.isString(alertKey)) {
            var plAlertService = $injector.get('plAlertService');
            plAlertService.error(alertKey, {delay: 10000});
          }
        }
        return data;
      }

      return {
        responseError: function (data) {
          data = response(data);
          return $q.reject(data);
        },
        response: function (data) {
          response(data);
          return data;
        }
      };
    });
})();
