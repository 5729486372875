(function () {
    'use strict';

    angular.module('erpRetail')
        .factory('ModelosFactory', ['$q', '$http', 'REST_API', function ($q, $http, REST_API) {
            return {
                get: function () {
                    var deferred = $q.defer();

                    $http.get(REST_API.HOST + REST_API.MODELOS)
                        .then(function (data) {
                            deferred.resolve(data.data);
                        });
                    return deferred.promise;
                },
                getTipos: function (textos) {
                    var deferred = $q.defer();
                    $http.get(REST_API.HOST + REST_API.MODELOS_TIPOS)
                        .then(function (data) {
                            deferred.resolve(data.data);
                        });
                    return deferred.promise;
                },
                getTexto: function (tipo) {
                    var deferred = $q.defer();
                  $http.get(REST_API.HOST + REST_API.MODELOS + '/' + tipo)
                        .then(function (data) {
                            deferred.resolve(data.data);
                        });
                    return deferred.promise;
                },
                getOriginal: function (type) {
                    var deferred = $q.defer();
                  $http.get(REST_API.HOST + REST_API.MODELOS_ORIGINAL + '/' + type)
                        .then(function (data) {
                            deferred.resolve(data.data);
                        });
                    return deferred.promise;
                },
                save: function (type, subject, html) {
                    var deferred = $q.defer();
                    $http.post(REST_API.HOST + REST_API.MODELOS, {
                        tipo: type,
                        subject: subject,
                        html: html
                    })
                        .then(function (data) {
                            deferred.resolve(data.data);
                        });
                    return deferred.promise;
                }
            };
        }]);
}());
