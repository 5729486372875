(function () {
  'use strict';

  angular.module('erpRetail')
    .directive('autoResize', function ($timeout, KEYCODE) {
      return {
        link: function ($scope, $element, $attrs) {
          $timeout(function () {
            var $input = angular.element();
            var $parent = angular.element();
            var minWidth = 0;

            if ($element.is('input')) {
              $input = $element;
            }
            else {
              $input = $element.find('input');
              if (!$input.length) {
                return;
              }
            }

            function resize(text) {
              var $this = angular.element(this);
              if (!text.trim()) {
                var placeholder = $this.attr('placeholder');
                if (placeholder) {
                  text = $this.attr('placeholder').trim();
                }
              }
              var $span = $this.parent().find('span');
              $span.text(text);

              var $toResize = $this;
              var width = $span.width();
              if ($parent.length) {
                $toResize = $parent;
                width += minWidth;
              }
              $toResize.css({'min-width': width, 'max-width': width});
            }

            function keypress(event) {
              if (event.which && event.charCode) {
                var c = String.fromCharCode(event.keyCode | event.charCode); // jshint ignore:line
                var $this = angular.element(this);
                resize.call($this, $this.val() + c);
              }
            }

            function keyup(event) {
              if (event.keyCode === KEYCODE.BACKSPACE || event.keyCode === KEYCODE.DELETE) {
                var $this = angular.element(this);
                resize.call($this, $this.val());
              }
            }

            function blur() {
              resize.call($input, $input.val());
            }

            (function init() {
              var selector = $attrs.autoResize;
              if (selector) {
                $parent = $element.closest(selector);
                minWidth = $parent.width();
              }
              $input.after('<span style="display: none;"></span>');
              $input.on('keypress', keypress);
              $input.on('keyup', keyup);
              $input.on('blur', blur);
              resize.call($input, $input.val());

              $scope.$on('$destroy', function () {
                $input.off('keypress', keypress);
                $input.off('keyup', keyup);
                $input.off('blur', blur);
              });
            })();
          });
        }
      };
    });
})();
