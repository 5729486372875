(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('DocumentosClienteController', /*@ngInject*/ function ($document, $state, $timeout, $transition$, documentosService, documentoService, Utils, $q, $translate) {
      var ctrl = this;

      ctrl.params = $transition$.params();
      ctrl.callback = {};
      ctrl.pdfUrl = '#';
      ctrl.definition = {};
      ctrl.search = '';
      ctrl.restoreDoc = ctrl.params.mbfaccbId;

      ctrl.init = init;
      ctrl.onLoad = onLoad;
      ctrl.onDocSelect = onDocSelect;
      ctrl.onDocDetail = onDocDetail;
      ctrl.canPay = canPay;
      ctrl.getStatus = getStatus;

      ctrl.init();

      /////////////

      function init() {
        ctrl.definition = {
          order: 'stampInsert desc',
          page: 1,
          options: {
            perPage: 10
          },
          fields: [
            {name: 'stampInsert', type: 'timestamp', visible: false, caption: ''},
            {name: 'ndocAsString', type: 'text', caption: 'ACOOUNT_DOCS_TABLE_TH_NDOC'},
            {name: 'dataDoc', type: 'date', caption: 'ACCOUNT_DOCS_TABLE_TH_DATA'},
            {name: 'estado', caption: 'ACCOUNT_DOCS_TABLE_TH_ESTADO'},
            {name: 'total', align: 'right', type: 'number', caption: 'ACCOUNT_DOCS_TABLE_TH_TOTAL'},
            {name: 'estadoLabel', caption: ' '}
          ]
        };
      }

      function onLoad(search, order, page, perpage) {
        var deferred = $q.defer();
        documentosService.getCliente(search, page, perpage, order)
          .then(function (response) {
            response.list = response.list.map(function (item, index) {
              item._$ctrl = ctrl;
              item._canPay = ctrl.canPay(item);
              item.statusSync = ctrl.getStatus(item.statusSync);
              if (item.estado === 'PaypalCompleted' || item.estado === 'PayedSettedByAdmin') {
                item.estadoLabel = '<i class="fa fa-check text-success"></i>';
              } else {
                item.estadoLabel = '<i class="fa fa-credit-card text-primary"></i>';
              }
              item.estado = $translate.instant('DOC_ESTADO_' + item.estado);
              if (ctrl.restoreDoc && ctrl.restoreDoc === item.mbfaccbId) {
                ctrl.onDocDetail(item).then(function () {
                  item._detailOpen = true;
                  $timeout(function () {
                    var element = angular.element('pl-table table > tbody:nth-child(' + (index + 1) + ') > tr.detail').get(0);
                    if (element) {
                      element.scrollIntoView();
                    }
                  });
                });
                ctrl.restoreDoc = undefined;
              }
              return item;
            });
            deferred.resolve(response);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
        return deferred.promise;
      }

      function onDocSelect(item) {
        item._detailOpen = !item._detailOpen;
        if (item._detailOpen) {
          return ctrl.onDocDetail(item);
        }
      }

      function onDocDetail(item) {
        if (item._thedoc) {
          return;
        }
        var deferred = $q.defer();
        documentoService.getDoc(item.mbfaccbId)
          .then(function (response) {
            item._thedoc = response.data;
            deferred.resolve();
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
        return deferred.promise;
      }

      function canPay(item) {
        return (!Utils.isNullOrEmpty(item) && Utils.sameText(item.estado, 'PaypalSend') || Utils.sameText(item.estado, 'EsperaPagamento'));
      }


      function getStatus(item) {
        return documentosService.getStatus(item);
      }
    });
})();
