(function () {
  'use strict';

  angular.module('erpRetail')
    .config(/*@ngInject*/ function ($stateProvider) {
      $stateProvider
        .state('loja.documentos', {
          url: '/documentos/:mbfaccbId',
          title: 'utilizador.states.documentos',
          params: {
            authenticate: true,
            mbfaccbId: { // Optional parameter
              value: null,
              squash: true,
              dynamic: true
            }
          },
          templateUrl: 'app/loja/utilizador/documentos/documentos.html',
          controller: 'DocumentosClienteController',
          controllerAs: 'ctrl'
        })
        .state('loja.perfil', {
          url: '/perfil',
          title: 'utilizador.states.perfil',
          abstract: true,
          params: {authenticate: true},
          templateUrl: 'app/loja/utilizador/perfil/perfil.html',
          controller: 'PerfilController',
          controllerAs: 'ctrl',
          resolve: {
            translatePartialLoader: function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('utilizador.perfil');
              return $translate.refresh();
            }
          }
        })
        .state('loja.perfil.mostra', {
          url: '/mostra',
          title: 'utilizador.states.perfil',
          params: {authenticate: true},
          templateUrl: 'app/loja/utilizador/perfil/perfil.dadosfaturacao.html',
          controller: 'PerfilController',
          controllerAs: 'ctrl'
        })
        .state('loja.perfil.editar', {
          url: '/edita',
          title: 'utilizador.states.perfil',
          params: {authenticate: true},
          templateUrl: 'app/loja/utilizador/perfil/perfil.dadosfaturacao.edit.html',
          controller: 'PerfilController',
          controllerAs: 'ctrl'
        })
        .state('loja.perfil.editarMorada', {
          url: '/editaMorada/:moralid',
          title: 'utilizador.states.perfil',
          params: {
            authenticate: true,
            moralid: {value: null, squash: true, dynamic: true}
          },
          templateUrl: 'app/loja/utilizador/perfil/perfil.morada.edit.html',
          controller: 'PerfilController',
          controllerAs: 'ctrl'
        })
        .state('loja.encomendas', {
          url: '/encomendas/:faccbId',
          title: 'utilizador.states.encomendasPendentes',
          params: {
            authenticate: true,
            faccbId: {value: null, squash: true, dynamic: true}
          },
          templateUrl: 'app/loja/utilizador/encomendas/encomendas.html',
          controller: 'EncomendasController',
          controllerAs: 'ctrl'
        })
        .state('loja.faturas', {
          url: '/faturas/:faccbId',
          title: 'utilizador.states.faturas',
          params: {
            authenticate: true,
            faccbId: {value: null, squash: true, dynamic: true}
          },
          templateUrl: 'app/loja/utilizador/faturas/faturas.html',
          controller: 'FaturasController',
          controllerAs: 'ctrl'
        })
        .state('loja.movimentosAberto', {
          url: '/movimentosaberto',
          title: 'utilizador.states.movimentosAberto',
          params: {authenticate: true},
          templateUrl: 'app/loja/utilizador/movimentosAberto/movimentosAberto.html',
          controller: 'MovimentosAbertoController',
          controllerAs: 'ctrl'
        });
    });
})();
