(function () {
  'use strict';
  angular.module('erpRetail')

    .run(['WidgetsFactory', function (WidgetsFactory) {
      WidgetsFactory.register('widget-produtos', 'widget-produtos-edit', 'Produtos');
    }])

    .directive('widgetProdutos', function () {

      var controller = ['$scope', 'WidgetsFactory', 'Utils', 'storage', 'ProdutosFactory', 'configFactory', '$rootScope', 'Auth', 'clienteService',
        function ($scope, WidgetsFactory, Utils, storage, ProdutosFactory, configFactory, $rootScope, Auth, clienteService) {
          var vm = this;
          vm.item = $scope.item;
          vm.ctrl = {
            config: {},
            noimage: storage.noimage,
            getImagem: storage.getImagem,
            addBasket: function (item) {
              return storage.addBasket(item);
            },
            getDatetime: function () {
              return $rootScope.getDatetime;
            },
            showDetail: function (item) {
              return storage.showDetail(item);
            },
            showDetailClassificador: function (item) {
              return storage.showDetailClassificador(item);
            }
          };

          vm.cliente = {};
          vm.user = Auth.getCurrentUser();

          if (angular.isDefined(vm.user.token)) {
            vm.getUserInfo = function () {
              clienteService.getCliente().then(function (cliente) {
                vm.cliente = cliente;
              });
            };
            vm.getUserInfo();
          }

          vm.produtos = [];
          vm.getDatetime = $rootScope.getDatetime;
          configFactory.getConfigSite().then(
            function (config) {
              $scope.config = config;
            });


          vm.refresh = function () {

            WidgetsFactory.log('widgetProdutos refresh');
            if (Utils.isNullOrUndefined(vm.item.data.tipo)) {
              vm.produtos = [];
              return;
            }

            if (vm.item.data.tipo === 0) {
              storage.getProdutosEmPromocao().then(function (data) {
                vm.produtos = data.data.data;
              });
            } else if (vm.item.data.tipo === 1) {
              ProdutosFactory.getProdutosCatalogo(vm.item.data.catalogo.mbcatalogoCabID).then(function (produtos) {
                vm.produtos = produtos;
              });
            }
          };

          vm.refresh();

          configFactory.getConfigSite().then(function (data) {
            vm.ctrl.config = data;
          });

          return vm;
        }];

      function link(scope, element, attrs, $compile) {
        //scope.$watch('item', function () {
        //    scope.refresh();
        //});

      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/produtos/produtos.html',
        link: link,
        controller: controller,
        controllerAs: 'vm'
      };

    })

    .directive('widgetProdutosEdit', function () {

      var controller = ['WidgetsFactory', 'ProdutosFactory', 'Utils', '$scope', function (WidgetsFactory, ProdutosFactory, Utils, $scope) {
        $scope.catalogos = [];

        $scope.tipos = [{tipo: 0, name: 'Promoções nos artigos'}, {tipo: 1, name: 'Catalogos'}];

        $scope.refresh = function () {
          if (Utils.isNullOrUndefined($scope.item.data.tipo)) {
            $scope.item.data.tipo = 0;
          }
          ProdutosFactory.getCatalogos().then(
            function (data) {
              $scope.catalogos = data;
              if ($scope.catalogos.length > 0) $scope.item.data.catalogo = data[0];
            });
        };

        $scope.refresh();
      }];

      //function link(scope, element, attrs, $compile) {
      //    scope.$watch('item', function () {
      //        scope.refresh();
      //    });
      //
      //}

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/produtos/produtos.edit.html',
        //link: link,
        controller: controller
      };

    });
})();
