(function () {
  'use strict';
  angular.module('erpRetail')

    .config(['$stateProvider', function ($stateProvider) {
      $stateProvider
        .state('admin.paginas', {
          abstract: true,
          url: '/paginas',
          params: {authenticate: 'admin'},
          title: 'admin.config.paginas.states.editarPaginas',
          templateUrl: 'app/admin/paginas/paginas.html'
        })
        .state('admin.paginas.list', {
          url: '/lista',
          params: {authenticate: 'admin'},
          title: 'admin.config.paginas.states.manutencaoPaginas',
          templateUrl: 'app/admin/paginas/paginas.list.html',
          controllerAs: 'ctrl',
          controller: 'PaginasController'
        })
        .state('admin.paginas.nova', {
          url: '/nova',
          params: {authenticate: 'admin'},
          title: 'admin.config.paginas.states.novaPagina',
          templateUrl: 'app/admin/paginas/paginas.edit.html',
          controllerAs: 'ctrl',
          controller: 'PaginasEditController'

        })
        .state('admin.paginas.edit', {
          url: '/editar/:id',
          params: {authenticate: 'admin'},
          title: 'admin.config.paginas.states.editarPagina',
          templateUrl: 'app/admin/paginas/paginas.edit.html',
          controllerAs: 'ctrl',
          controller: 'PaginasEditController'

        });
    }]);
}());
