(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function (API_URL, REST_APIProvider, cgReservasEstadiasProvider) { // jshint ignore:line
      cgReservasEstadiasProvider.setConfig({
        apiBaseUrl: API_URL + 'api',
        apiEndPoint: '/reservasEstadias',
        imageFallbackSource: REST_APIProvider.PRODUTOS_NOIMAGE, // jshint ignore:line
        templatesHTML: {
          rgpdTermsBody: 'app/components/widgets/reservasestadias/reservasestadias.rgpd.html'
        }
      });
    })
    .run(function (WidgetsFactory) {
      WidgetsFactory.register('reservas-estadias', 'reservas-estadias-edit', 'widgets.reservasestadias.title');
    })
    .component('reservasEstadias', {
      bindings: {
        item: '=',
        properties: '<?'
      },
      templateUrl: 'app/components/widgets/reservasestadias/reservasestadias.html',
      controllerAs: '$ctrl',
      controller: /*@ngInject*/ function ($uiRouterGlobals) {
        var ctrl = this;

        ctrl.token = null;

        ctrl.$onInit = init;

        /////////////

        function init() {
          ctrl.token = $uiRouterGlobals.params.token;
        }
      }
    });
})();
