(function () {
  'use strict';

  angular.module('erpRetail')
    .directive('printDiv', function ($window) {
      return {
        restrict: 'A',
        scope: {
          printDiv: '@'
        },
        link: function (scope, element) {
          element.on('click', function () {
            var printContents = angular.element(scope.printDiv).clone();
            var print = angular.element('#print');
            print.html(printContents);
            $window.print();
            print.html();
            return true;
          });

          scope.$on('$destroy', function () {
            element.off('click');
          });
        }
      };
    });
})();
