(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function ($stateProvider) {
      $stateProvider
        .state('disconnected', {
          url: '/disconnected',
          templateUrl: 'app/account/disconnected/disconnected.html',
          controller: 'DisconnetedController',
          controllerAs: '$ctrl'
        });
    });
}());
