(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('Utils', /*@ngInject*/ function ($rootScope) {
      var service = {
        isNullOrUndefined: function (obj) {
          return angular.isUndefined(obj) || obj === null;
        },
        isNullOrEmpty: function (obj) {
          var ret = angular.isUndefined(obj) || obj === null;
          if (ret) {
            return ret;
          }
          return obj === '';
        },
        translate: function (value, params) {
          return $rootScope.globalTranslate(value, params);
        },
        sameText: function (value1, value2) {
          if (value1 === null && value2 === null) {
            return true;
          }
          if (value1 === null && value2 !== null) {
            return false;
          }
          if (value1 !== null && value2 === null) {
            return false;
          }
          return (value1.toLowerCase() === value2.toLowerCase());
        },
        toNumber: function (value) {
          if (service.isNullOrEmpty(value)) {
            return 0;
          }
          return parseInt(value);
        },
        combineDateTime: function(date, time){
          var myDate=date;
          var myTime=time;
          if (angular.isString(date))
            myDate = new Date(date);
          if (angular.isString(time))
            myTime = new Date(time);
            

        var datePart = myDate.toISOString().substring(0, 10);
        var timePart = myTime.toISOString().substring(10, 24);
        return new Date(datePart + timePart);
      }
      };
      return service;
    });
})();
