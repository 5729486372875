(function () {
    'use strict';

    angular.module('erpRetail')
        .factory('EmpresaFactory', ['$q', '$http', 'REST_API', function ($q, $http, REST_API) {
            return {
                get: function () {
                    var deferred = $q.defer();

                    $http.get(REST_API.HOST + REST_API.EMPRESA)
                        .then(function (data) {
                            deferred.resolve(data.data);
                        });
                    return deferred.promise;
                }
            };
        }]);
}());
