(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('FaturasController', /*@ngInject*/ function ($rootScope, $q, $state, $transition$, documentosService, documentoService, Utils, PopAlert) {
      var ctrl = this;

      ctrl.params = $transition$.params();

      ctrl.list = [];
      ctrl.activeDoc = null;
      ctrl.config = {};
      ctrl.source = {};
      ctrl.pdfUrl = '#';
      ctrl.callback = {};
      ctrl.definition = {};
      ctrl.ndoc = '';

      ctrl.init = init;
      ctrl.onLoad = onLoad;
      ctrl.onDocSelect = onDocSelect;
      ctrl.onDocDetail = onDocDetail;

      ctrl.init();

      /////////////

      function init() {
        ctrl.definition = {
          order: 'stampInsert desc',
          options: {
            perPage: 10
          },
          fields: [
            {name: 'stampInsert', type: 'timestamp', visible: false, caption: ''},
            {name: 'docNome', type: 'text', caption: 'ACOOUNT_DOCS_TABLE_TH_DOCNOME'},
            {name: 'nDoc', type: 'text', caption: 'ACOOUNT_DOCS_TABLE_TH_NDOC'},
            {name: 'dataDoc', type: 'date', caption: 'ACCOUNT_DOCS_TABLE_TH_DATA'},
            {name: 'totalDocumento', align: 'right', type: 'currency', caption: 'ACCOUNT_DOCS_TABLE_TH_TOTAL'},
            {name: 'nomeEstado', caption: 'ADMIN_DOCS_TABLE_TH_STATE'}
          ]
        };

        ctrl.onLoad();
      }

      function onLoad(search, order, page, perpage) {
        var deferred = $q.defer();
        documentosService.getComerciais(ctrl.ndoc, page, perpage, order)
          .then(function (response) {
            var linhas = response.data.list.map(function (item) {
              item._$ctrl = ctrl;

              return item;
            });
            deferred.resolve(linhas);
          })
          .catch(function (reason) {
            deferred.reject(reason);
            PopAlert.showError($rootScope.globalTranslate('utilizador.faturas.fetchError'));
          });
        return deferred.promise;
      }

      function onDocSelect(item) {
        item._detailOpen = !item._detailOpen;
        if (item._detailOpen) {
          return ctrl.onDocDetail(item);
        }
      }

      function onDocDetail(item) {
        if (item._thedoc) {
          return;
        }
        var deferred = $q.defer();
        documentoService.getDocOnline(item.faccbId)
          .then(function (response) {
            item._thedoc = response.data.data;
            deferred.resolve();
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
        return deferred.promise;
      }
    });
})();
