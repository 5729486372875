(function () {
  'use strict';

  aiFilter.$inject = ['cgClass'];

  function aiFilter(cgClass) {
    return function (array, expression, comparator) {
      if (!angular.isArray(array)) {
        return array;
      }

      var predicateFn;
      var matchAgainstAnyProp;

      switch (typeof expression) {
        case 'function':
          predicateFn = expression;
          break;
        case 'boolean':
        case 'number':
        case 'string':
          matchAgainstAnyProp = true; // jshint ignore:line
        // eslint-disable-next-line no-fallthrough
        case 'object':
          predicateFn = createPredicateFn(expression, comparator, matchAgainstAnyProp, cgClass);
          break;
        default:
          return array;
      }

      return array.filter(predicateFn);
    };
  }

  // Helper functions for `filterFilter`
  function createPredicateFn(expression, comparator, matchAgainstAnyProp, cgClass) {
    var shouldMatchPrimitives = angular.isObject(expression) && ('$' in expression);
    var predicateFn;

    if (comparator === true) {
      comparator = angular.equals;
    }
    else if (!angular.isFunction(comparator)) {
      comparator = function (actual, expected) {
        if (angular.isObject(actual) || angular.isObject(expected)) {
          // Prevent an object to be considered equal to a string like `'[object'`
          return false;
        }

        actual = cgClass.accentFold(('' + actual).toLowerCase());
        expected = cgClass.accentFold(('' + expected).toLowerCase());
        return actual.indexOf(expected) !== -1;
      };
    }

    predicateFn = function (item) {
      if (shouldMatchPrimitives && !angular.isObject(item)) {
        return deepCompare(item, expression.$, comparator, false);
      }
      return deepCompare(item, expression, comparator, matchAgainstAnyProp);
    };

    return predicateFn;
  }

  function deepCompare(actual, expected, comparator, matchAgainstAnyProp, dontMatchWholeObject) {
    var actualType = (actual !== null) ? typeof actual : 'null';
    var expectedType = (expected !== null) ? typeof expected : 'null';

    if (angular.isString(expectedType) && (expected.charAt(0) === '!')) {
      return !deepCompare(actual, expected.substring(1), comparator, matchAgainstAnyProp);
    }
    else if (angular.isArray(actual)) {
      // In case `actual` is an array, consider it a match
      // if ANY of it's items matches `expected`
      return actual.some(function (item) {
        return deepCompare(item, expected, comparator, matchAgainstAnyProp);
      });
    }

    switch (actualType) {
      case 'object':
        var key;
        if (matchAgainstAnyProp) {
          for (key in actual) {
            if (Object.prototype.hasOwnProperty.call(actual, key) && (key.charAt(0) !== '$') && deepCompare(actual[key], expected, comparator, true)) {
              return true;
            }
          }
          return dontMatchWholeObject ? false : deepCompare(actual, expected, comparator, false);
        }
        else if (angular.isObject(expectedType)) {
          for (key in expected) {
            if (Object.prototype.hasOwnProperty.call(expected, key)) {
              var expectedVal = expected[key];
              if (angular.isFunction(expectedVal) || angular.isUndefined(expectedVal)) {
                continue;
              }

              var matchAnyProperty = key === '$';
              var actualVal = matchAnyProperty ? actual : actual[key];
              if (!deepCompare(actualVal, expectedVal, comparator, matchAnyProperty, matchAnyProperty)) {
                return false;
              }
            }
          }
          return true;
        }
        else {
          return comparator(actual, expected);
        }
        break;
      case 'function':
        return false;
      default:
        return comparator(actual, expected);
    }
  }

  angular.module('erpRetail')
    .filter('encodeURI', function ($window) {
      return $window.encodeURI;
    })

    .filter('offset', function () {
      return function (input, start) {
        start = parseInt(start, 10);
        return input.slice(start);
      };
    })

    .filter('AIOrderBy', function (orderByFilter, $parse, cgClass) {
      function map(obj, iterator, context) {
        var results = [];
        angular.forEach(obj, function (value, index, list) {
          results.push(iterator.call(context, value, index, list));
        });
        return results;
      }

      var lowercase = function (string) {
        return string;
      };

      function toBoolean(value) {
        if (angular.isFunction(value)) {
          value = true;
        }
        else if (value && value.length !== 0) {
          var v = lowercase('' + value);
          value = !(v === 'f' || v === '0' || v === 'false' || v === 'no' || v === 'n' || v === '[]');
        }
        else {
          value = false;
        }
        return value;
      }

      return function (array, sortPredicate, reverseOrder) {
        if (!angular.isArray(array)) return array;
        if (!sortPredicate) return array;
        sortPredicate = angular.isArray(sortPredicate) ? sortPredicate : [sortPredicate];
        sortPredicate = map(sortPredicate, function (predicate) {
          var descending = false, get = predicate || angular.identity;
          if (angular.isString(predicate)) {
            if ((predicate.charAt(0) === '+' || predicate.charAt(0) === '-')) {
              descending = predicate.charAt(0) === '-';
              predicate = predicate.substring(1);
            }
            get = $parse(predicate);
          }
          return reverseComparator(function (a, b) {
            return compare(get(a), get(b));
          }, descending);
        });
        var arrayCopy = [];
        for (var i = 0; i < array.length; i++) {
          arrayCopy.push(array[i]);
        }
        return arrayCopy.sort(reverseComparator(comparator, reverseOrder));

        function comparator(o1, o2) {
          for (var i = 0; i < sortPredicate.length; i++) {
            var comp = sortPredicate[i](o1, o2);
            if (comp !== 0) return comp;
          }
          return 0;
        }

        function reverseComparator(comp, descending) {
          return toBoolean(descending) ? function (a, b) {
              return comp(b, a);
            }
            : comp;
        }

        function compare(v1, v2) {
          var t1 = typeof v1;
          var t2 = typeof v2;
          if (t1 === t2) {
            if (angular.isString(v1)) {
              v1 = cgClass.accentFold(v1.toLowerCase());
              v2 = cgClass.accentFold(v2.toLowerCase());
            }
            if (v1 === v2) {
              return 0;
            }
            return v1 < v2 ? -1 : 1;
          }
          else {
            return t1 < t2 ? -1 : 1;
          }
        }
      };
    })


    /**
     * @ngdoc filter
     * @name filter
     * @kind function
     *
     * @description
     * Selects a subset of items from `array` and returns it as a new array.
     *
     * @param {Array} array The source array.
     * @param {string|Object|function()} expression The predicate to be used for selecting items from
     *   `array`.
     *
     *   Can be one of:
     *
     *   - `string`: The string is evaluated as an expression and the resulting value is used for substring match against
     *     the contents of the `array`. All strings or objects with string properties in `array` that contain this string
     *     will be returned. The predicate can be negated by prefixing the string with `!`.
     *
     *   - `Object`: A pattern object can be used to filter specific properties on objects contained
     *     by `array`. For example `{name:"M", phone:"1"}` predicate will return an array of items
     *     which have property `name` containing "M" and property `phone` containing "1". A special
     *     property name `$` can be used (as in `{$:"text"}`) to accept a match against any
     *     property of the object. That's equivalent to the simple substring match with a `string`
     *     as described above. The predicate can be negated by prefixing the string with `!`.
     *     For Example `{name: "!M"}` predicate will return an array of items which have property `name`
     *     not containing "M".
     *
     *   - `function(value, index)`: A predicate function can be used to write arbitrary filters. The
     *     function is called for each element of `array`. The final result is an array of those
     *     elements that the predicate returned true for.
     *
     * @param {function(actual, expected)|true|undefined} comparator Comparator which is used in
     *     determining if the expected value (from the filter expression) and actual value (from
     *     the object in the array) should be considered a match.
     *
     *   Can be one of:
     *
     *   - `function(actual, expected)`:
     *     The function will be given the object value and the predicate value to compare and
     *     should return true if the item should be included in filtered result.
     *
     *   - `true`: A shorthand for `function(actual, expected) { return angular.equals(expected, actual)}`.
     *     this is essentially strict comparison of expected and actual.
     *
     *   - `false|undefined`: A short hand for a function which will look for a substring match in case
     *     insensitive way.
     *
     * @example
     <example>
     <file name="index.html">
     <div ng-init="friends = [{name:'John', phone:'555-1276'},
     {name:'Mary', phone:'800-BIG-MARY'},
     {name:'Mike', phone:'555-4321'},
     {name:'Adam', phone:'555-5678'},
     {name:'Julie', phone:'555-8765'},
     {name:'Juliette', phone:'555-5678'}]"></div>

     Search: <input ng-model="searchText">
     <table id="searchTextResults">
     <tr><th>Name</th><th>Phone</th></tr>
     <tr ng-repeat="friend in friends | filter:searchText">
     <td>{{friend.name}}</td>
     <td>{{friend.phone}}</td>
     </tr>
     </table>
     <hr>
     Any: <input ng-model="search.$"> <br>
     Name only <input ng-model="search.name"><br>
     Phone only <input ng-model="search.phone"><br>
     Equality <input type="checkbox" ng-model="strict"><br>
     <table id="searchObjResults">
     <tr><th>Name</th><th>Phone</th></tr>
     <tr ng-repeat="friendObj in friends | filter:search:strict">
     <td>{{friendObj.name}}</td>
     <td>{{friendObj.phone}}</td>
     </tr>
     </table>
     </file>
     <file name="protractor.js" type="protractor">
     var expectFriendNames = function(expectedNames, key) {
         element.all(by.repeater(key + ' in friends').column(key + '.name')).then(function(arr) {
           arr.forEach(function(wd, i) {
             expect(wd.getText()).toMatch(expectedNames[i]);
           });
         });
       };

     it('should search across all fields when filtering with a string', function() {
         var searchText = element(by.model('searchText'));
         searchText.clear();
         searchText.sendKeys('m');
         expectFriendNames(['Mary', 'Mike', 'Adam'], 'friend');

         searchText.clear();
         searchText.sendKeys('76');
         expectFriendNames(['John', 'Julie'], 'friend');
       });

     it('should search in specific fields when filtering with a predicate object', function() {
         var searchAny = element(by.model('search.$'));
         searchAny.clear();
         searchAny.sendKeys('i');
         expectFriendNames(['Mary', 'Mike', 'Julie', 'Juliette'], 'friendObj');
       });
     it('should use a equal comparison when comparator is true', function() {
         var searchName = element(by.model('search.name'));
         var strict = element(by.model('strict'));
         searchName.clear();
         searchName.sendKeys('Julie');
         strict.click();
         expectFriendNames(['Julie'], 'friendObj');
       });
     </file>
     </example>
     */
    .filter('AIFilter', aiFilter)


    .filter('AIhighlight', function () {
      return function (text, search) {
        if (text && (search || angular.isNumber(search))) {
          text = text.toString();
          search = search.toString();
          // TODO acabar função AIhighlight

          return text.replace(new RegExp(search, 'gi'), '<span class="ui-match">$&</span>');
        } else {
          return text;
        }
      };
    });
}());
