(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('confirmaPagamentoModal', function (CGModalService) {
      return {
        confirmaPagamento: confirmaPagamento
      };

      /////////////

      function confirmaPagamento(doc) {
        return CGModalService.show({
          templateUrl: 'app/components/confirmaPagamentoModal/confirma.pagamento.modal.html',
          controller: 'ConfirmaPagamentoModalController',
          resolve: {
            item: function () {
              return doc;
            }
          }
        });
      }
    });
})();
