(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('MenuController', function (configFactory, PopAlert, $uibModal, $translate, MenuService, PaginasFactory, Utils) {
      var ctrl = this;

      ctrl.data = [{}];
      ctrl.mnuHome = {};
      ctrl.mnuContactos = {};
      ctrl.mnuTermos = {};

      ctrl.init = init;
      ctrl.moveLastToTheBeginning = moveLastToTheBeginning;
      ctrl.addEmptyMenu = addEmptyMenu;
      ctrl.newSubItem = newSubItem;
      ctrl.collapseAll = collapseAll;
      ctrl.expandAll = expandAll;
      ctrl.tipoChangeHome = tipoChangeHome;
      ctrl.save = save;
      ctrl.edit = edit;
      ctrl.refresh = refresh;

      ctrl.init();

      /////////////

      function init() {
        ctrl.mnuHome = {page: 0, tipos: [], tipo: 'sistema'};
        ctrl.mnuContactos = {page: 0, tipos: [], tipo: 'sistema'};
        ctrl.mnuTermos = {page: 0, tipos: [], tipo: 'sistema'};
        ctrl.refresh();
      }

      function moveLastToTheBeginning() {
        var a = ctrl.data.pop();
        ctrl.data.splice(0, 0, a);
      }

      function addEmptyMenu() {
        ctrl.data.push({
          title: $translate.instant('admin.menus.newMenu'),
          url: '',
          pageId: 1,
          fontAwesome: 'fa-home',
          nodes: []
        });
      }

      function newSubItem(node) {
        if (!node.nodes) {
          node.nodes = [];
        }
        node.nodes.push({
          title: node.title + '.' + (node.nodes.length + 1),
          pageId: node.pageId * 10 + node.nodes.length,
          fontAwesome: node.fontAwesome,
          nodes: []
        });
      }

      function collapseAll() {
        ctrl.$broadcast('collapseAll');
      }

      function expandAll() {
        ctrl.$broadcast('expandAll');
      }

      function tipoChangeHome(mnu) {
        if (mnu.tipo === 'sistema') {
          mnu.tipos = MenuService.getMenuSystem();
          mnu.tipos = mnu.tipos.slice(1);
        }
        else if (mnu.tipo === 'paginas') {
          mnu.tipos = [];
          PaginasFactory.getAll().then(function (data) {
            for (var i = 0; i < data.length; i++) {
              mnu.tipos.push({id: data[i].id, title: data[i].title});
            }
          });
        }
      }

      function save() {
        var values = [
          {configID: 'site.menu', value: ctrl.data},
          {configID: 'site.pages.home', value: ctrl.mnuHome.page},
          {configID: 'site.pages.contactos', value: ctrl.mnuContactos.page},
          {configID: 'site.pages.termos', value: ctrl.mnuTermos.page}
        ];
        configFactory.saveValue(values, function (ok) {
          if (ok) {
            PopAlert.showSuccess('admin.menus.menuSaved');
            configFactory.refresh(true);
          }
        });
      }

      function edit(node) {
        var modalInstance = $uibModal.open({
          templateUrl: 'app/admin/menu/menu.edit.html',
          controller: 'MenuEditPopupController',
          controllerAs: 'ctrl',
          resolve: {
            node: function () {
              return node;
            }
          }
        });

        modalInstance.result.then(function (itemSave) {
          node.title = itemSave.title;
          node.pageId = itemSave.pageId;
          node.fontAwesome = itemSave.fontAwesome;
          ctrl.save();
        });
      }

      function refresh() {
        configFactory.getConfigSiteAdmin().then(
          function (data) {
            ctrl.data = data.menu;

            function mnuSync(mnu, page) {
              mnu.page = Utils.toNumber(page);
              if (mnu.page <= 0) {
                mnu.tipo = 'sistema';
              }
              else {
                mnu.tipo = 'paginas';
              }
              ctrl.tipoChangeHome(mnu);
            }

            mnuSync(ctrl.mnuHome, data.pages.home);
            mnuSync(ctrl.mnuContactos, data.pages.contactos);
            mnuSync(ctrl.mnuTermos, data.pages.termos);
          }
        );
      }
    })
    .controller('MenuEditPopupController', function (node, $uibModalInstance, MenuService, PaginasFactory) {
      var ctrl = this;

      ctrl.item = angular.copy(node);
      ctrl.tipos = [];
      ctrl.tipo = 'sistema';

      ctrl.$onInit = init;
      ctrl.refresh = refresh;
      ctrl.cancel = cancel;
      ctrl.tipoChange = tipoChange;
      ctrl.save = save;
      ctrl.remove = remove;

      /////////////

      function init() {
        ctrl.refresh();
      }

      function refresh() {
        if (ctrl.item.pageId <= 0) {
          ctrl.tipo = 'sistema';
        } else {
          ctrl.tipo = 'paginas';
        }
        ctrl.tipoChange();
      }

      function cancel() {
        $uibModalInstance.dismiss('cancel');
      }

      function tipoChange() {
        if (ctrl.tipo === 'sistema') {
          ctrl.tipos = MenuService.getMenuSystem();
        }
        else if (ctrl.tipo === 'paginas') {
          ctrl.tipos = [];
          PaginasFactory.getAll().then(function (data) {
            ctrl.tipos = [];
            for (var i = 0; i < data.length; i++) {
              ctrl.tipos.push({id: data[i].id, title: data[i].title});
            }
          });

        }
      }

      function save() {
        $uibModalInstance.close(ctrl.item);
      }

      function remove(item) {
        item.remove(item);
      }
    });
})();
