(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('ImagensFactory', ['$q', '$http', 'REST_API', 'Utils', function ($q, $http, REST_API, Utils) {


      return {
        getList: function (folder) {
          var deferred = $q.defer();
          var url = REST_API.HOST + REST_API.IMAGE_LIST;
          if (!Utils.isNullOrEmpty(folder)) {
            url += '/' + folder;
          }
          $http.get(url)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              deferred.reject(err);
            });
          return deferred.promise;
        },
        getImagem: function (src, def) {
          if (Utils.isNullOrEmpty(src) && !Utils.isNullOrEmpty(def)) {
            return def;
          }
          var url = REST_API.HOST + REST_API.IMAGE_GET;
          url += '/' + src;
          return url;
        },
        getUrlImagem: function (folder, name) {

          var url = REST_API.HOST + REST_API.IMAGE_GET;
          if (!Utils.isNullOrEmpty(folder)) {
            url += '/' + folder;
          }
          url += '/' + name;
          return url;
        },
        deleteImagem: function (folder, name) {
          var deferred = $q.defer();
          var url = this.getUrlImagem(folder, name);
          $http.delete(url)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (error) {
              deferred.reject(error);
            });
          return deferred.promise;
        },

        renameImagem: function (folder, from, to) {
          var deferred = $q.defer();
          $http.post(REST_API.HOST + REST_API.IMAGE_RENAME, {folder: folder, from: from, to: to})
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              deferred.reject(err.data);
            });
          return deferred.promise;
        }
      };
    }]);
}());

