(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('storage', function ($rootScope, $sessionStorage, $localStorage, $http, $q, $state, $log, configFactory, REST_API, Utils, PopAlert, Lightbox, paisesService,
                                  Auth, clienteService, PagamentosFactory) {
        var factory = this;

        factory.init = init;
        factory.clear = clear;
        factory.loadCliente = loadCliente;
        factory.reloadCondicoesPagamento = reloadCondicoesPagamento;
        factory.loadPaises = loadPaises;
        factory.getClassificadorByName = getClassificadorByName;
        factory.getClassificadorById = getClassificadorById;
        factory.showArtigos = showArtigos;
        factory.showDetail = showDetail;
        factory.showDetailClassificador = showDetailClassificador;
        factory.getImagem = getImagem;
        factory.getImagemByDocId = getImagemByDocId;
        factory.getfirstImagem = getfirstImagem;
        factory.qtdInc = qtdInc;
        factory.addBasket = addBasket;
        factory.addBasketByNArtigo = addBasketByNArtigo;
        factory.calcBasket = calcBasket;
        factory.getArtigo = getArtigo;
        factory.calcBasketOnline = calcBasketOnline;
        factory.getProdutosEmPromocao = getProdutosEmPromocao;
        factory.pesquisa = pesquisa;
        factory.getTextoClassificador = getTextoClassificador;
        factory.openLightboxModal = openLightboxModal;
        factory.loadCondicoesPagamentoList = loadCondicoesPagamentoList;
        factory.fillDescricaoCondicaoPagamento = fillDescricaoCondicaoPagamento;
        factory.loadCondicoesPagamentoList = loadCondicoesPagamentoList;
        factory.getStockArtigoOnline = getStockArtigoOnline;

        factory.eventUser = $rootScope.$on('event-user', function (event, args) {
          if (angular.equals(Auth.currentUser, {})) {
            factory.clienteData = {};
            clear();
          } else {
            init();
          }
        });

        factory.eventUserLoggedOut = $rootScope.$on('event-logout', function (event, args) {
          factory.clear();
        });


        function clear() {
          factory.cesto = [];
          factory.carrinhoTotal = {
            nItens: 0,
            custoTransporte: 0.0,
            desconto: 0.0,
            total: 0.0,
            totalIva: 0.0
          };
          factory.ncondpag = 0;
          factory.codTransportadora = 0;
          if (angular.isArray(factory.condicoesPagamentoList)) {
            factory.condicoesPagamentoList.forEach(function (item) {
              item.checked = false;
            });
          }
          delete factory.dataEntregaPrev;
        }

        function init() {
          factory.clear();
          factory.loadDefered = $q.defer();
          factory.loadPromise = factory.loadDefered.promise;


          $localStorage = $localStorage.$default({cesto: []});
          factory.clientPromise = $q.defer();
          factory.ncondpag = 0;
          factory.codTransportadora = 0;

          factory.carrinhoTotal = {nItens: 0, total: 0};
          factory.lastSearch = '';
          factory.noimage = REST_API.PRODUTOS_NOIMAGE;
          factory.imagePath = REST_API.HOST + REST_API.PRODUTOS_IMAGE_PATH;
          factory.clienteData = null;

          factory.step = 1;
          factory.qtdMin = 1;
          factory.qtdMax = 999;

          factory.tipoTabelaImagens = {
            none: {id: 0, identifier: ''},
            tipoArtigo: {id: 1, identifier: 'codTpArt'},
            grFamilia: {id: 2, identifier: 'ndepart'},
            familia: {id: 3, identifier: 'nfamilia'},
            subFamilia: {id: 4, identifier: 'nsubFa'},
            categoria: {id: 5, identifier: 'categoria'},
            classe: {id: 6, identifier: 'classe'}
          };
          Object.defineProperty(factory, 'cesto', {
            get: function () {
              return $localStorage.cesto;
            },
            set: function (value) {
              $localStorage.cesto = value;
              factory.calcBasket();
            }
          });

          loadPaises();

          factory.reloadCondicoesPagamento().then(function () {
            factory.loadCondicoesPagamentoList();
          });
          loadCliente();
        }

        init();

        function loadPaises() {
          paisesService.getPaises().then(function (paises) {
            factory.paises = paises;
          });
        }

        function getClassificadorByName(name) {
          switch (name) {
            case 'Classe':
              return factory.tipoTabelaImagens.classe;
            case 'Tipo de Artigo':
              return factory.tipoTabelaImagens.tipoArtigo;
            case 'Grande Família':
              return factory.tipoTabelaImagens.grFamilia;
            case 'Família':
              return factory.tipoTabelaImagens.familia;
            case 'Sub-família':
              return factory.tipoTabelaImagens.subFamilia;
            case 'Categoria':
              return factory.tipoTabelaImagens.categoria;
          }
        }

        function getClassificadorById(id) {
          var classificador = null;
          angular.forEach(factory.tipoTabelaImagens, function (tipo) {
            if (id === tipo.id) {
              classificador = tipo;
            }
          });
          return classificador;
        }

        function showArtigos() {
          if (angular.isUndefined(factory.lastUrlName)) {
            $state.go('loja.produtos');
          } else {
            $state.go(factory.lastUrlName);
          }
        }

        function showDetail(artigo) {
          factory.lastUrlName = $state.current.name;
          $state.go('loja.produto', {nartigo: artigo.nArtigo || artigo.nartigo});
        }

        function showDetailClassificador(classificador) {
          factory.lastUrlName = $state.current.name;
          $state.go('loja.classificador', {tabela: classificador.tabela, nchave: classificador.chave, ordem: classificador.ordem, clearSearch: true});
        }

        function getImagem(artigo) {
          if (!artigo || !artigo.imagem) {
            return factory.noimage;
          }
          return factory.imagePath + artigo.imagem;
        }

        function getImagemByDocId(docId) {

          if (Utils.isNullOrUndefined(docId)) return factory.noimage;
          return factory.imagePath + docId;
        }

        function getfirstImagem(imagens) {
          if (Utils.isNullOrUndefined(imagens)) return factory.noimage;
          if (Utils.isNullOrUndefined(imagens[0])) return factory.noimage;

          return factory.imagePath + imagens[0].docId.replace('{', '').replace('}', '');
        }

        function qtdInc(value, item) {
          item.qtd += value;
        }

        function addBasket(artigo) {
          var aux = _.find(factory.cesto, function (rw) {
            return rw.nArtigo === artigo.nArtigo;
          });
          if (angular.isUndefined(aux) || !aux) {
            aux = angular.copy(artigo);
            if (!aux.qtd || aux.qtdMinEncome && aux.qtdMinEncome > 0 && aux.qtd < aux.qtdMinEncome) {
              aux.qtd = aux.qtdMinEncome;
            }
            aux.qtd = aux.qtd || 1.0;
            aux.preco *= aux.qtdPorUnidade1;
            factory.cesto.push(aux);
          } else if (artigo.qtd) {
            aux.qtd += artigo.qtd;
          } else {
            aux.qtd++;
          }
          PopAlert.showSuccess(artigo.nome + ' adicionado ao carrinho');
          factory.calcBasket();
        }

        function addBasketByNArtigo(nArtigo) {
          factory.getArtigo(nArtigo).then(
            function (response) {
              factory.addBasket(response.artigo);
            },
            function (error) {
              $log.error(error);
              PopAlert.showError('CART_ERROR_ADD_TO_CART');
            }
          );
        }

        function calcBasket() {
          factory.calcBasketOnline(factory.ncondpag, factory.codTransportadora).then(function (data) {

            factory.carrinhoTotal = {
              nItens: 0.0,
              totalSemDesconto: 0.0,
              total: data.cab.custoTransporte,
              desconto: 0.0,
              custoTransporte: data.cab.custoTransporte,
              totalIva: 0.0

            };
            for (var i = 0; i < factory.cesto.length; i++) {

              factory.carrinhoTotal.nItens += parseInt((factory.cesto[i].qtd));
              var precoSemDesconto = factory.cesto[i].qtd * factory.cesto[i].precoSemDesconto;
              var desconto = precoSemDesconto - factory.cesto[i].valorLinha;
              factory.carrinhoTotal.totalSemDesconto += precoSemDesconto;
              factory.carrinhoTotal.total += factory.cesto[i].valorLinha;
              factory.carrinhoTotal.desconto += desconto;
              factory.carrinhoTotal.totalIva += factory.cesto[i].valorIva;

            }
          }, function (error) {
            $log.error(error);
            PopAlert.showError('CART_ERROR_CALC');
          });
        }

        function getArtigo(nArtigo) {
          if (Utils.isNullOrEmpty(nArtigo)) return null;
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.PRODUTO_DETALHE + '/' + nArtigo)
            .then(function (data) {
              data.data.artigo.qtd = data.data.artigo.qtdMinEncome || 1;
              data.data.artigo.collapsed = true;
              deferred.resolve(data.data);
            }, function (err) {
              deferred.reject(err.data);
            });
          return deferred.promise;
        }

        function calcBasketOnline(ncondpag, codTransportadora) {

          var deferred = $q.defer();


          var lista = [];
          for (var i = 0; i < factory.cesto.length; i++) {
            lista.push({
              qtd: factory.cesto[i].qtd,
              nArtigo: factory.cesto[i].nArtigo
            });
          }
          var param = {
            'cab': {
              'ncondpag': ncondpag,
              'codTransportadora': codTransportadora
            },
            'linhas': lista
          };

          $http.post(REST_API.HOST + REST_API.PRODUTO_BASKET_CALC, param)
            .then(function (data) {

              //factory.cesto = param.linhas;
              for (var i = 0; i < param.linhas.length; i++) {
                for (var j = 0; j < factory.cesto.length; j++) {
                  if (factory.cesto[j].nArtigo === data.data.linhas[i].nArtigo) {
                    factory.cesto[j].precoSemDesconto = data.data.linhas[i].calculoPrecos.precoSemDesconto;
                    factory.cesto[j].valorLinha = data.data.linhas[i].calculoPrecos.valorLinha;
                    factory.cesto[j].valorIva = data.data.linhas[i].calculoPrecos.valorIva;
                    break;
                  }
                }
              }
              deferred.resolve(data.data);
            }, function (err) {
              deferred.reject(err.data);
            });
          return deferred.promise;
        }

      function getStockArtigoOnline(nartigo) {
	factory.stockArmazens=[];
        factory.promise = $http.get(REST_API.HOST + REST_API.RESTAPI_ARTIGO+'/'+nartigo).then(function (response) {
          
          angular.forEach(response.data.data.stockArmazens, function(item){                  
                        factory.stockArmazens.push(item);                  
          });
        })['finally'](function () {
          factory.promise = null;
        });
      }

      function getProdutosEmPromocao() {
          return factory.pesquisa(null, null, null, null, null, null, null, null, null, null,
            null, null, true, null, null, null, null, null);
        }

        function pesquisa(pesquisa, catalogoCab, catalogoClasse, familia, subFamilia, grandeFamilia, classe, categoria, tipo, orderBy,
                          startOffset, recordCount, emPromo, livreLong04, livreLong05, comStockPotOuAReceber, tags, classificadores, tabelaTextosClassificadores) {
          var p = {
            params: {
              pesquisa: pesquisa,
              catalogoCab: catalogoCab,
              catalogoClasse: catalogoClasse,
              familia: familia,
              grandeFamilia: grandeFamilia,
              subFamilia: subFamilia,
              classe: classe,
              categoria: categoria,
              tipoArtigo: tipo,
              orderBy: orderBy,
              startOffset: startOffset,
              recordCount: recordCount,
              emPromo: emPromo,
              livreLong04: livreLong04,
              livreLong05: livreLong05,
              comStockPotOuAReceber: comStockPotOuAReceber,
              tags: tags ? angular.toJson(tags) : null,
              classificadores: classificadores,
              tabelaTextosClassificadores: tabelaTextosClassificadores
            }
          };
          return $http.get(REST_API.HOST + REST_API.PRODUTO_PESQUISA, p);
        }

        function getTextoClassificador(tabela, chave, ordem) {
          var deferred = $q.defer();
          var u = REST_API.HOST + REST_API.PRODUTO_TEXTO_CLASSIFICADOR + '/' + tabela + '/' + chave + '/' + ordem;
          $http.get(u)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              deferred.reject(err.data);
            });
          return deferred.promise;
        }

        function openLightboxModal(images, index) {
          return Lightbox.openModal(images, index, {templateUrl: 'app/components/lightbox/lightbox.html'});
        }

        function loadCliente() {
          if (Auth.isLoggedIn()) {
            clienteService.getCliente().then(function (cliente) {
              factory.clienteData = {};
              factory.clienteData.cliente = cliente;
              factory.clienteData.clienteMoradasAlt = [{
                moralid: '{00000000-0000-0000-0000-000000000000}',
                nome: 'Principal',
                contactoNome: factory.clienteData.cliente.nome,
                morada: factory.clienteData.cliente.morada,
                localidade: factory.clienteData.cliente.localidade,
                cpost: factory.clienteData.cliente.codPostal,
                codPaisIso: factory.clienteData.cliente.paisIso.codPaisIso,
                paisIso: {
                  codPaisIso: factory.clienteData.cliente.paisIso.codPaisIso,
                  nomePais: factory.clienteData.cliente.paisIso.nomePais
                },
                nipc: factory.clienteData.cliente.nifReal,
                contactoTelefone: factory.clienteData.cliente.telefone,
                isPrincipal: true,
                completed: factory.clienteData.cliente.completed,
                canChangeNIF: factory.clienteData.cliente.canChangeNIF
              }];
              if (cliente.completed) {
                clienteService.getClienteMoradasAlternativas().then(function (clienteMoradasAlt) {
                  factory.clienteData.clienteMoradasAlt = factory.clienteData.clienteMoradasAlt.concat(clienteMoradasAlt);
                  factory.clienteData.moradaEntrega = factory.clienteData.clienteMoradasAlt[0];
                  factory.clienteData.moradaFaturacao = factory.clienteData.clienteMoradasAlt[0];
                  factory.clienteData.clienteMoradasAltEdit = angular.copy(clienteMoradasAlt);
                  factory.clientPromise.resolve(factory.clienteData);
                  factory.loadDefered.resolve();
                  $rootScope.$broadcast('storage-loaded');
                });
              } else {
                factory.clienteData.moradaEntrega = factory.clienteData.clienteMoradasAlt[0];
                factory.clienteData.moradaFaturacao = factory.clienteData.clienteMoradasAlt[0];
                factory.clienteData.clienteMoradasAltEdit = [];
                factory.clientPromise.resolve(factory.clienteData);
                factory.loadDefered.resolve();
                $rootScope.$broadcast('storage-loaded');
              }
            });
          }
        }

        function reloadCondicoesPagamento() {
          var deferred = $q.defer();
          PagamentosFactory.condicoesPagamento('', -1, -1).then(
            function (pagamList) {
              factory.pagamList = pagamList;
              deferred.resolve();
            },
            function (err) {
              deferred.reject(err);
            }
          );
          return deferred.promise;
        }

        function loadCondicoesPagamentoList() {
          var defered = $q.defer();
          configFactory.getConfigSite().then(
            function (data) {
              factory.condicoesPagamentoList = data.condicoesPagamento;
              factory.fillDescricaoCondicaoPagamento();
              return defered.resolve();
            }, function (err) {
              return defered.reject(err);
            }
          );
          return defered.promise;
        }

        function fillDescricaoCondicaoPagamento() {
          for (var i = 0; i < factory.condicoesPagamentoList.length; i++) {
            var item = factory.condicoesPagamentoList[i];
            for (var j = 0; j < factory.pagamList.length; j++) {
              var pagam = factory.pagamList[j];
              if (pagam.ncondpag === item.ncondpag) {
                item.descricao = pagam.descricao;
                break;
              }
            }
          }
        }

        return factory;
      }


    );
}());
