(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('PagamentosFactory', function ($q, $http, $log, REST_API, $state) {
      return {
        paypal: function (MBFaccbId, estado, token) {
          var deferred = $q.defer();
          $http.post(REST_API.HOST + REST_API.PAGAMENTO_PAYPAL, {MBFaccbId: MBFaccbId, estado: estado, token: token})
            .then(function (response) {
              deferred.resolve(response.data);
            })
            .catch(function (reason) {
              $log.error(reason);
              deferred.reject(reason);
            });
          return deferred.promise;
        },

        goPagamento: function (item, MBFaccbId) {
          if (item === 9) { //'PAYPAL'
            $state.go('loja.pagamento.paypal', {MBFaccbId: MBFaccbId, payKey: '', action: ''});
          }
        },

        setPayed: function (MBFaccbId, motivo) {
          var deferred = $q.defer();
          $http.post(REST_API.HOST + REST_API.PAGAMENTO_SET_PAYED + '/' + MBFaccbId, {descricao: motivo})
            .then(function (response) {
              deferred.resolve(response.data);
            })
            .catch(function (reason) {
              $log.error(reason);
              deferred.reject(reason);
            });
          return deferred.promise;
        },

        condicoesPagamento: function (pesquisa, startoffset, recordcount) {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.PAGAMENTO_CONDICOES_PAGAMENTO, {pesquisa: pesquisa, startoffset: startoffset, recordcount: recordcount})
            .then(function (response) {
              deferred.resolve(response.data);
            })
            .catch(function (reason) {
              $log.error(reason);
              deferred.reject(reason);
            });
          return deferred.promise;
        },

        transportadoras: function (codPaisIso, codPostal) {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.PAGAMENTO_TRANSPORTADORAS + '/' + codPaisIso + '/' + codPostal)
            .then(function (response) {
              deferred.resolve(response.data);
            })
            .catch(function (reason) {
              $log.error(reason);
              deferred.reject(reason);
            });
          return deferred.promise;
        }
      };
    });
}());
