(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('cttService', function ($http, $q, REST_API) {
      var service = {
        getRegionByCodPostal: getRegionByCodPostal
      };
      return service;

      ///////////

      function getRegionByCodPostal(codPostal) {
        return $http.get(REST_API.HOST + REST_API.CTT_CODIGO_POSTAL, {params: {inCodPos: codPostal}});
      }
    });
}());
