(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function ($urlRouterProvider, $httpProvider, $stateProvider, $locationProvider) {
      $httpProvider.interceptors.push('errorHandlerInterceptor');
      $httpProvider.interceptors.push('authInterceptor');
      $httpProvider.interceptors.push('notificationInterceptor');
      $httpProvider.defaults.useXDomain = true;
      delete $httpProvider.defaults.headers.common['X-Requested-With'];

      $locationProvider.html5Mode(false);
      $urlRouterProvider.otherwise('pagina/inicio');
    });
})();
