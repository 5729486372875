(function () {
    'use strict';
  angular.module('erpRetail')
        .run(['WidgetsFactory', function (WidgetsFactory) {
          WidgetsFactory.register('widget-google-maps', 'widget-google-maps-edit', 'GMaps');
        }])

    .directive('widgetGoogleMaps', function () {

            var controller = function ($scope, WidgetsFactory, $sce) {
                $scope.trustSrc = function (url) {
                    return $sce.trustAsResourceUrl(url);
                };

                $scope.refresh = function () {
                  WidgetsFactory.log('widgetGoogleMaps refresh');
                };

            };


            function link(scope, element, attrs, $compile) {
                //scope.$watch('id', function () {
                //    scope.refresh();
                //});

            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/googleMaps/google.maps.html',
                link: link,
                controller: controller
            };

        })

    .directive('widgetGoogleMapsEdit', function () {

            function controller($scope, WidgetsFactory) {
                $scope.refresh = function () {
                  WidgetsFactory.log('widgestGoogleMaps refresh');
                };

                //$scope.getImagem = ImagensFactory.getImagem;

                /*$scope.removeItem = function(item){
                    var index =  $scope.item.data.maps.indexOf(item);
                    if (index===-1) return;
                    $scope.item.data.maps.splice( index, 1 );
                };*/

                /*$scope.addImage = function () {
                    imagePick.pick("", $scope.item.data.image, function (folder, img) {
                        $scope.item.data.image = folder + img;
                    });
                };*/
            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/googleMaps/google.maps.edit.html',
                controller: controller
            };

        });

}());
