(function () {
    'use strict';
  angular.module('erpRetail')
        .run(['WidgetsFactory', function (WidgetsFactory) {
          WidgetsFactory.register('widget-html-image-full', 'widget-html-image-full-edit', 'Image Full Width');
        }])

    .directive('widgetHtmlImageFull', function () {

            function controller($scope, WidgetsFactory, ImagensFactory) {
                $scope.getImagem = ImagensFactory.getImagem;
                $scope.refresh = function () {
                  WidgetsFactory.log('widgetHtmlImageFull refresh');
                };

                $scope.img = [{imgUrl: ''}];
            }

            function link(scope, element, attrs) {
                scope.$watch('item', function () {
                    scope.refresh();
                });
            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/htmlImageFull/html.image.full.html',
                link: link,
                controller: controller
            };

        })
    .directive('widgetHtmlImageFullEdit', function () {

            function controller($scope, WidgetsFactory, imagePick, ImagensFactory) {

                $scope.getImagem = ImagensFactory.getImagem;

                $scope.refresh = function () {
                  WidgetsFactory.log('widgetHtmlImageFullEdit refresh');
                    //WidgetsFactory.log($scope.item);
                };

                $scope.addImage = function (item) {
                  imagePick.pick('', item.data.imgUrl, function (folder, img) {
                        item.data.imgUrl = folder + img;
                    });
                };

            }

            function link(scope, element, attrs, $compile) {
                scope.$watch('item', function () {
                    scope.refresh();
                });

            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/htmlImageFull/html.image.full.edit.html',
                link: link,
                controller: controller
            };

        });
}());
