/* eslint-disable */
(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function () {
      if (!String.prototype.format) {
        String.prototype.format = function () { // jshint ignore:line
          var args = arguments;
          return this.replace(/{(\d+)}/g, function (match, number) {
            return angular.isDefined(args[number]) ? args[number] : match;
          });
        };
      }
    });
})();
