(function () {
  'use strict';

  angular.module('CookieComponent', ['ui.bootstrap', 'ngCookies'])
    .factory('sharedDataServiceCookie', function ($rootScope) {
      var sharedDataServiceCookie = {};
      sharedDataServiceCookie.prepForEmitPolicy = function () {
        $rootScope.$broadcast('handlePolicy');
      };
      return sharedDataServiceCookie;
    })

    .controller('CookieController', function ($scope, $element, $window, $log, $cookies, sharedDataServiceCookie, $uibModal) {
      $scope.init = function () {
        $scope.cookieVar = false;
        $scope.scroll = false;
        $scope.click = false;
        if (!$scope.ngModel)
          $scope.ngModel = 0;
        sharedDataServiceCookie.hasCookie = $scope.cookieVar;
        $scope.output = $scope.ngModel;
      };

      $scope.showCookieConsent = function () {
        if (angular.isUndefined(typeof($cookies.get('cookieConsent')))) {
          $scope.cookieVar = false;
          return true;
        } else {
          $scope.cookieVar = true;
          return false;
        }
      };

      $scope.$on('handlePolicy', function () {
        var size = {};
        //todo filipe model
        var modalInstance = $uibModal.open({
          templateUrl: 'policyContent.html',
          controller: ModalInstancePolicyController,
          size: size,
          resolve: {
            Data: function () {
              return sharedDataServiceCookie.hasCookie;
            }
          }
        });

        modalInstance.result.then(function () {

        }, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
      });

      $scope.getCookie = function () {
        return angular.isDefined(typeof($cookies.get('cookieConsent')));
      };

      $scope.openPolicyPanel = function () {
        sharedDataServiceCookie.prepForEmitPolicy();
      };

      $scope.closePanel = function () {
        $cookies.put('cookieConsent', 'y');
        sharedDataServiceCookie.hasCookie = true;
        $element.hide();
      };

      // enable on scroll if with-scroll attr is true
      angular.element($window).bind('scroll', function () {
        if (!$scope.scroll) {
          $cookies.put('cookieConsent', 'y');
          sharedDataServiceCookie.hasCookie = true;
          $scope.scroll = true;
        }
      });

      // enable on click
      angular.element($window).bind('click', function () {
        if (!$scope.click) {
          $cookies.put('cookieConsent', 'y');
          sharedDataServiceCookie.hasCookie = true;
          $scope.click = true;
        }
      });
    })

    .directive('cookie', /*@ngInject*/ function ($document, $cookies, sharedDataServiceCookie) {
      return {
        require: '^?ngModel',
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: 'app/cookie-bar/cookiebar.html',
        scope: {color: '@', ngModel: '=', moreText: '@', closeText: '@', bodyText: '@', withScroll: '@'},
        controller: 'CookieController',
        link: function (scope, element, attr, ngModel) {
          scope.$watch(function () {
            if (angular.isDefined(typeof($cookies.get('cookieConsent'))))
              sharedDataServiceCookie.hasCookie = true;
            if (sharedDataServiceCookie.hasCookie)
              ngModel.$setViewValue(sharedDataServiceCookie.hasCookie);
          });
        }
      };
    });

  // Please note that $uibModalInstance represents a modal window (instance) dependency.
  // It is not the same as the $modal service used above.
  /*@ngInject*/
  function ModalInstancePolicyController($scope, $uibModalInstance, $cookies, sharedDataServiceCookie) {
    $scope.close = function () {
      $cookies.put('cookieConsent', 'y');
      sharedDataServiceCookie.hasCookie = true;
      $uibModalInstance.dismiss();
    };
  }
})();
