(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('ConfigClassificadoresPropertiesController', function (classificador, save, plAlertService, configFactory) {
      var ctrl = this;

      ctrl.item = {};
      ctrl.properties = [];

      ctrl.$onInit = init;
      ctrl.doSave = doSave;

      /////////////

      function init() {
        ctrl.item = classificador;
        ctrl.properties = [
          {name: 'visible', type: 'boolean', caption: 'admin.classificadores.properties.visible'},
          {name: 'open', type: 'boolean', caption: 'admin.classificadores.properties.open'},
          {name: 'visibleInMenu', type: 'boolean', caption: 'admin.classificadores.properties.visibleInMenu'}
        ];
      }

      function doSave() {
        if (!ctrl.item) {
          plAlertService.error('admin.errors.invalidValue');
          return;
        }
        return save().then(function () {
          ctrl.$close(ctrl.item);
        });
      }
    });
})();
