(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function ($templateRequest, $templateCache) {
      $templateRequest('app/loja/utilizador/encomendas/fatura.html').then(function (template) {
        $templateCache.put('template-encomendas-fatura', template);
      });
    })
    .controller('EncomendasController', /*@ngInject*/ function ($rootScope, $q, $state, $transition$, documentosService, documentoService, Utils, PopAlert, configFactory) {
      var ctrl = this;

      ctrl.params = $transition$.params();
      ctrl.isDocSynced = true;
      ctrl.callback = {};
      ctrl.definition = {};

      ctrl.init = init;
      ctrl.onLoad = onLoad;
      ctrl.onDocSelect = onDocSelect;
      ctrl.onDocDetail = onDocDetail;

      ctrl.init();

      /////////////

      function init() {
        ctrl.definition = {
          order: 'stampInsert desc',
          options: {
            perPage: 10
          },
          fields: [
            {name: 'stampInsert', type: 'timestamp', visible: false, caption: ''},
            {name: 'nDoc', type: 'text', caption: 'ACOOUNT_DOCS_TABLE_TH_NDOC'},
            {name: 'dataDoc', type: 'date', caption: 'ACCOUNT_DOCS_TABLE_TH_DATA'},
            {name: 'totalDocumento', align: 'right', type: 'number', caption: 'ACCOUNT_DOCS_TABLE_TH_TOTAL'},
            {name: 'nomeEstado', caption: 'ADMIN_DOCS_TABLE_TH_STATE'}
          ]
        };

        ctrl.onLoad();
      }

      //// NOVO
      function onLoad(search, order, page, perpage) {
        var deferred = $q.defer();
        documentosService.getEncomendas(search, page, perpage, order)
          .then(function (response) {
            var linhas = response.data.map(function (item) {
              item._$ctrl = ctrl;
              return item;
            });
            deferred.resolve(linhas);
          })
          .catch(function (reason) {
            deferred.reject(reason);
            PopAlert.showError($rootScope.globalTranslate('utilizador.encomendas.fetchError'));
          });
        return deferred.promise;
      }

      function onDocSelect(item) {
        item._detailOpen = !item._detailOpen;
        if (item._detailOpen) {
          item.isDocSynced = item.faccbId !== 0;
          item._activeDoc = item.isDocSynced ? item.faccbId : item.mbfaccbid;
          return ctrl.onDocDetail(item);
        }
      }


      function onDocDetail(item) {
        if (item._thedoc) {
          return;
        }
        var deferred = $q.defer();
        var request = item.isDocSynced ? documentoService.getDocOnline : documentoService.getDoc;

        configFactory.getConfigSite().then(function (configSite) {
          request(item._activeDoc).then(
            function (response) {
              item._thedoc = item.isDocSynced ? response.data.data : response.data;

              for (var i=0;i<configSite.transportadoras.length;i++) {
                    var transportadora=configSite.transportadoras[i];
                if (item._thedoc.cargaInfo && transportadora.trackingUrl && transportadora.codTransportadora===item._thedoc.cargaInfo.codTransportadora) {
                  item._thedoc.cargaInfo.trackUrl =transportadora.trackingUrl.format(item._thedoc.cargaInfo.trackNumber);
                  break;
                }
              }
              deferred.resolve();
            }
          ).catch(function (reason) {
            deferred.reject(reason);
          });
        }).catch(function (reason) {
          deferred.reject(reason);
        });
        return deferred.promise;
      }
    });
})();
