(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('CGModalService', function ($uibModal) {
      var service = {
        showVanilla: showVanilla,
        show: show,
        okCancel: okCancel
      };
      return service;

      ////////////

      function showVanilla(options) {
        var op = _.merge({}, {
          animation: true,
          size: 'lg',
          bindToController: true,
          controllerAs: '$ctrl',
          controller: /*@ngInject*/ function ($uibModalInstance) {
            var ctrl = this;

            ctrl.close = doClose;
            ctrl.cancel = doCancel;

            ////////////

            function doClose(value) {
              $uibModalInstance.close(value);
            }

            function doCancel(value) {
              $uibModalInstance.dismiss(value);
            }
          }
        }, options);
        return $uibModal.open(op);
      }

      function show(options) {
        return service.showVanilla(options).result;
      }

      function okCancel(title, message) {
        return show({
          templateUrl: 'app/components/cgmodal/okcancel.html',
          bindToController: true,
          controllerAs: '$ctrl',
          controller: /*@ngInject*/ function ($uibModalInstance, $timeout) {
            var ctrl = this;

            ctrl.title = title;
            ctrl.message = message;

            ctrl.$onInit = init;

            ////////////

            function init() {
              $timeout(function () {
                angular.element('pl-button#btModelOK button').focus();
              });
            }
          }
        });
      }
    });
})();
