(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('PaginasFactory', ['$q', '$http', 'REST_API', '$log', 'configFactory', 'Utils', function ($q, $http, REST_API, $log, configFactory, Utils) {
      var that = this;

      that.load = function (id) {
        var deferred = $q.defer();
        if (Utils.isNullOrUndefined(id)) {
          deferred.reject('not found');
          return deferred.promise;
        }

        $http.get(REST_API.HOST + REST_API.PAGINA + '/' + id)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err.data);
            deferred.reject(err.data);
          });
        return deferred.promise;
      };

      that.getAll = function () {
        var deferred = $q.defer();

        $http.get(REST_API.HOST + REST_API.PAGINAS_TODAS)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err.data);
            deferred.reject(err.data);
          });
        return deferred.promise;
      };

      that.save = function (pagina) {
        var deferred = $q.defer();

        $http.post(REST_API.HOST + REST_API.PAGINA + '/' + pagina.id, pagina)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err.data);
            deferred.reject(err.data);
          });
        return deferred.promise;
      };

      that.deletePagina = function (pagina) {
        var deferred = $q.defer();

        $http.delete(REST_API.HOST + REST_API.PAGINA + '/' + pagina.id, pagina)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err.data);
            deferred.reject(err.data);
          });
        return deferred.promise;
      };

      return that;
    }]);
}());
