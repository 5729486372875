(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-col3', 'widget-html-col3-edit', 'Html 4xCol-3');
    })

    .directive('widgetHtmlCol3', function () {
      function controller($scope, WidgetsFactory, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgestHtmlCol3 refresh');
        };
        $scope.img = [{imgUrl: ''}];
      }

      function link(scope, element, attrs) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlCol3/html.col3.html',
        link: link,
        controller: controller
      };
    })

    .directive('widgetHtmlCol3Edit', function () {
      function controller($scope, WidgetsFactory, imagePick, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgetHtmlEdit refresh');
          //WidgetsFactory.log($scope.item);
        };

        $scope.addImage1 = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl1 = folder + img;
          });
        };

        $scope.addImage2 = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl2 = folder + img;
          });
        };

        $scope.addImage3 = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl3 = folder + img;
          });
        };

        $scope.addImage4 = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl4 = folder + img;
          });
        };

        $scope.tinymceOptions = {
          resize: false,
          toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | insertfile  link image',
          'file_browser_callback': function (fieldName, url, type, win) {
            win.document.getElementById(fieldName).value = 'my browser value';
          }
        };
      }

      function link(scope, element, attrs, $compile) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlCol3/html.col3.edit.html',
        link: link,
        controller: controller
      };
    });
})();
