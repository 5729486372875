/**
 * Created by Pedro Lopes on 03/10/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('configFactory', function ($http, REST_API, $q, $filter, $log, $rootScope, $state, Utils, CONFIG, $templateCache) {
      var factory = this;
      factory.isLoading = false;
      //factory.config = CONFIG.SITE_CONFIG;
      factory.classificadores = {};

      factory.deferredGetConfig = $q.defer();

      factory.getClassificadorCaption = function (classificador) {
        var aux = factory.getClassificador(classificador);
        if (aux === null) return '';
        return factory.getClassificador(classificador).caption;
      };

      factory.getClassificadorTags = function () {
        var ret = [];
        for (var i = 0; i < factory.classificadoresList.length; i++) {
          if (factory.classificadoresList[i].tipo === 'tags')
            ret.push(factory.classificadoresList[i]);
        }
        return ret;
      };

      factory.getClassificador = function (classificador) {
        for (var i = 0; i < factory.classificadoresList.length; i++) {
          if ((classificador.tipo === factory.classificadoresList[i].tipo) &&
            (classificador.id === factory.classificadoresList[i].id))
            return factory.classificadoresList[i];
        }
        return null;
      };

      factory.getClassificadorByTipoId = function (tipo,id) {
        for (var i = 0; i < factory.classificadoresList.length; i++) {
          if ((tipo === factory.classificadoresList[i].tipo) &&
            (id === factory.classificadoresList[i].id))
            return factory.classificadoresList[i];
        }
        return null;
      };

      factory.saveClassificadores = function (list) {
        var deferred = $q.defer();
        factory.classificadoresList = $filter('orderBy')(factory.classificadoresList, 'position');
        $http.post(REST_API.HOST + REST_API.CONFIG_CLASSIFICADORES, list)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err);
            deferred.reject(err);
          });
        return deferred.promise;
      };

      factory.saveCondicoesPagamento = function (list) {
        var deferred = $q.defer();
        factory.condicoesPagamentoList = $filter('orderBy')(factory.condicoesPagamentoList, 'position');
        for (var i = 0; i < list.length; i++) {
          delete list[i].descricao;
        }
        $http.post(REST_API.HOST + REST_API.CONFIG_CONDICOES_PAGAMENTO, list)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err.data);
            deferred.reject(err.data);
          });
        return deferred.promise;
      };

      factory.saveTransportadoras = function (list) {
        var deferred = $q.defer();
        factory.transportadorasList = $filter('orderBy')(factory.transportadorasList, 'position');
        for (var i = 0; i < list.length; i++) {
          delete list[i].nome;
        }
        $http.post(REST_API.HOST + REST_API.CONFIG_TRANSPORTADORAS, list)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err.data);
            deferred.reject(err.data);
          });
        return deferred.promise;
      };

      factory.getConfigSiteAdmin = function () {
        var deferred = $q.defer();

        $http.get(REST_API.HOST + REST_API.CONFIG_SITE_ADMIN)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err);
            deferred.reject(err);
          });
        return deferred.promise;
      };

      factory.refreshConfig = function () {
        factory.classificadores.grandeFamilia = factory.getClassificador({tipo: 'grandesFamilias', id: 0});
        factory.classificadores.subFamilia = factory.getClassificador({tipo: 'subFamilias', id: 0});
        factory.classificadores.familia = factory.getClassificador({tipo: 'familias', id: 0});
        factory.classificadores.classes = factory.getClassificador({tipo: 'classes', id: 0});
        factory.classificadores.categoria = factory.getClassificador({tipo: 'categorias', id: 0});
        factory.classificadores.tipo = factory.getClassificador({tipo: 'tipos', id: 0});
        factory.classificadores.tags = factory.getClassificadorTags();

        $templateCache.put('produto.box', $rootScope.$config.data.modelos.box);
        $templateCache.put('produto.detalhe', $rootScope.$config.data.modelos.detalhe);
        $templateCache.put('produto.list', $rootScope.$config.data.modelos.list);
        $templateCache.put('produto.promocao', $rootScope.$config.data.modelos.promocao);
        $templateCache.put('main.css', $rootScope.$config.data.modelos.maincss);
        $templateCache.put('menu', $rootScope.$config.data.modelos.header);

        $templateCache.put('classificador.box', $rootScope.$config.data.modelos.classificadorbox);
        $templateCache.put('classificador.detalhe', $rootScope.$config.data.modelos.classificadordetalhe);
        $templateCache.put('classificador.list', $rootScope.$config.data.modelos.classificadorlist);
        $templateCache.put('carrinho', $rootScope.$config.data.modelos.carrinho);
        $templateCache.put('carrinho.table', $rootScope.$config.data.modelos.carrinhoTable);
        $templateCache.put('carrinho.submenu', $rootScope.$config.data.modelos.carrinhoSubmenu);
        $templateCache.put('carrinho.total', $rootScope.$config.data.modelos.carrinhoTotal);
        $templateCache.put('carrinho.step1', $rootScope.$config.data.modelos.carrinhoStep1);
        $templateCache.put('carrinho.step2', $rootScope.$config.data.modelos.carrinhoStep2);
        $templateCache.put('carrinho.step3', $rootScope.$config.data.modelos.carrinhoStep3);
        $templateCache.put('carrinho.step4', $rootScope.$config.data.modelos.carrinhoStep4);
        $templateCache.put('carrinho.step5', $rootScope.$config.data.modelos.carrinhoStep5);
        $templateCache.put('widgets.loja', $rootScope.$config.data.modelos.widgetLoja);
        $templateCache.put('widgets.lojaClassificadores', $rootScope.$config.data.modelos.widgetLojaClassificadores);

        $rootScope.$config.loaded = true;
      };

      factory.getTemplate = function (template) {
        if (!$rootScope.$config.loaded) {
          var deferred = $q.defer();
          factory.getConfigSite().then(function (data) {
            deferred.resolve($templateCache.get(template));
          });
          return deferred.promisse;
        }
        return $templateCache.get(template);
      };

      factory.getConfigSite = function () {
        //$log.info('getConfigSite');
        var deferred = factory.deferredGetConfig;
        if ($rootScope.$config.loaded) {
          deferred.resolve($rootScope.$config.data);
          //$log.info('getConfigSite: ok - already loaded');
          return deferred.promise;
        }
        if (factory.isLoading) {
          return deferred.promise;
        }
        factory.isLoading = true;

        if (CONFIG.USE_DEFAULT) {
          $rootScope.$config.loaded = true;
          $rootScope.$config.data = CONFIG.SITE_CONFIG;
          factory.refreshConfig();
          //$log.info('getConfigSite: ok - Default');
          deferred.resolve($rootScope.$config.data);
          return deferred.promise;
        }

        if ($rootScope.$config.loaded) {
          deferred.resolve($rootScope.$config.data);
        } else {

          $http.get(REST_API.HOST + REST_API.CONFIG_SITE)
            .then(function (data) {
              $rootScope.$config.data = data.data;

              factory.refreshConfig();
              $rootScope.$config.loaded = true;
              //$log.info("config load");
              deferred.resolve(data.data);
              //$log.info('getConfigSite: ok - http loaded');
              factory.isLoading = false;
            }, function (err) {
              //$log.debug(err);
              deferred.reject(err);

              //factory.isLoading = false;
              //$log.info('getConfigSite: error - http');
            });
        }
        return deferred.promise;
      };

      factory.getValue = function (item) {
        var deferred = $q.defer();
        $http.get(REST_API.HOST + REST_API.CONFIG_VALUE + '/' + item).then(
          function (response) {
            deferred.resolve(response);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      };

      factory.saveValue = function (item, callback) {
        var deferred = $q.defer();
        var cb = callback || angular.noop;
        $http.post(REST_API.HOST + REST_API.CONFIG_VALUE, item).then(
          function (response) {
            cb(true, response);
            deferred.resolve(response);
          },
          function (error) {
            cb(false, error);
            deferred.reject(error);
          }
        );
        return deferred.promise;
      };

      factory.getInfo = function (callback) {
        var cb = callback || angular.noop;
        $http.get(REST_API.HOST + REST_API.CONFIG_INFO)
          .then(function (data) {
            cb(true, data.data);
          }, function (err) {
            cb(false, err);
          });
      };

      factory.getMenuBuild = function () {
        var deferred = $q.defer();

        $http.get(REST_API.HOST + REST_API.CONFIG_MENU_BUILD)
          .then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            $log.debug(err);
            deferred.reject(err);
          });
        return deferred.promise;
      };

      factory.refresh = function (reset) {
        if (reset) $rootScope.$config.loaded = false;
        //$log.info("config refresh");
        if (CONFIG.USE_DEFAULT) {
          return CONFIG.SITE_CONFIG;
        } else
          factory.getConfigSite().then(
            function (value) {
              return value;
            }
          );
      };

      //factory.run=function () {
      //   if (factory.loaded) return;
      //   factory.refresh();
      //};
      //
      //
      //
      //factory.run();

      Object.defineProperty(factory, 'classificadoresList', {
        get: function () {
          return $rootScope.$config.data.classificadores;
        },
        set: function (value) {
          $rootScope.$config.data.classificadores = value;
        }
      });

      Object.defineProperty(factory, 'condicoesPagamentoList', {
        get: function () {
          return $rootScope.$config.data.condicoesPagamento;
        },
        set: function (value) {
          $rootScope.$config.data.condicoesPagamento = value;
        }
      });

      Object.defineProperty(factory, 'transportadorasList', {
        get: function () {
          return $rootScope.$config.data.transportadoras;
        },
        set: function (value) {
          $rootScope.$config.data.transportadoras = value;
        }
      });

      return factory;
    });
}());
