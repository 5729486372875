(function () {
  'use strict';

  angular.module('erpRetail')
    .component('reservasEstadiasEdit', {
      bindings: {
        item: '<'
      },
      templateUrl: 'app/components/widgets/reservasestadias/reservasestadias.edit.html',
      controller: /* ngInject */ function (WidgetsFactory) {
        var ctrl = this;

        ctrl.tinymceOptions = {};

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.refresh = refresh;

        /////////////

        function init() {
          ctrl.tinymceOptions = {
            resize: false,
            toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify |' +
            ' bullist numlist outdent indent | insertfile  link image'
          };
        }

        function onChanges(reservasEstadias) {
          if (reservasEstadias && reservasEstadias.item && !reservasEstadias.item.isFirstChange()) {
            ctrl.refresh(reservasEstadias.item.currentValue);
          }
        }

        function refresh(item) {
          WidgetsFactory.log('reservasEstadias refresh');
        }
      }
    });
})();
