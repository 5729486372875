(function () {
  'use strict';

  angular.module('erpRetail')
    .component('widgets', {
      bindings: {
        items: '='
      },
      templateUrl: 'app/components/widgets/widgets.html'
    })
    .component('widgetBuild', {
      bindings: {
        item: '<'
      },
      controller: /*@ngInject*/ function ($compile, $scope, $element, $rootScope, Utils) {
        var ctrl = this;

        ctrl.item = {};

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.doCompile = doCompile;
        ctrl.getDateTime=$rootScope.getDatetime;

        /////////////

        function init() {
          ctrl.item = ctrl.item || {};

          ctrl.doCompile();
        }

        function onChanges(widgetBuild) {
          if (widgetBuild && widgetBuild.item && !widgetBuild.item.isFirstChange()) {
            ctrl.doCompile(widgetBuild.item.currentValue);
          }
        }

        function doCompile(item) {
          item = item || ctrl.item;
          item.isVisible=Utils.isNullOrUndefined(item.publishAfter) || Utils.isNullOrUndefined(item.publishedTo) ||
            (ctrl.getDateTime.getTime() >=  item.publishAfter && ctrl.getDateTime.getTime() <= item.publishedTo);
          var html = '<' + item.name + ' item=\'$ctrl.item\' ></' + item.name + '>';

          // When the 'compile' expression changes assign it into the current DOM
          $element.html(html);

          /* compile the new DOM and link it to the current $scope.
           * NOTE: we only compile .childNodes so that
           * we don't get into infinite loop compiling ourselves */
          $compile($element.contents())($scope);
        }
      }
    });
})();
