(function () {
  'use strict';

  angular.module('erpRetail')
    .config(/*@ngInject*/ function ($stateProvider) {
      $stateProvider
        .state('loja', {
          url: '?token',
          title: 'loja.states.loja',
          abstract: true,
          views: {
            '': {
              templateUrl: 'app/loja/index.html',
              controller: 'HomeController',
              controllerAs: 'ctrl'
            },
            'header': {
              templateUrl: 'app/loja/header.html',
              controller: 'HomeController',
              controllerAs: 'ctrl'
              //},
              //"footer": {
              //    template: $rootScope.config.modelo.footer, //"app/loja/footer.html",
              //    controller: "HomeController"
            }
          },
          resolve: {
            config: ['configFactory', function (configFactory) {
              return configFactory.getConfigSite();
            }]
          },
          params: {
            token: {
              type: 'string',
              value: null,
              squash: true
            }
          }
        })
        .state('loja.home', {
          url: '/pagina/:id',
          title: 'loja.states.home',
          templateUrl: 'app/loja/home/homedyn.html',
          resolve: {
            config: ['configFactory', function (configFactory) {
              return configFactory.getConfigSite();
            }],
            pagina: function (PaginasFactory, $transition$, config, MenuService, $state, Utils) {
              function gogo(cod, page, title) {
                var stateParamsClone = {};
                if ($transition$.params().id === page) {
                  stateParamsClone.id = Utils.toNumber(cod);
                  $state.go($state.current, stateParamsClone);
                }
                return $transition$.params().id <= 0;
              }

              if (gogo(config.pages.home, 'inicio', 'Home')) return;
              if (gogo(config.pages.termos, 'termos', 'Termos')) return;
              if (gogo(config.pages.contactos, 'contactos', 'Contactos')) return;

              var t = PaginasFactory.load($transition$.params().id);
              t.then(
                function (obj) {
                  $state.current.title = obj.data.title;
                  if (!Utils.isNullOrUndefined(obj.data.name)) {
                    $transition$.params().id = obj.data.name;
                  }
                },
                function () {
                  // página não encontrada
                  $state.go('e404');
                }
              );
              return t;
            }
          },
          controller: 'PaginaShowController',
          controllerAs: 'ctrl'
        })
        .state('loja.contatos', {
          url: '/contactos',
          title: 'loja.states.contactos',
          templateUrl: 'app/loja/contatos/contatos.html',
          controller: 'ContatosController',
          controllerAs: 'ctrl'
        })
        .state('loja.carrinho', {
          url: '/carrinho',
          title: 'loja.states.carrinho',
          component: 'carrinhoComponent'
        });
    })
    .run(/*@ngInject*/ function ($transitions, $log, Auth, configFactory) {
      $transitions.onStart({}, function ($transition) {
        Auth.isLoggedInAsync(function () {
          handleRedirect(configFactory, $transition);
        });
      });
    });

  function handleRedirect(configFactory, transition) {
    var toState = transition.$to();
    var params = transition.params();
    if (toState.self.name !== 'loja.home') {
      return;
    }

    configFactory.getConfigSite().then(
      function (response) {
        var statesToRedirect = [
          'inicio',
          'termos'
        ];

        for (var i = 0; i < statesToRedirect.length; i++) {
          var state = statesToRedirect[i];
          if (state.indexOf(params.id) !== -1) {
            var page = '';

            switch (state) {
              case 'inicio':
                page = 'home';
                break;
              case 'termos':
                page = 'termos';
                break;
              default:
                return;
            }

            page = response.pages[page];
            if (page) {
              transition.router.stateService.go('loja.home', {id: page});
            }
            break;
          }
        }
      }
    );
  }
})();
