(function () {
    'use strict';

    angular.module('erpRetail')
      .controller('UtilizadorNovaPalavraPasseController',
            ['$scope', 'Auth', '$filter', '$state', 'PopAlert',
                function ($scope, Auth, $filter, $state, PopAlert) {
                  $scope.token = '';
                  $scope.password = '';

                    $scope.ok = function () {
                      return (!($scope.token === null || angular.isUndefined($scope.token) || $scope.token === ''));
                    };

                    $scope.novaPassword = function (form) {
                        if (!form.$valid) {
                            PopAlert.showError($filter('translate')('FORM_INVALID'));
                            return;
                        }
                        Auth.novaPassword($scope.token, $scope.password).then(function () {
                            PopAlert.showSuccess($filter('translate')('LOGIN_PASSWORD_CHANGED'));
                          $state.go('login');
                        }, function () {
                            PopAlert.showError($filter('translate')('LOGIN_PASSWORD_ERROR'));
                        });
                    };

                    $scope.passwordValidator = Auth.passwordValidator;

                    $scope.run = function run() {
                        $scope.token = Auth.getRecover();
                    };

                    $scope.run();

                }]);
}());
