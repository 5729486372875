(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('DashboardCtrl', ['$scope', '$timeout',
      function ($scope, $timeout) {
        $scope.gridsterOptions = {
          margins: [10, 10],
          columns: 12,
          draggable: {
            handle: 'h4'
          }
        };

        $scope.dashboards = {
          '1': {
            id: '1',
            name: 'Home',
            widgets: [{
              col: 0,
              row: 0,
              sizeY: 1,
              sizeX: 1,
              name: 'Widget 1'
            }, {
              col: 2,
              row: 1,
              sizeY: 1,
              sizeX: 1,
              name: 'Widget 2'
            }]
          },
          '2': {
            id: '2',
            name: 'Other',
            widgets: [{
              col: 1,
              row: 1,
              sizeY: 1,
              sizeX: 2,
              name: 'Other Widget 1'
            }, {
              col: 1,
              row: 3,
              sizeY: 1,
              sizeX: 1,
              name: 'Other Widget 2'
            }]
          }
        };

        $scope.clear = function () {
          $scope.dashboard.widgets = [];
        };

        $scope.addWidget = function () {
          $scope.dashboard.widgets.push({
            name: 'New Widget',
            sizeX: 1,
            sizeY: 1
          });
        };

        $scope.$watch('selectedDashboardId', function (newVal, oldVal) {
          if (newVal !== oldVal) {
            $scope.dashboard = $scope.dashboards[newVal];
          } else {
            $scope.dashboard = $scope.dashboards[1];
          }
        });

        // init dashboard
        $scope.selectedDashboardId = '1';

      }
    ])

    .controller('CustomWidgetCtrl', ['$scope', '$uibModal',
      function ($scope, $uibModal) {

        $scope.remove = function (widget) {
          $scope.dashboard.widgets.splice($scope.dashboard.widgets.indexOf(widget), 1);
        };

        $scope.openSettings = function (widget) {
          $uibModal.open({
            scope: $scope,
            templateUrl: 'components/directives/grid/grid.layout.settings.html',
            controller: 'WidgetSettingsCtrl',
            resolve: {
              widget: function () {
                return widget;
              }
            }
          });
        };

      }
    ])

    .controller('WidgetSettingsCtrl', ['$scope', '$timeout', '$rootScope', '$uibModalInstance', 'widget',
      function ($scope, $timeout, $rootScope, $uibModalInstance, widget) {
        $scope.widget = widget;

        $scope.form = {
          name: widget.name,
          sizeX: widget.sizeX,
          sizeY: widget.sizeY,
          col: widget.col,
          row: widget.row
        };

        $scope.sizeOptions = [{
          id: '1',
          name: '1'
        }, {
          id: '2',
          name: '2'
        }, {
          id: '3',
          name: '3'
        }, {
          id: '4',
          name: '4'
        }];

        $scope.dismiss = function () {
          $uibModalInstance.dismiss();
        };

        $scope.remove = function () {
          $scope.dashboard.widgets.splice($scope.dashboard.widgets.indexOf(widget), 1);
          $uibModalInstance.close();
        };

        $scope.submit = function () {
          angular.extend(widget, $scope.form);

          $uibModalInstance.close(widget);
        };

      }
    ])


    // helper code
    .filter('object2Array', function () {
      return function (input) {
        var out = [];
        for (var i in input) {
          if (Object.prototype.hasOwnProperty.call(input, i)) {
            out.push(input[i]);
          }
        }
        return out;
      };
    });
}());
