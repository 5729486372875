(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('widgetEditController', function ($uibModalInstance, WidgetsFactory, item, Utils, $rootScope) {
      var ctrl = this;
      ctrl.scope=$rootScope;
      ctrl.scope.widgetChanged=ctrl.scope.widgetChanged|| 1;

      ctrl.item = {};
      ctrl.itemCopy = {};
      if (Utils.isNullOrUndefined(item.publishAfter)) item.publishAfter=new Date();
      ctrl.dateFrom = item.publishAfter;
      ctrl.timeFrom = item.publishAfter;

      if (Utils.isNullOrUndefined(item.publishedTo)) item.publishedTo=new Date('1/1/2050 12:00:00 PM');
      ctrl.dateTo = item.publishedTo;
      ctrl.timeTo = item.publishedTo;


      ctrl.$onInit = init;
      ctrl.editDirective = editDirective;
      ctrl.save = save;
      ctrl.cancel = cancel;

      /////////////

      function init() {
        ctrl.item = item;
        ctrl.itemCopy = angular.copy(item);
      }

      function editDirective() {
        var name = WidgetsFactory.getEdit(item.name);
        return '<' + name + ' item=\'$ctrl.item\'></' + name + '>';
      }

      function save() {
        ctrl.item.publishAfter = Utils.combineDateTime(new Date(ctrl.dateFrom), new Date(ctrl.timeFrom)).getTime();
        ctrl.item.publishedTo = Utils.combineDateTime(new Date(ctrl.dateTo), new Date(ctrl.timeTo)).getTime();
        ctrl.scope.widgetChanged=ctrl.scope.widgetChanged+1;
        $uibModalInstance.close(ctrl.item);
      }

      function cancel() {
        $uibModalInstance.dismiss(ctrl.itemCopy);
      }
    });
})();
