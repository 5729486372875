(function () {
  'use strict';
  angular.module('erpRetail')
    .run(['WidgetsFactory', function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-saft-funcs', 'widget-html-saft-funcs-edit', 'Html Saft Funcs');
    }])

    .directive('widgetHtmlSaftFuncs', ['WidgetsFactory', function (WidgetsFactory) {

      function controller($scope, WidgetsFactory) {
        $scope.refresh = function () {
          WidgetsFactory.log('widgetHtmlSaftFuncs refresh');
        };
      }

      function link(scope, element, attrs) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlSaftFuncs/html.saft.funcionalidades.html',
        link: link,
        controller: controller
      };

    }])
    .directive('widgetHtmlSaftFuncsEdit', ['WidgetsFactory', function (WidgetsFactory) {
      function controller($scope, WidgetsFactory) {
        $scope.refresh = function () {
          //WidgetsFactory.log("widgetHtmlEdit refresh");
          //WidgetsFactory.log($scope.item);
        };

        $scope.tinymceOptions = {
          resize: false,
          toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | insertfile  link image',
          'file_browser_callback': function (fieldName, url, type, win) {
            win.document.getElementById(fieldName).value = 'my browser value';
          }

        };
      }

      function link(scope, element, attrs, $compile) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlFull/html.saft.funcionalidades.edit.html',
        link: link,
        controller: controller
      };
    }]);
}());
