(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('FileListController', ['$scope', 'ImagensFactory', '$filter', 'Utils', '$uibModal', 'PopAlert',
      function ($scope, ImagensFactory, $filter, Utils, $uibModal, PopAlert) {
        $scope.itemsPerPage = 12;
        $scope.currentPage = 1;

        $scope.grid = {
          list: [],
          config: {
            itemsPerPage: 10,
            maxPages: 10,
            fillLastPage: true,
            orderBy: 'AIOrderBy'
          }
        };
        $scope.searchimages = '';
        $scope.folder = '';

        $scope.filtered = [];

        $scope.getImagem = function (item) {
          return ImagensFactory.getUrlImagem($scope.folder, item.name);
        };

        $scope.deleteImagem = function (item) {
          ImagensFactory.deleteImagem($scope.folder, item.name).then(function (ret) {
            var idx = $scope.filtered.indexOf(item);
            if (idx > -1) $scope.filtered.splice(idx, 1);
            PopAlert.showSuccess($filter('translate')('IMAGE_DELETED', {name: item.name}));
          }, function (ret) {
            PopAlert.showError($filter('translate')('IMAGE_ERRO_DELETE'));
          });
        };

        $scope.showImagem = function (item) {
          var modalInstance = $uibModal.open({
            templateUrl: 'app/admin/imagens/imagens.show.html',
            controller: 'FileShowController',
            resolve: {
              item: function () {
                return item;
              },
              folder: function () {
                return $scope.folder;
              }
            }
          });

          modalInstance.result.then(
            function (data) {
              if (data === 'delete') {
                $scope.deleteImagem(item);
              }
              //PopAlert.showSuccess(data);
            },
            function (data) {
              //PopAlert.showSuccess(data);
            });


        };

        $scope.showRename = function (item) {
          var modalInstance = $uibModal.open({
            templateUrl: 'app/admin/imagens/imagens.rename.html',
            controller: 'FileRenameController',
            resolve: {
              item: function () {
                return item;
              },
              folder: function () {
                return $scope.folder;
              }
            }
          });

          modalInstance.result.then(function (name) {
            ImagensFactory.renameImagem($scope.folder, item.name, name).then(
              function () {
                item.name = name;
                PopAlert.showSuccess($filter('translate')('IMAGE_RENAMED', {from: item.name, to: name}));
              },
              function () {
                PopAlert.showError($filter('translate')('IMAGE_ERRO_RENAME'));
              });
          });

        };

        $scope.refresh = function () {
          ImagensFactory.getList($scope.folder)
            .then(function (list) {
              $scope.grid.list = list;
              $scope.updateFilter();
            }, function (err) {

            });
        };

        $scope.updateFilter = function () {
          if (Utils.isNullOrEmpty($scope.searchimages)) {
            $scope.filtered = $scope.grid.list;
          } else {
            $scope.filtered = $filter('filter')($scope.grid.list, $scope.searchimages);
          }
        };

        $scope.refresh();

      }])


    .controller('FileShowController', ['$scope', 'ImagensFactory', 'folder', 'item', '$uibModalInstance', function ($scope, ImagensFactory, folder, item, $uibModalInstance) {
      $scope.folder = folder;
      $scope.item = item;
      $scope.getImagem = function () {
        return ImagensFactory.getUrlImagem(folder, item.name);
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };


      $scope.delete = function () {
        $uibModalInstance.close('delete');
      };

    }])


    .controller('FileRenameController', ['$scope', 'ImagensFactory', 'folder', 'item', '$uibModalInstance', function ($scope, ImagensFactory, folder, item, $uibModalInstance) {
      $scope.folder = folder;
      $scope.name = item.name;
      $scope.getImagem = function () {
        return ImagensFactory.getUrlImagem(folder, item.name);
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.edit = function () {
        $uibModalInstance.close($scope.name);
      };

    }])

    .controller('FileUploadController', ['$scope', 'FileUploader', 'REST_API', 'Auth',
      function ($scope, FileUploader, REST_API, Auth) {
        var uploader = $scope.uploader = new FileUploader({
          url: REST_API.HOST + REST_API.IMAGE_UPLOAD,
          headers: {
            authToken: Auth.getToken()
          }
        });

        // FILTERS

        uploader.filters.push({
          name: 'imageFilter',
          fn: function (item /*{File|FileLikeObject}*/, options) {
            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
          }
        });

        //// CALLBACKS
        //
        //uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
        //    console.info('onWhenAddingFileFailed', item, filter, options);
        //};
        //uploader.onAfterAddingFile = function(fileItem) {
        //    console.info('onAfterAddingFile', fileItem);
        //};
        //uploader.onAfterAddingAll = function(addedFileItems) {
        //    console.info('onAfterAddingAll', addedFileItems);
        //};
        //uploader.onBeforeUploadItem = function(item) {
        //    console.info('onBeforeUploadItem', item);
        //};
        //uploader.onProgressItem = function(fileItem, progress) {
        //    console.info('onProgressItem', fileItem, progress);
        //};
        //uploader.onProgressAll = function(progress) {
        //    console.info('onProgressAll', progress);
        //};
        //uploader.onSuccessItem = function(fileItem, response, status, headers) {
        //    console.info('onSuccessItem', fileItem, response, status, headers);
        //};
        //uploader.onErrorItem = function(fileItem, response, status, headers) {
        //    console.info('onErrorItem', fileItem, response, status, headers);
        //};
        //uploader.onCancelItem = function(fileItem, response, status, headers) {
        //    console.info('onCancelItem', fileItem, response, status, headers);
        //};
        //uploader.onCompleteItem = function(fileItem, response, status, headers) {
        //    console.info('onCompleteItem', fileItem, response, status, headers);
        //};
        //uploader.onCompleteAll = function() {
        //    console.info('onCompleteAll');
        //};
        //
      }]);
}());
