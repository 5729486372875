(function () {
  'use strict';
  angular.module('erpRetail')
    .run(['WidgetsFactory', function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-collapse', 'widget-html-collapse-edit', 'Html collapse');
    }])
    .directive('widgetHtmlCollapse', function () {

      function controller($scope, WidgetsFactory, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.isCollapsed = false;
        $scope.refresh = function () {
          WidgetsFactory.log('widgestHtmlCollapse refresh');
        };

        $scope.img = [{imgUrl: ''}];
      }

      function link(scope, element, attrs) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlCollapse/html.collapse.html',
        link: link,
        controller: controller
      };

    })
    .directive('widgetHtmlCollapseEdit', function () {

      function controller($scope, WidgetsFactory, imagePick, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.isCollapsed = false;
        $scope.refresh = function () {
          WidgetsFactory.log('widgetHtmlCollapseEdit refresh');
          //WidgetsFactory.log($scope.item);
        };

        $scope.addImage = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl = folder + img;
          });
        };
      }

      function link(scope, element, attrs, $compile) {
        scope.$watch('item', function () {
          scope.refresh();
        });

      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlCollapse/html.collapse.edit.html',
        link: link,
        controller: controller
      };
    });
}());
