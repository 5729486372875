(function () {
  'use strict';

  angular.module('erpRetail')
    .value('uiTinymceConfig', {
      resize: false,
      theme: 'modern',
      plugins: 'advlist autolink lists link image anchor visualblocks code table contextmenu paste',
      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | insertfile  link image',
      'file_browser_callback': function (fieldName, url, type, win) {
        win.document.getElementById(fieldName).value = 'my browser value';
      }
    })
    .config(function () {
      tinyMCE.baseURL = '/node_modules/tinymce';
    })
    .config(compileServiceConfig);

  function /*@ngInject*/ compileServiceConfig($compileProvider, ENV, $logProvider) {
    // disable debug data on prod profile to improve performance
    $compileProvider.debugInfoEnabled(ENV === 'dev');

    /*
     If you wish to debug an application with this information
     then you should open up a debug console in the browser
     then call this method directly in this console:

     angular.reloadWithDebugInfo();
     */
    $logProvider.debugEnabled(ENV === 'dev');
  }
})();
