(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('timePickerModal', function ($uibModal, Utils) {
      return {
        timePick: timePick
      };

      /////////////

      function timePick(item, callback) {
        var modalInstance = $uibModal.open({
          templateUrl: 'app/components/timePickerModal/time.picker.modal.html',
          controllerAs: '$ctrl',
          controller: 'timePickerModalController',
          modalFade: true,
          backdrop: true,
          size: 'lg',
          resolve: {
            item: function () {
              return {
                item: item
              };
            }
          }
        });
        if (!Utils.isNullOrUndefined(callback)) {
          modalInstance.result.then(function (selectedItem) {
            callback(selectedItem);
          });
        }
        return modalInstance.result;
      }
    });
})();
