(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('UtilizadoresEditController', function ($rootScope, $filter, $transition$, $q, item, Auth, User, PopAlert, clienteService, emailService,
                                                        CGModalService, plAlertService) {
      var ctrl = this;

      ctrl.tiny = {test: ''};
      ctrl.form = {};
      ctrl.emailValidator = item ? emailService.validatorEmailExists(item.email) : emailService.validatorEmailExists();
      ctrl.type = $transition$.params().type;
      ctrl.isEdit = $transition$.params().isEdit === true;
      ctrl.mailSent = false;
      ctrl.backupData = null;

      ctrl.init = init;
      ctrl.save = save;
      ctrl.edit = edit;
      ctrl.cancel = cancel;
      ctrl.getNClifos = getNClifos;
      ctrl.getNClifo = getNClifo;
      ctrl.requestChangePassword = requestChangePassword;
      ctrl.changePassword = changePassword;

      ctrl.init();

      /////////////

      function init() {
        ctrl.item = item ? angular.copy(item) : {};
        ctrl.editing = ctrl.type === 'new';
        ctrl.backupData = angular.copy(ctrl.item);
      }

      function save() {
        ctrl.form.$setSubmitted();
        if (!ctrl.form.$valid) {
          return;
        }

        var newUser = {};
        if (!ctrl.isEdit) {
          newUser = angular.copy(ctrl.item);
          newUser.baseUrl = Auth.baseUrl();
          newUser.byAdmin = true;
          newUser.nconta = newUser.erp.nconta;
          newUser.erp = undefined;
        }
        var request = item && item.id ? User.saveUser(item.id, ctrl.item) : User.createUser(newUser);
        request.then(
          function (response) {
            ctrl.editing = false;
            PopAlert.showSuccess($rootScope.globalTranslate('utilizador.edit.saveUserSuccess'));
            if (response.id) {
              $rootScope.globalGoTo('admin.utilizadores.edit', {id: response.id});
            }
          },
          function () {
            PopAlert.showError($rootScope.globalTranslate('utilizador.edit.saveUserError'));
          }
        );
      }

      function getNClifos(search, order, page, perpage) {
        return clienteService.query(search, order, page, perpage);
      }

      function getNClifo(search) {
        var deferred = $q.defer();
        clienteService.query(search, '', 1, 1).then(function (response) {
          if (response.list && response.list.length) {
            deferred.resolve(response.list[0]);
          }
          else {
            deferred.reject();
          }
        });
        return deferred.promise;
      }

      function edit() {
        ctrl.editing = !ctrl.editing;
      }

      function cancel() {
        ctrl.edit();
        ctrl.item = angular.copy(ctrl.backupData);
        if (ctrl.form) {
          ctrl.form.email.$setValidity('uniqueEmail', true);
          ctrl.form.$setPristine();
        }
      }

      function requestChangePassword() {
        ctrl.mailSent = true;
        Auth.recuperar(ctrl.item.email).then(
          function () {
            PopAlert.showSuccess($rootScope.globalTranslate('utilizador.edit.resendPwEmailSuccess'));
          },
          function () {
            PopAlert.showError($rootScope.globalTranslate('utilizador.edit.resendPwEmailError'));
          }
        )['finally'](function () {
          ctrl.mailSent = false;
        });
      }

      function changePassword() {
        var deferred = $q.defer();
        CGModalService.show({
          templateUrl: 'app/admin/utilizadores/utilizadores.edit.password.html',
          bindToController: true,
          controllerAs: 'ctrl',
          controller: /*@ngInject*/ function ($uibModalInstance) {
            var ctrl = this;

            ctrl.definition = {};
            ctrl.template = {};
            ctrl.form = {};
            ctrl.model = {};

            ctrl.$onInit = doInit;
            ctrl.submit = submit;
            ctrl.doCancel = doCancel;

            /////////////

            function doInit() {
              ctrl.definition = {
                fields: [
                  {
                    name: 'password', type: 'password', formName: 'formPassword', caption: 'LOGIN_PLACEHOLDER_PASS',
                    properties: {placeholder: 'LOGIN_PLACEHOLDER_PASS', required: true}, validators: {minlength: 5}
                  },
                  {
                    name: 'confirm', type: 'password', caption: 'LOGIN_SIGNUP_PLACEHOLDER_REPEAT_PASS',
                    properties: {placeholder: 'LOGIN_SIGNUP_PLACEHOLDER_REPEAT_PASS', required: true},
                    validators: {
                      minlength: 5, equals: {value: 'formPassword', message: 'utilizador.mensagens.campoDeveSerIgual'}
                    }
                  }
                ]
              };

              ctrl.template = {
                items: [
                  {type: 'field', field: 'password'},
                  {type: 'field', field: 'confirm'}
                ]
              };
            }

            function submit() {
              ctrl.form.$setSubmitted();
              if (ctrl.form.$invalid) {
                return;
              }
              $uibModalInstance.close(ctrl.model);
            }

            function doCancel() {
              $uibModalInstance.dismiss('cancel');
            }
          }
        }).then(
          function (response) {
            if (response.password) {
              User.mudarPassword(item.id, response.password).then(
                function (response) {
                  deferred.resolve(response);
                  plAlertService.success('utilizador.edit.changePwSuccess');
                },
                function (error) {
                  deferred.reject(error);
                }
              );
            }
            else {
              deferred.reject();
            }
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      }
    });
}());
