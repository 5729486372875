(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('SignupController', /*@ngInject*/ function ($scope, Auth, $location, $window, $log, $state, PopAlert) {
      $scope.user = {};
      $scope.errors = {};

      $scope.register = function (form) {
        if (!form.$valid) {
          return;
        }

        var newUser = {
          name: $scope.user.name,
          email: $scope.user.email,
          password: $scope.user.password,
          baseUrl: Auth.baseUrl()
        };

        Auth.createUser(newUser).then(
          function (status) {
            switch (status.state) {
              case 'USER_EXISTS_UNVERIFIED':
                $state.go('user.confirm_email');
                break;
              case 'USER_CREATED_UNVERIFIED':
                $state.go('user.confirm_email');
                break;
              case 'USER_EXISTS':
                PopAlert.showError('account.signup.userExists', {'email': $scope.user.email});
                break;
              default:
                $state.go('e404');
                break;
            }
          },
          function (error) {
            PopAlert.showError(error);
          }
        );
      };

      $scope.passwordValidator = function (password) {
        if (!password) {
          return;
        }

        if (password.length < 5) {
          return 'LOGIN_ERROR_PASS_VALIDATION';
        }

        return true;
      };
    });
})();
