(function () {
  'use strict';

  /**
   * Created by pedro on 13/04/2017.
   */
  angular.module('erpRetail')
    .filter('textoArtigo', function (Utils) {
      return function (text) {
        return Utils.isNullOrEmpty(text) ? '' : text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      };
    });
})();
