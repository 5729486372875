(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('ConfigController', function (configFactory, $log, $filter, $q, $http, emailService, Utils, imagePick, $state, $transition$, PopAlert,
                                              CGModalService, PagamentosFactory, TransportadorasFactory) {
      var ctrl = this;

      ctrl.classificadoresList = configFactory.classificadoresList;
      ctrl.condicoesPagamentoList = configFactory.condicoesPagamentoList;
      ctrl.transportadoraList = configFactory.transportadorasList;

      ctrl.myFilter = '';
      ctrl.config = {
        itemsPerPage: 20,
        maxPages: 15,
        fillLastPage: true
      };
      ctrl.info = [];
      ctrl.play = [];
      ctrl.playFiltered = [];
      ctrl.version = {};
      ctrl.configSaved = true;
      ctrl.erpSyncMethod = 'ERP';

      ctrl.tabs = [
        {name: 'estado', header: 'admin.config.controller.controllerTabs.estado', src: 'app/admin/config/config.estado.html'},
        {name: 'site', header: 'admin.config.controller.controllerTabs.site', src: 'app/admin/config/config.site.html'},
        {name: 'erp', header: 'admin.config.controller.controllerTabs.erp', src: 'app/admin/config/config.erp.html'},
        {name: 'social', header: 'admin.config.controller.controllerTabs.social', src: 'app/admin/config/config.social.html'},
        {name: 'email', header: 'admin.config.controller.controllerTabs.email', src: 'app/admin/config/config.email.html'},
        {name: 'classificadores', header: 'admin.config.controller.controllerTabs.classificadores', src: 'app/admin/config/config.classificadores.html'},
        {name: 'condicoespagamento', header: 'admin.config.controller.controllerTabs.condsPagamento', src: 'app/admin/config/config.condicoespagamento.html'},
        {name: 'transportadoras', header: 'admin.config.controller.controllerTabs.transportadoras', src: 'app/admin/config/config.transportadoras.html'},
        {name: 'variaveis', header: 'admin.config.controller.controllerTabs.variaveis', src: 'app/admin/config/config.variaveis.html'},
        {name: 'play', header: 'admin.config.controller.controllerTabs.variaveisServidor', src: 'app/admin/config/config.playvars.html'}
      ];

      ctrl.options = {
        dropped: function () {
          for (var i = 0; i < ctrl.classificadoresList.length; i++) {
            ctrl.classificadoresList[i].position = i;
          }
          configFactory.saveClassificadores(ctrl.classificadoresList).then(
            function () {
              PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
            },
            function () {
              PopAlert.showError('admin.config.controller.mensagens.errorSaved');
            }
          );
        }
      };

      ctrl.optionsCondicoesPagamento = {
        dropped: function () {
          for (var i = 0; i < ctrl.condicoesPagamentoList.length; i++) {
            ctrl.condicoesPagamentoList[i].position = i;
          }
          configFactory.saveCondicoesPagamento(ctrl.condicoesPagamentoList).then(
            function () {
              ctrl.fillDescricaoCondicaoPagamento();
              PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
            },
            function () {
              PopAlert.showError('admin.config.controller.mensagens.errorSaved');
            }
          );
        }
      };

      ctrl.optionsTransportadoras = {
        dropped: function () {
          for (var i = 0; i < ctrl.transportadoraList.length; i++) {
            ctrl.transportadoraList[i].position = i;
          }
          configFactory.saveTransportadoras(ctrl.transportadoraList).then(
            function () {
              ctrl.fillDescricaoTransportadoras();
              PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
            },
            function () {
              PopAlert.showError('admin.config.controller.mensagens.errorSaved');
            }
          );
        }
      };

      ctrl.updateERPTab = function () {
        if (ctrl.config.UseRestServer === 1) {
          ctrl.erpSyncMethod = 'ERPRest';
        } else {
          ctrl.erpSyncMethod = 'ERP';
        }
      };

      ctrl.saveErp = function () {
        ctrl.configSaved = true;
        switch (ctrl.erpSyncMethod) {
          case 'ERP':
            ctrl.config.UseRestServer = 0;
            break;
          case 'ERPRest':
            ctrl.config.UseRestServer = 1;
            break;
        }
        configFactory.saveValue({configID: 'ServerName', value: ctrl.config.siteServerName}, function (ok) {
          if (!ok) {
            ctrl.configSaved = false;
          }
        });
        configFactory.saveValue({configID: 'ServerPort', value: ctrl.config.siteServerPort}, function (ok) {
          if (!ok) {
            ctrl.configSaved = false;
          }
        });
        configFactory.saveValue({configID: 'RestERPv1User', value: ctrl.config.siteERPServerURL}, function (ok) {
          if (!ok) {
            ctrl.configSaved = false;
          }
        });
        configFactory.saveValue({configID: 'centralgest.api.ErpRestToken', value: ctrl.config.ErpRestToken}, function (ok) {
          if (!ok) {
            ctrl.configSaved = false;
          }
        });
        configFactory.saveValue({configID: 'sync.userest', value: ctrl.config.UseRestServer}, function (ok) {
          if (!ok) {
            ctrl.configSaved = false;
          }
        });

        if (ctrl.configSaved) {
          PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
          ctrl.refresh();
        } else {
          PopAlert.showError('admin.config.controller.mensagens.errorSaved');
        }
      };

      ctrl.getImage = function () {
        imagePick.pick('', ctrl.config.siteLogo, function (folder, img) {
          ctrl.config.siteLogo = folder + img;
          configFactory.saveValue({configID: 'site.logo.url', value: ctrl.config.siteLogo}, function (ok) {
            if (ok) {
              PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
              configFactory.refresh(true);
              return '';
            }
          });
        });
      };

      ctrl.getImageLogin = function () {
        imagePick.pick('', ctrl.config.loginLogo, function (folder, img) {
          ctrl.config.loginLogo = folder + img;
          configFactory.saveValue({configID: 'site.logo.login', value: ctrl.config.loginLogo}, function (ok) {
            if (ok) {
              PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
              configFactory.refresh(true);
              return '';
            }
          });
        });
      };

      ctrl.getFavicon = function () {
        imagePick.pick('', ctrl.config.sitefavicon, function (folder, img) {
          ctrl.config.sitefavicon = folder + img;
          configFactory.saveValue({configID: 'site.favicon', value: ctrl.config.sitefavicon}, function (ok) {
            if (ok) {
              PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
              configFactory.refresh(true);
              return '';
            }
          });
        });
      };

      ctrl.save = function () {
        var deferred = $q.defer();
        configFactory.saveClassificadores(ctrl.classificadoresList).then(
          function (response) {
            PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
            deferred.resolve(response);
          },
          function (error) {
            PopAlert.showError('admin.config.controller.mensagens.errorSaved');
            deferred.reject(error);
          }
        );
        return deferred.promise;
      };

      ctrl.saveCondicoesPagamento = function () {
        var deferred = $q.defer();
        configFactory.saveCondicoesPagamento(ctrl.condicoesPagamentoList).then(
          function (response) {
            ctrl.fillDescricaoCondicaoPagamento();
            PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
            deferred.resolve(response);
          },
          function (error) {
            PopAlert.showError(error);
            deferred.reject(error);
          }
        );
        return deferred.promise;
      };

      ctrl.saveTransportadora = function () {
        var deferred = $q.defer();
        configFactory.saveTransportadoras(ctrl.transportadoraList).then(
          function (response) {
            ctrl.fillDescricaoTransportadoras();
            PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
            deferred.resolve(response);
          },
          function (error) {
            PopAlert.showError(error);
            deferred.reject(error);
          }
        );
        return deferred.promise;
      };

      ctrl.reloadCondicoesPagamento = function () {
        var deferred = $q.defer();
        PagamentosFactory.condicoesPagamento('', -1, -1).then(
          function (pagamList) {
            ctrl.pagamList = pagamList;
            deferred.resolve();
          },
          function (err) {
            deferred.reject(err);
          }
        );
        return deferred.promise;
      };

      ctrl.reloadTransportadora = function () {
        var deferred = $q.defer();
        TransportadorasFactory.all().then(
          function (data) {
            ctrl.gcTransportadora = data;
            deferred.resolve();
          },
          function (err) {
            deferred.reject(err);
          }
        );
        return deferred.promise;
      };

      ctrl.fillDescricaoCondicaoPagamento = function () {
        for (var i = 0; i < ctrl.condicoesPagamentoList.length; i++) {
          var item = ctrl.condicoesPagamentoList[i];
          for (var j = 0; j < ctrl.pagamList.length; j++) {
            var pagam = ctrl.pagamList[j];
            if (pagam.ncondpag === item.ncondpag) {
              item.descricao = pagam.descricao;
              break;
            }
          }
        }
      };

      ctrl.fillDescricaoTransportadoras = function () {
        for (var i = 0; i < ctrl.transportadoraList.length; i++) {
          var item = ctrl.transportadoraList[i];
          for (var j = 0; j < ctrl.gcTransportadora.length; j++) {
            var transp = ctrl.gcTransportadora[j];
            if (transp.codTransportadora === item.codTransportadora) {
              item.nome = transp.nome;
              break;
            }
          }
        }
      };

      ctrl.getValueOf = function (key) {
        var i;
        for (i = 0; i < ctrl.play.length; i++) {
          if (ctrl.play[i].key === key) {
            return ctrl.play[i].value;
          }
        }
      };

      ctrl.refresh = function () {
        ctrl.reloadTransportadora().then(function () {
          ctrl.reloadCondicoesPagamento().then(function () {
            configFactory.getConfigSiteAdmin().then(
              function (data) {
                ctrl.config = data;
                ctrl.classificadoresList = data.classificadores;
                ctrl.condicoesPagamentoList = data.condicoesPagamento;
                ctrl.transportadoraList = data.transportadoras;
                ctrl.fillDescricaoCondicaoPagamento();
                ctrl.fillDescricaoTransportadoras();
                ctrl.updateERPTab();
              }
            );
          });
        });

        ctrl.info = configFactory.getInfo(function (ok, info) {
          if (ok) {
            ctrl.info = info;
            for (var item in info.playCfg) {
              if (Object.prototype.hasOwnProperty.call(info.playCfg, item)) {
                var n = {key: item, value: info.playCfg[item]};
                ctrl.play.push(n);
              }
            }
            ctrl.playFiltered = angular.copy(ctrl.play);
          }
        });

        var tab = $transition$.params().tab;
        for (var i = 0; i < ctrl.tabs.length; i++) {
          if (tab === ctrl.tabs[i].name) {
            ctrl.tabs[i].active = true;
            break;
          }
        }
      };

      ctrl.sendEmail = function () {
        PopAlert.showInfo('admin.config.controller.mensagens.sendingEmail', {'siteEmailContact': ctrl.config.siteEmailContact});
        emailService.sendContact('teste', ctrl.config.siteEmailContact, 'admin.config.controller.mensagens.testeEmailContacto').then(
          function (ok) {
            PopAlert.showSuccess('admin.config.controller.mensagens.emailSent', {'siteEmailContact': ctrl.config.siteEmailContact});
          },
          function (data, status, headers, config) {

            data = angular.fromJson(data);
            if (!Utils.isNullOrUndefined(data) && !Utils.isNullOrUndefined(data.status)) {
              PopAlert.showError('admin.config.controller.mensagens.showErrorEmailSentWithoutData', {
                'siteEmailContact': ctrl.config.siteEmailContact,
                'data': data.message
              });
              $log.error(data);
            } else {
              PopAlert.showError('admin.config.controller.mensagens.showErrorEmailSent', {'siteEmailContact': ctrl.config.siteEmailContact});
            }
          });

      };

      ctrl.tabSelect = function (tab) {
        $state.transitionTo('admin.config', {tab: tab.name}, {notify: false});
      };

      ctrl.updateMyFilter = function () {
        ctrl.playFiltered = $filter('filter')(ctrl.play, ctrl.myFilter, null, 'key');
      };

      ctrl.editProperties = function (item) {
        return CGModalService.show({
          templateUrl: 'app/admin/config/config.classificadores.properties.html',
          controller: 'ConfigClassificadoresPropertiesController',
          resolve: {
            classificador: /*@ngInject*/ function () {
              return item;
            },
            save: function () {
              return ctrl.save;
            }
          }
        });
      };

      ctrl.refresh();
    })
    .directive('configValue', function () {
      return {
        scope: {
          item: '<',
          configid: '<',
          hint: '@',
          label: '@',
          icon: '@',
          type: '@?'
        },
        bindToController: true,
        transclude: true,
        templateUrl: 'app/admin/config/config.value.html',
        controllerAs: 'ctrl',
        controller: /*@ngInject*/ function (configFactory, PopAlert, $filter) {
          var ctrl = this;

          ctrl.value = '';

          ctrl.$onInit = init;
          ctrl.$onChanges = onChanges;
          ctrl.updateItem = updateItem;

          /////////////

          function init() {
            if (!ctrl.hint) {
              ctrl.hint = $filter('translate')('admin.config.controller.mensagens.vazio');
            }
          }

          function onChanges(config) {
            if (config) {
              if (config.item) {
                ctrl.item = config.item.currentValue;
              }
              if (config.configid) {
                ctrl.configid = config.configid.currentValue;
              }
            }
          }

          function updateItem(configID, data) {
            configFactory.saveValue({configID: configID, value: data}, function (ok) {
              if (ok) {
                PopAlert.showSuccess('admin.config.controller.mensagens.configSaved');
                configFactory.refresh(true);
                return '';
              }
            });
          }
        }
      };
    });
})();
