(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('errorHandlerInterceptor', function ($location, $injector, $q, $state, cgexception) {
      return {
        'responseError': function (rejection) {
          if (rejection.stack) {
            //javascript error
            return $q.reject(rejection);
          }

          // In case server doesn't return a JSON response (usually 401 status error)
          if (!angular.isObject(rejection) || !rejection.config) {
            return $q.reject(rejection);
          }

          var exception = cgexception.get(rejection);

          if (rejection.status === -1 || rejection.data.message && rejection.data.message.indexOf('Connection refused') !== -1) {
            $state.go('disconnected', rejection);
          }

          if (exception && exception.class !== 'unknown') {
            var plAlertService = $injector.get('plAlertService');
            if (cgexception.isFatal(exception)) {
              plAlertService.error('error.server.exception');
            }
            else {
              plAlertService.error(exception.message);
            }
          }

          return $q.reject(rejection);
        }
      };
    });
})();
