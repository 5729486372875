(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-col9-col3', 'widget-html-col9-col3-edit', 'Html Col-9+Col-3');
    })

    .directive('widgetHtmlCol9Col3', function () {
      function controller($scope, WidgetsFactory, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgestHtmlCol9Col3 refresh');
        };
        $scope.img = [{imgUrl: ''}];
        $scope.item.data.html = {};
      }

      function link(scope) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '='
        },
        templateUrl: 'app/components/widgets/htmlCol9Col3/html.col9.col3.html',
        link: link,
        controller: controller
      };
    })

    .directive('widgetHtmlCol9Col3Edit', function () {
      function controller($scope, WidgetsFactory, imagePick, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgetHtmlCol9Col3Edit refresh');
        };
        $scope.addImage = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl = folder + img;
          });
        };
      }

      function link(scope) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlCol9Col3/html.col9.col3.edit.html',
        link: link,
        controller: controller
      };
    });
})();
