(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('AlteraMoradaModalController', function ($q, $log, item, paisesService, clienteService, cttService, $translate, PopAlert, $rootScope) {
      var ctrl = this;

      ctrl.definition = {};
      ctrl.form = {};
      ctrl.formDelete = {};
      ctrl.isNew = true;
      ctrl.paises = [];
      ctrl.paisesDelete = [];
      ctrl.moradasAltList = clienteService.getClienteMoradasAlternativas();

      ctrl.$onInit = init;
      ctrl.submit = submit;
      ctrl.submitDelete = submitDelete;
      ctrl.cancel = cancel;
      ctrl.findPais = findPais;
      ctrl.getPaisValue = getPaisValue;
      ctrl.setRegionByCodPostal = setRegionByCodPostal;
      ctrl.codPostValidator = codPostValidator;

      /////////////

      function init() {
        if (item) {
          ctrl.model = _.cloneDeep(item);
          ctrl.isNew = false;
        } else {
          ctrl.model = {};
        }
        ctrl.definition = {
          fields: []
        };

        ctrl.promise = paisesService.getPaises().then(function (paises) {
          ctrl.paises = paises;
          ctrl.definition = {
            fields: [
              {
                name: 'nome',
                caption: 'utilizador.perfil.moradas.identificacao',
                placeholder: 'utilizador.perfil.moradas.identificacao',
                properties: {disabled: ctrl.model.isPrincipal || ctrl.moradasAltList.length === 0},
                validators: {required: {value: true}}
              },
              {
                name: 'contactoNome',
                caption: 'utilizador.perfil.moradas.fatura.name',
                placeholder: 'utilizador.perfil.moradas.fatura.name',
                validators: {required: {value: true}}
              },
              {
                name: 'cpost', type: 'cpost', caption: 'utilizador.perfil.moradas.fatura.cp',
                placeholder: 'utilizador.perfil.moradas.fatura.cp',
                validators: {required: {value: true}, cpost: ctrl.codPostValidator(ctrl.getPaisValue)},
                events: {
                  change: function (plEditText) {
                    ctrl.setRegionByCodPostal(plEditText.value);
                  }
                }
              },
              {
                name: 'morada',
                caption: 'utilizador.perfil.moradas.fatura.address',
                placeholder: 'utilizador.perfil.moradas.fatura.address',
                validators: {required: {value: true}}
              },
              {
                name: 'localidade',
                caption: 'utilizador.perfil.moradas.fatura.local',
                placeholder: 'utilizador.perfil.moradas.fatura.local',
                validators: {required: {value: true}}
              },
              {
                name: 'paisIso',
                type: 'select',
                caption: 'utilizador.perfil.moradas.fatura.pais',
                placeholder: 'utilizador.perfil.moradas.fatura.pais',
                validators: {required: {value: true}},
                properties: {
                  select: {list: ctrl.paises, labelProp: 'nomePais', valueProp: 'codPaisIso'},
                  disabled: !ctrl.model.canChangeNIF && ctrl.model.isPrincipal
                }
              },
              {
                name: 'contactoTelefone',
                caption: 'utilizador.perfil.moradas.fatura.telefone',
                placeholder: 'utilizador.perfil.moradas.fatura.telefone',
                validators: {required: {value: false}}
              },
              {
                name: 'nipc',
                type: 'nif',
                caption: 'utilizador.perfil.moradas.fatura.nif',
                placeholder: 'utilizador.perfil.moradas.fatura.nif',
                properties: {
                  visible: ctrl.model.isPrincipal || false,
                  disabled: !ctrl.model.canChangeNIF
                },
                validators: {required: {value: false}, nif: clienteService.nifValidator(ctrl.getPaisValue)}
              }
            ]
          };
          if (ctrl.model && ctrl.model.paisIso && ctrl.paises.length) {
            var pais = ctrl.findPais();
            ctrl.model.nomePais = pais.paisIso.nomePais;
            ctrl.model.paisIso = pais.codPaisIso;
          }
        });
      }

      function submit() {
        if (ctrl.getPaisValue() && clienteService.validateNif(ctrl.model.nipc) === false && ctrl.model.canChangeNIF) {
          PopAlert.showError($rootScope.globalTranslate('validators.nif'));
          return false;
        }

        ctrl.form.$setSubmitted();
        if (ctrl.form.$invalid) {
          return;
        }

        ctrl.model.paisIso = ctrl.findPais();
        ctrl.model.codPaisIso = ctrl.model.paisIso.codPaisIso;
        ctrl.$close(ctrl.model);
      }

      function submitDelete() {
        ctrl.model.paisIso = ctrl.findPais();
        ctrl.model.codPaisIso = ctrl.model.paisIso.codPaisIso;
        ctrl.$close(ctrl.model);
      }

      function cancel() {
        ctrl.$dismiss();
      }

      function findPais() {
        var value = ctrl.model.paisIso;
        var isObject = angular.isObject(value);
        return ctrl.paises.find(function (pais) {
          var codPaisIso = pais.codPaisIso;
          return isObject ? codPaisIso === value.codPaisIso : codPaisIso === value;
        });
      }

      function getPaisValue() {
        var codPais = angular.isObject(ctrl.model.paisIso) ? ctrl.model.paisIso.codPaisIso : ctrl.model.paisIso;
        return codPais === 620;
      }

      function codPostValidator(value) {
        return function () {
          return {
            value: angular.isFunction(value) ? value() : value,
            message: $translate.instant('validators.cpost', {value: ''}),
            validate: function (name, ngModel) {
              if (name === false) {
                return true;
              }
              var regexCodPostal = /^\d{4}-\d{3}$/;
              return ngModel && regexCodPostal.test(ngModel) && ngModel.length === 8 && ctrl.setRegionByCodPostal(ngModel);
            }
          };
        };
      }

      function setRegionByCodPostal(codPostal) {
        if (!codPostal || (ctrl.model.paisIso.codPaisIso !== 620 && ctrl.model.paisIso !== 620)) {
          return;
        }

        cttService.getRegionByCodPostal(codPostal)
          .then(function (response) {
            var region = response.data.OK;
            if (region) {
              ctrl.model.morada = region.Localidade.Arruamentos.Rua.Designacao.content;
              ctrl.model.localidade = region.Localidade.Designacao.content;
              ctrl.model.nomePais = 'Portugal';
              ctrl.model.paisIso = 620;
            }
          })
          .catch(function (reason) {
            $log.error(reason);
          });
        return true;
      }
    });
})();
