(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('UtilizadorRecuperarController', function ($scope, Auth, $filter, $state, $transition$, plAlertService) {
      var ctrl = this;

      ctrl.email = '';
      ctrl.params = {};
      ctrl.promise = null;

      ctrl.$onInit = init;
      ctrl.recuperar = recuperar;

      /////////////

      function init() {
        ctrl.params = $transition$.params();
      }

      function recuperar() {
        ctrl.promise = Auth.recuperar($scope.email).then(
          function () {
            plAlertService.success('LOGIN_PASS_RECOVER_SEND_EMAIL');
            var params = $transition$.params();
            return $state.go('login', {returnstate: params.returnstate, returnstateparams: params.returnstateparams});
          },
          function () {
            plAlertService.error('LOGIN_ERROR_EMAIL_NOT_FOUND');
          }
        )['finally'](function () {
          ctrl.promise = null;
        });
      }
    });
})();
