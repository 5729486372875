(function () {
  'use strict';

  angular.module('erpRetail')
    .component('widgetCarouselEdit', {
      bindings: {
        item: '='
      },
      templateUrl: 'app/components/widgets/carousel/carousel.edit.html',
      controller: function (WidgetsFactory, Utils, imagePick, ImagensFactory, timePickerModal) {
        var ctrl = this;

        ctrl.tableform = {};

        ctrl.$onInit = init;
        ctrl.getImagem = ImagensFactory.getImagem;
        ctrl.refresh = refresh;
        ctrl.edit = edit;
        ctrl.save = save;
        ctrl.addItem = addItem;
        ctrl.removeItem = removeItem;
        ctrl.addImage = addImage;
        ctrl.openTimePickerModal = openTimePickerModal;

        /////////////

        function init() {
          if (Utils.isNullOrUndefined(ctrl.item.data.carousel)) {
            var newItem = {
              carousel: {
                items: []
              }
            };
            if (Utils.isNullOrUndefined(ctrl.item.publishAfter))
              ctrl.item.publishAfter = new Date();
            if (Utils.isNullOrUndefined(ctrl.item.publishedTo))
              ctrl.item.publishedTo = new Date('1/1/2050 12:00:00 PM');
            ctrl.item.data = newItem;
          }
        }

        function refresh() {
          WidgetsFactory.log('widgestCarousel refresh');
        }

        function edit() {
          if (ctrl.item.data.carousel && ctrl.item.data.carousel.items && !ctrl.item.data.carousel.items.length) {
            ctrl.addItem();
          }
          ctrl.tableform.$show();
        }

        function save() {
          ctrl.tableform.$setSubmitted();
          if (ctrl.tableform.$valid) {
            ctrl.tableform.$save();
          }
        }

        function addItem() {
          var newItem = {
            link: '',
            linkBtnText: '',
            titulo: '',
            subTitulo: '',
            image: '',
            _index: ctrl.item.data.carousel.items.length,
            dateTime: {
              from: new Date(),
              to: new Date('1/1/2050 12:00:00 PM'),
            }
          };

          ctrl.item.data.carousel.items.push(newItem);
        }

        function removeItem(item) {
          var index = ctrl.item.data.carousel.items.indexOf(item);
          if (index === -1) {
            return;
          }
          ctrl.item.data.carousel.items.splice(index, 1);
        }

        function addImage(item) {
          return imagePick.pick('', item.image, function (folder, img) {
            item.image = folder + img;
          });
        }

        function openTimePickerModal(aitem) {
          return timePickerModal.timePick(aitem.dateTime, function (item) {
            aitem.dateTime = item;
          });
        }
      }
    });
})();
