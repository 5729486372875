(function () {
    'use strict';
  angular.module('erpRetail')
        .run(['WidgetsFactory', function (WidgetsFactory) {
          WidgetsFactory.register('widget-jumbotron', 'widget-jumbotron-edit', 'Jumbotron');
        }])

    .directive('widgetJumbotron', function () {

            var controller = function ($scope, WidgetsFactory, ImagensFactory) {
                $scope.getImagem = ImagensFactory.getImagem;
                $scope.refresh = function () {
                  WidgetsFactory.log('widgestJumbotron refresh');
                };

            };

            function link(scope, element, attrs, $compile) {
                //scope.$watch('id', function () {
                //    scope.refresh();
                //});

            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/jumbotron/jumbotron.html',
                link: link,
                controller: controller
            };

        })

    .directive('widgetJumbotronEdit', function () {

            function controller($scope, WidgetsFactory, imagePick, ImagensFactory) {
                $scope.refresh = function () {
                  WidgetsFactory.log('widgestJumbotron refresh');
                };

                $scope.getImagem = ImagensFactory.getImagem;

                $scope.removeItem = function (item) {
                    var index = $scope.item.data.jumbotron.indexOf(item);
                    if (index === -1) return;
                    $scope.item.data.jumbotron.splice(index, 1);
                };

                $scope.addImage = function () {
                  imagePick.pick('', $scope.item.data.image, function (folder, img) {
                        $scope.item.data.image = folder + img;
                    });
                };
            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/jumbotron/jumbotron.edit.html',
                controller: controller
            };

        })


        .directive('backImg', function () {
            return function (scope, element, attrs) {
                var url = attrs.backImg;
                element.css({
                    'background-image': 'url(' + url + ')',
                    /*'background-image': 'url("http://www.centralgest.com/img/top/empresa/centralgest-sobre-nos.jpg")',*/
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat'
                });
            };
        });
}());
