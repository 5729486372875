(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function ($stateProvider) {
      $stateProvider
        .state('admin', {
          url: '/admin',
          title: 'admin.states.admin',
          params: {authenticate: 'admin'},
          redirectTo: 'admin.config',
          resolve: {
            'configSite': /*@ngInject*/ function (configFactory) {
              return configFactory.getConfigSite();
            }
          },
          views: {
            '': {
              templateUrl: 'app/admin/admin.html'
            },
            'header': {
              templateUrl: 'app/admin/header.html',
              controller: 'HomeController'
            },
            'footer': {
              templateUrl: 'app/admin/footer.html'
            }
          }
        })
        .state('admin.menu', {
          url: '/menu',
          title: 'admin.states.menu',
          params: {authenticate: 'admin'},
          templateUrl: 'app/admin/menu/menu.html',
          controllerAs: 'ctrl',
          controller: 'MenuController'
        })

        .state('admin.sincronizacao', {
          url: '/sincronizacao',
          title: 'admin.states.sync',
          params: {authenticate: 'admin'},
          templateUrl: 'app/admin/sincronizacao/sincronizacao.html',
          controller: 'SincronizacaoController'
        })
        .state('admin.documentos', {
          url: '/documentos/:mbfaccbId',
          title: 'admin.states.documentos',
          params: {
            authenticate: 'admin',
            mbfaccbId: { // Optional parameter
              value: null,
              squash: true,
              dynamic: true
            }
          },
          templateUrl: 'app/admin/documentos/documentos.html',
          controller: 'DocumentosController',
          controllerAs: 'ctrl'
        })
        .state('admin.clientes', {
          url: '/clientes',
          params: {authenticate: 'admin'},
          title: 'admin.states.clientes',
          templateUrl: 'app/admin/clientes/clientes.html',
          controller: 'ClientesController',
          controllerAs: 'ctrl'
        })
        .state('admin.homeedit', {
          url: '/home-editar',
          params: {authenticate: 'admin'},
          title: 'admin.states.editarHome',
          templateUrl: 'app/admin/home/homeEdit.html',
          controller: 'HomeEditController'
        })
        .state('admin.modelos', {
          url: '/modelos?modelo',
          params: {authenticate: 'admin'},
          title: 'admin.states.editarModelos',
          templateUrl: 'app/admin/modelos/modelos.html',
          controller: 'ModelosController',
          resolve: {
            'config': ['configFactory', function (configFactory) {
              return configFactory.getConfigSite();
            }],
            'ace': function ($ocLazyLoad) {
              return $ocLazyLoad.load('ace');
            }
          }
        });
    });
})();
