(function () {
  'use strict';

  // Loads the correct sidebar on window load, ollapses the sidebar on window resize.
  // Sets the min-height of #page-wrapper to window size
  angular.element(function () {
    angular.element(window).bind('load resize', function () {
      var topOffset = 50;
      var width = (this.window.innerWidth > 0) ? this.window.innerWidth : this.screen.width;
      if (width < 768) {
        angular.element('div.navbar-collapse').addClass('collapse');
        topOffset = 100; // 2-row-menu
      } else {
        angular.element('div.navbar-collapse').removeClass('collapse');
      }

      var height = (this.window.innerHeight > 0) ? this.window.innerHeight : this.screen.height;
      height = height - topOffset;
      if (height < 1) height = 1;
      if (height > topOffset) {
        angular.element('#page-wrapper').css('min-height', (height) + 'px');
      }
    });
  });
})();
