(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('UtilizadoresListController', /*@ngInject*/ function ($q, $state, User, $filter, Utils) {
      var ctrl = this;

      ctrl.searchText = '';

      ctrl.edit = edit;
      ctrl.updateFiltered = updateFiltered;
      ctrl.onLoad = onLoad;
      ctrl.init = init;

      ctrl.init();

      /////////////

      function init() {
        ctrl.grid = {
          list: [],
          filteredList: [],
          config: {
            itemsPerPage: 10,
            maxPages: 10,
            fillLastPage: true,
            orderBy: 'AIOrderBy'
          }
        };
        ctrl.onLoad();
      }

      function edit(item) {
        $state.go('admin.utilizadores.edit', {id: item.id});
      }


      function updateFiltered() {
        if (Utils.isNullOrEmpty(ctrl.searchText)) {
          ctrl.grid.filteredList = ctrl.grid.list;
        } else {
          ctrl.grid.filteredList = $filter('AIFilter')(ctrl.grid.list, ctrl.searchText);
        }
      }

      function onLoad() {
        var deferred = $q.defer();
        User.getAll().then(function (users) {
          ctrl.grid.list = users;
          ctrl.updateFiltered();
          deferred.resolve(ctrl.grid.list);
        })
          .catch(function (reason) {
            deferred.reject(reason);
          });
        return deferred.promise;
      }
    });
}());


