(function () {
    'use strict';
  angular.module('erpRetail')
        .run(['WidgetsFactory', function (WidgetsFactory) {
          WidgetsFactory.register('widget-breadcrumb', 'widget-breadcrumb-edit', 'Breadcrumb');
        }])

    .directive('widgetBreadcrumb', function () {

      var controller = ['$scope', 'WidgetsFactory', function ($scope, WidgetsFactory) {

                $scope.refresh = function () {
                  WidgetsFactory.log('widgestBreadcrumb refresh');
                };

                $scope.addItem = function () {
                  $scope.item.data.breadcrumb.push({'titulo': ''});
                };
                $scope.removeItem = function (item) {
                    var index = $scope.item.data.breadcrumb.indexOf(item);
                    if (index === -1) return;
                    $scope.item.data.breadcrumb.splice(index, 1);
                };

            }];

            function link(scope, element, attrs, $compile) {
                //scope.$watch('id', function () {
                //    scope.refresh();
                //});

            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/breadcrumb/breadcrumb.html',
                link: link,
                controller: controller
            };

        })

    .directive('widgetBreadcrumbEdit', ['WidgetsFactory', function (WidgetsFactory) {

            function controller($scope, WidgetsFactory) {

                $scope.save = function () {
                  $scope.tableform.$setSubmitted();
                  if ($scope.tableform.$valid) {
                    $scope.tableform.$save();
                  }
                };

                $scope.refresh = function () {
                  WidgetsFactory.log('widgestBreadcrumb refresh');
                };

            }

            return {
                restrict: 'E',
                scope: {
                    item: '=item'
                },
                templateUrl: 'app/components/widgets/breadcrumb/breadcrumb.edit.html',
                controller: controller
            };

        }]);
}());


