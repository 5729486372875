(function () {
    'use strict';

    angular.module('erpRetail')
      .controller('UtilizadorConfirmaEmailController', ['$scope', 'Auth', '$state', function ($scope, Auth, $state) {
            $scope.email = Auth.getEmailToConfirm();


            $scope.run = function () {
                if (angular.isUndefined($scope.email) || angular.isUndefined($scope.email.email) || $scope.email.email.length === 0) {
                  $state.go('loja.home', {id: 'home'});
                }
            };

            $scope.enviar = function () {
                Auth.sendEmailToConfirm($scope.email.email);
            };

            $scope.run();

        }]);
}());
