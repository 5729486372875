(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('PopAlert', function (plAlertService, Utils) {

      var that = {};

      that.showInfo = function (text, params) {
        plAlertService.success(Utils.translate(text, params));
      };

      that.showSuccess = function (text, params) {
        plAlertService.success(Utils.translate(text, params));
      };

      that.showError = function (text, params) {
        plAlertService.error(Utils.translate(text, params));
      };

      that.prompt = function (messagem, defaultValue, fok, fcancel) {
        plAlertService.prompt(Utils.translate(messagem), Utils.translate(defaultValue), fok, fcancel);
      };

      that.alert = function (title, messagem, ret) {
        plAlertService.msgBoxConfirm(Utils.translate(title), Utils.translate(messagem)).result.then(function (result) {
          if (result === 'ok')
            ret(true);
        });
      };


      that.postError = function (mensagem) {
        var aux = '';
        if (angular.isDefined(mensagem)) aux = '<br>' + mensagem;
        plAlertService.error(Utils.translate('alerts.postError', {'dados': aux}));
      };

      that.confirm = that.alert;

      return that;
    });
}());
