(function () {
    'use strict';

//event listener form DOM ready
    function addLoadEvent(func) {
        var oldonload = window.onload;
      if (typeof window.onload !== 'function') {
            window.onload = func;
        } else {
            window.onload = function () {
                if (oldonload) {
                    oldonload();
                }
                func();
            };
        }
    }

//call function after DOM ready
    addLoadEvent(function () {
        outdatedBrowser({
            bgColor: '#3f3f3f',
            color: '#e3e3e3',
            lowerThan: 'IE11',
          languagePath: 'node_modules/outdated-browser/outdatedbrowser/lang/pt.html'
        });
    });
}());
