(function () {
    'use strict';


// baseado no https://github.com/ghoullier/ng-image-cache
// <ui-image data-src="http://localhost/images/test.png" data-title="Test"></ui-image>


    function uiImageDirective() {
        return {
            link: link,
            restrict: 'E',
            replace: true,
            scope: {
                cacheSrc: '@',
                title: '@'
            },
            template: '<img title="{{title}}"/>'
        };

        function link(scope, element, attributes) {
            element.attr('src', attributes.cacheSrc);
        }
    }

  angular.module('ui.CGImageCache', [])
        .directive('cgImage', uiImageDirective)
    ;

}());
