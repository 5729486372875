(function () {
  'use strict';
    angular.module('erpRetail')

        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('admin.imagens', {
                    abstract: true,
                    url: '/imagens',
                  title: 'imagens',
                    params: {authenticate: 'admin'},
                  templateUrl: 'app/admin/imagens/imagens.html'

                })
                .state('admin.imagens.lista', {
                    url: '/lista',
                  title: 'Imagens',
                    params: {authenticate: 'admin'},
                  templateUrl: 'app/admin/imagens/imagens.list.html',
                  controller: 'FileListController'
                })
                .state('admin.imagens.galeria', {
                    url: '/galeria',
                  title: 'Imagens',
                    params: {authenticate: 'admin'},
                  templateUrl: 'app/admin/imagens/imagens.galeria.html',
                  controller: 'FileListController'
                })
                .state('admin.imagens.upload', {
                    url: '/enviar',
                  title: 'Imagens',
                    params: {authenticate: 'admin'},
                  templateUrl: 'app/admin/imagens/imagens.upload.html',
                  controller: 'FileUploadController'
                });
        }]);
}());
