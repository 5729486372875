(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('MovimentosAbertoController', /*@ngInject*/ function ($rootScope, $q, $state, documentosService, documentoService, Utils, PopAlert) {
      var ctrl = this;

      ctrl.definition = {};
      ctrl.init = init;
      ctrl.onLoad = onLoad;

      ctrl.init();

      /////////////

      function init() {
        ctrl.definition = {
          order: 'stampInsert desc',
          options: {
            perPage: 10
          },
          fields: [
            {name: 'stampInsert', type: 'timestamp', visible: false, caption: ''},
            {name: 'nConta', type: 'text', caption: 'utilizador.movimentos.nConta'},
            {name: 'nDocumentoFac', type: 'text', caption: 'utilizador.movimentos.nDocFac'},
            {name: 'dataDoc', type: 'date', caption: 'utilizador.movimentos.dataDoc'},
            {name: 'dataVenc', type: 'date', caption: 'utilizador.movimentos.dataVenc'},
            {name: 'debito', align: 'right', type: 'currency', caption: 'utilizador.movimentos.debito'},
            {name: 'credito', align: 'right', type: 'currency', caption: 'utilizador.movimentos.credito'},
            {name: 'saldo', align: 'right', type: 'currency', caption: 'utilizador.movimentos.saldo'},
            {name: 'nDocExterno', type: 'text', caption: 'utilizador.movimentos.nDocExterno'},
            {name: 'nRequisicao', type: 'text', caption: 'utilizador.movimentos.nRequisicao'}
          ]
        };

        ctrl.onLoad();
      }

      function onLoad(search, order, page, perpage) {
        var deferred = $q.defer();
        documentosService.getMovimentos(search, page, perpage, order)
          .then(function (response) {
            var linhas = response.data.list.map(function (item) {
              item._$ctrl = ctrl;
              return item;
            });
            deferred.resolve(linhas);
          })
          .catch(function (reason) {
            deferred.reject(reason);
            PopAlert.showError($rootScope.globalTranslate('utilizador.movimentos.fetchError'));
          });
        return deferred.promise;
      }
    });
})();
