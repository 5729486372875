(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('DisconnetedController', function ($rootScope) {
      var ctrl = this;
      ctrl.$config = $rootScope.$config;
      ctrl.back = $rootScope.goBack;
    });
})();
