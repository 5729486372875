(function () {
  'use strict';

  angular.module('erpRetail')
    .component('cgListNav', {
      require: {
        ngModel: 'ngModel',
        loja: '?^loja'
      },
      bindings: {
        listView: '=?',
        sortList: '<?',
        sortOrder: '=?',
        onViewChange: '&?',
        onSearch: '&?',
        onSortOrder: '&?',
        properties: '<?'
      },
      templateUrl: 'app/components/directives/cgListNav/cgListNav.html',
      controllerAs: 'ctrl',
      controller: /*@ngInject*/ function ($scope) {
        var ctrl = this;
        var defaultOptions = {
          disabled: {
            btnBox: false,
            btnList: false,
            order: false,
            search: false
          }
        };

        ctrl.options = {};
        ctrl.sortList = null;
        ctrl.sortOrders = [
          {name: 'STORE_SORT_ALPHA_ASC', value: 'artigonome'},
          {name: 'STORE_SORT_ALPHA_DESC', value: 'artigonome desc'},
          {name: 'STORE_SORT_PRICE_ASC', value: 'PrecoComIva asc'},
          {name: 'STORE_SORT_PRICE_DESC', value: 'PrecoComIva desc'},
          {name: 'STORE_SORT_PROMO', value: 'PrecoPromoComIva desc'}
        ];

        ctrl.form = {};
        ctrl.listView = '';
        ctrl.sortOrder = '';
        ctrl.searchValue = '';

        $scope.$on('menu-produtos', function () {
          ctrl.cleanSearch();
        });

        ctrl.$onInit = init;
        ctrl.render = render;
        ctrl.setListView = setListView;
        ctrl.sortOrderChanged = sortOrderChanged;
        ctrl.doSearch = doSearch;
        ctrl.cleanSearch = cleanSearch;

        /////////////

        function init() {
          ctrl.listView = ctrl.listView || 'box';
          ctrl.sortOrders = ctrl.sortList || ctrl.sortOrders;
          ctrl.sortOrder = ctrl.sortOrder || ctrl.sortOrders[0].value;

          // Initialize search value
          ctrl.ngModel.$render = function () {
            ctrl.searchValue = ctrl.ngModel.$modelValue || ctrl.searchValue;
          };

          if (ctrl.loja && ctrl.loja.options) {
            angular.extend(defaultOptions, ctrl.loja.options);
          }
          ctrl.options = angular.extend({}, defaultOptions, ctrl.properties);

          if (ctrl.listView === 'box' && ctrl.options.disabled.btnBox) {
            ctrl.setListView('list');
          }
        }

        function render() {
          ctrl.ngModel.$setViewValue(ctrl.searchValue);
          ctrl.doSearch(ctrl.form.$valid);
        }

        function setListView(type) {
          if ((type === 'box' && !ctrl.options.disabled.btnBox) || (type === 'list' && !ctrl.options.disabled.btnList)) {
            ctrl.listView = type;
            if (angular.isFunction(ctrl.onViewChange)) {
              ctrl.onViewChange({listView: type});
            }
          }
        }

        function sortOrderChanged() {
          if (angular.isFunction(ctrl.onSortOrder) && !ctrl.options.disabled.order) {
            ctrl.onSortOrder({order: ctrl.sortOrder});
          }
        }

        function doSearch(isValid) {
          if (isValid && angular.isFunction(ctrl.onSearch) && !ctrl.options.disabled.search) {
            ctrl.onSearch();
          }
        }

        function cleanSearch() {
          ctrl.searchValue = '';
          ctrl.render();
        }

        /////////////

        $scope.$watch(angular.bind(ctrl, function () {
          var array = [ctrl.properties];
          if (ctrl.loja && ctrl.loja.options) {
            array.push(ctrl.loja.options);
          }
          return array;
        }), function (newVal, oldVal) {
          if (newVal && newVal !== oldVal) {
            if (ctrl.loja && ctrl.loja.options) {
              angular.extend(ctrl.options, ctrl.loja.options);
            }
            angular.extend(ctrl.options, ctrl.properties);
          }
        }, true);
      }
    });
})();
