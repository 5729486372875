(function () {
    'use strict';
    angular.module('erpRetail')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
              .state('noresponse', {
                url: '/noresponse',
                title: 'error.states.semResposta',
                templateUrl: 'app/erros/noresponse.html',
                controller: 'Erro404Controller'
                })
              .state('e404', {
                parent: 'loja',
                url: '/404',
                title: 'error.states.semResposta',
                templateUrl: 'app/erros/404.html'
                });
        }]);
}());
