(function () {
  'use strict';

  angular.module('erpRetail')
    .constant('CONFIG', {
      USE_DEFAULT: false,
      SITE_CONFIG: {
        siteName: 'ERPRetail',
        siteUrl: '',
        siteLogo: 'assets/images/ERPRetail-services-inv.png',
        ivaIncluido: true,
        googleAnalytics: '',
        modelos: {
          box: '',
          list: '',
          detalhe: ''
        },
        modelosClassificador: {
          box: '',
          list: '',
          detalhe: ''
        },
        classificadores: [
          {
            tipo: 'familias',
            caption: 'Tipo',
            position: 0,
            visible: true
          },
          {
            tipo: 'classes',
            caption: 'Marca',
            position: 1,
            visible: true
          },
          {
            tipo: 'grandesFamilias',
            caption: 'Género',
            position: 2,
            visible: true
          },
          {
            tipo: 'subFamilias',
            caption: 'Volume',
            position: 3,
            visible: true
          },
          {
            tipo: 'categorias',
            caption: 'Categoria',
            position: 4,
            visible: true
          },
          {
            tipo: 'tipos',
            caption: 'Tipos',
            position: 5,
            visible: false
          },
          {
            tipo: 'tags',
            caption: 'Tags',
            position: 6,
            visible: false
          }
        ],
        simboloMoeda: '',
        checkout: {
          mostraDataEntregaPrev: false
        },
        trackingInfos:[
            {
              name:'DHL',
              url: 'https://www.dhl.com/pt-en/home/tracking.html?tracking-id=%s',
              codTransportadora: 0
            }
        ]
      }
    })
    .provider('REST_API', function (API_URL) {
      var provider = {
        ADMIN_CONFIG_ALL: 'api/config/todos',
        ADMIN_SYNC_ALL: 'api/sincronizar/inicio',
        ADMIN_SYNC_ALL_COMPLETE: 'api/sincronizar/completo',
        ADMIN_SYNC_IMAGES: 'api/sincronizar/imagens/0',
        ADMIN_SYNC_IMAGES_ALL: 'api/sincronizar/imagens/1',
        ADMIN_SYNC_STATUS: 'api/sincronizar/estado',
        ADMIN_CLIENTES_ALL: 'api/clientes/todos',
        USER_ME: 'api/utilizador',
        USER: 'api/utilizador/id', //:id
        USER_ALL: 'api/utilizador/todos',
        USER_EMAIL_CONFIRM: 'api/utilizador/emailconfirmacao',
        USER_EMAIL_VALIDATION: 'api/utilizador/confirmaemail',
        USER_EMAIL_RECOVER: 'api/utilizador/recuperar',
        USER_EMAIL_EXISTS: 'api/utilizador/emailexists', //:email
        USER_NOVA_PASSWORD: 'api/utilizador/nova',
        USER_MUDAR_PASSWORD: '/password',
        DOCUMENTOS_ALL: 'api/documentos/todos',
        DOCUMENTOS_CLIENTE: 'api/documentos/cliente',
        DOCUMENTOS_COMERCIAIS: 'api/documentos/docscomerciaisonline',
        DOCUMENTOS_ENCOMENDAS: 'api/documentos/encomendaspendentesonline',
        DOCUMENTOS_MOVIMENTOS: 'api/documentos/movimentosemaberto',
        DOCUMENTOS_ONLINE: 'api/documentos/documentoonline',
        DOCUMENTOS_EXPORT: 'api/documentos/exporttopdfonline',
        DOCUMENTO: 'api/documentos/doc', // :mbfaccbid
        MEIOS_PAGAMENTO: 'api/documentos/meiospagamento',
        REFERENCIASMB: 'api/documentos/referenciasmultibanco', // :mbfaccbid
        CLIENTE: 'api/clientes/eu',
        CLIENTEMORADASALTERNATIVAS: 'api/clientes/moradasalternativas',
        CLIENTEMORADASALTERNATIVASEDIT: 'api/clientes/moradaalternativa',
        CLIENTES: 'api/clientes/todos',
        PAISES: 'api/paises/paisesiso',
        CONFIG_VALUE: 'api/config/valor',
        CONFIG_INFO: 'api/config/info',
        CONFIG_SITE: 'api/config/site',
        CONFIG_SITE_ADMIN: 'api/config/siteadmin',
        CONFIG_MENU_BUILD: 'api/config/menu',
        CONFIG_CLASSIFICADORES: 'api/config/classificadores',
        CONFIG_CONDICOES_PAGAMENTO: 'api/config/condicoespagamento',
        CONFIG_TRANSPORTADORAS: 'api/config/transportadoras',
        EMAIL_CONTACT: 'api/email/sendcontact',
        LOGIN: 'api/login',
        LOGOUT: 'api/logout',
        EMPRESA: 'api/empresa',
        PRODUTOS_CATALOGOS: 'api/artigos/catalogos',
        PRODUTOS_CATALOGO: 'api/artigos/catalogo', //:catalogoCab
        PRODUTOS_IMAGE_PATH: 'api/artigos/imagem/', //:imagem
        PRODUTOS_NOIMAGE: 'assets/images/produtos/noimage.jpg',
        MODELOS: 'api/modelos', //:tipos
        MODELOS_TIPOS: 'api/modelos/tipos',
        MODELOS_ORIGINAL: 'api/modelos/original', //:typo
        CHECKOUT: 'api/artigos/checkout',
        IMAGE_UPLOAD: 'api/imagens/upload',
        IMAGE_LIST: 'api/imagens/list', // : folder
        IMAGE_GET: 'api/imagem',
        IMAGE_RENAME: 'api/imagem/renomar',
        PAGINAS_TODAS: 'api/paginas/todas',
        PAGINA: 'api/pagina', //:id
        PAGAMENTO_PAYPAL: 'api/pagamentos/paypal/inicio',
        PAGAMENTO_SET_PAYED: 'api/pagamentos/setpayed', //:mbfaccbid
        PRODUTOS_TODOS: 'api/artigos/todos',
        PRODUTO_DETALHE: 'api/artigos/detalhe',
        PRODUTO_BASKET_CALC: 'api/artigos/basketcalc',
        PRODUTO_PESQUISA: 'api/artigos/pesquisa',
        PRODUTO_TEXTO_CLASSIFICADOR: 'api/artigos/textosclassificador',
        PRODUTO_ANEXOS: 'api/artigos/documentos',
        PRODUTO_DOCUMENTO: 'api/artigos/documento',
        PAGAMENTO_CONDICOES_PAGAMENTO: 'api/pagamentos/condicoespagamento',
        PAGAMENTO_TRANSPORTADORAS: 'api/pagamentos/transportadoras',
        PAGAMENTO_REGIOES: 'api/pagamentos/regioes',
        PAGAMENTO_CUSTOCAB: 'api/pagamentos/custoCab',
        PAGAMENTO_CUSTOLIN: 'api/pagamentos/custoLin',
        TRANSPORTADORAS: 'api/transportadoras',
        CTT_CODIGO_POSTAL: 'api/ctt/codigopostal',
        RESTAPI_ARTIGO: 'api/cgrestapi/artigo',
        $get: /*@ngInject*/ factory
      };
      return provider;

      /////////////

      function factory($location) {
        if (!API_URL) {
          var url = $location.protocol() + '://' + $location.host();
          var port = $location.port();
          if (port) {
            url += ':' + port;
          }
          url += '/';
          API_URL = url;
        }
        return angular.extend({}, provider, {
          HOST: API_URL
        });
      }
    })
    .config(/*@ngInject*/ function ($ocLazyLoadProvider, plEditProvider, $urlServiceProvider) {
      $ocLazyLoadProvider.config({
        modules: [
          {
            name: 'ace',
            files: [
              'node_modules/ace-builds/src-noconflict/ace.js',
              'node_modules/ace-builds/src-noconflict/theme-monokai.js',
              'node_modules/ace-builds/src-noconflict/worker-json.js',
              'node_modules/ace-builds/src-noconflict/mode-json.js',
              'node_modules/ace-builds/src-noconflict/mode-html.js',
              'node_modules/ace-builds/src-noconflict/mode-css.js'
            ]
          }
        ]
      });

      plEditProvider.map('text', 'nif', {validators: {minlength: 9, maxlength: 9}});

      var REGEX_GUID = new RegExp(/^[{(]?[0-9A-F]{8}[-]?([0-9A-F]{4}[-]?){3}[0-9A-F]{12}[)}]?$/, 'g');
      $urlServiceProvider.config.type('guid', {
        name: 'guid',
        definition: {
          encode: function (value) {
            return encodeURI(value);
          },
          decode: function (value) {
            return decodeURI(value);
          },
          pattern: REGEX_GUID,
          equals: function (a, b) {
            return a.trim() === b.trim();
          },
          is: function (val) {
            return REGEX_GUID.test(val);
          }
        }
      });

    });
}());
