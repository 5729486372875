(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function (WidgetsFactory) {
      WidgetsFactory.register('widget-carousel', 'widget-carousel-edit', 'Carousel');
    })
    .component('widgetCarousel', {
      bindings: {
        item: '<'
      },
      templateUrl: 'app/components/widgets/carousel/carousel.html',
      controller: function (configFactory, WidgetsFactory, ImagensFactory, $rootScope, Utils) {
        var ctrl = this;

        ctrl.myInterval = 5000;
        ctrl.active = 0;
        ctrl.actual = null;
        ctrl.index = null;
        ctrl.carouselItems=[];

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.getImagem = ImagensFactory.getImagem;
        ctrl.refresh = refresh;
        ctrl.filterItems = filterItems;
        ctrl.getDatetime = new Date();

        $rootScope.$watch('widgetChanged', function(newValue, oldValue) {          
          ctrl.getDatetime = new Date();
          refresh();
        });
        /////////////

        function init() {
          ctrl.refresh();
          if (ctrl.carouselItems.length>0)
            ctrl.index = ctrl.carouselItems[ctrl.active]._index;
        }

        function onChanges(carousel) {
          if (carousel && carousel.item && !carousel.item.isFirstChange()) {
            ctrl.refresh();
          }
        }

        function refresh() {
          WidgetsFactory.log('widgestCarousel refresh');
          
          if (ctrl.item.data.carousel) {            
            ctrl.carouselItems=[];
            angular.forEach(ctrl.item.data.carousel.items, function (item) {
              if (filterItems(item)) {
                item._index = ctrl.carouselItems.length;
                ctrl.carouselItems.push(item);
              }
            });            
          }
        }

        function filterItems(element) {
          if (Utils.isNullOrUndefined(element.dateTime)) return true;
          if (Utils.isNullOrUndefined(element.dateTime.from)) return true;
          if (Utils.isNullOrUndefined(element.dateTime.to)) return true;
             
          return ctrl.getDatetime >= new Date(element.dateTime.from) && ctrl.getDatetime <= new Date(element.dateTime.to);
        }
      }
    });
}());
