(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('imagePick', function ($uibModal, Utils) {
      return {
        pick: pick
      };

      /////////////

      function pick(folder, imageUrl, callback) {
        var modalInstance = $uibModal.open({
          templateUrl: 'app/components/imagepick/image.pick.html',
          controllerAs: '$ctrl',
          controller: 'imagePickController',
          modalFade: true,
          backdrop: true,
          size: 'lg',
          resolve: {
            item: function () {
              return {
                folder: folder,
                imageUrl: imageUrl
              };
            }
          }
        });
        if (!Utils.isNullOrUndefined(callback)) {
          modalInstance.result.then(function (selectedItem) {
            callback(selectedItem.folder, selectedItem.name);
          });
        }
        return modalInstance.result;
      }
    });
})();
