(function () {
  'use strict';
  angular.module('erpRetail')
    .run(['WidgetsFactory', function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-full-button', 'widget-html-full-button-edit', 'Html button');
    }])

    .directive('widgetHtmlFullButton', ['WidgetsFactory', function (WidgetsFactory) {

      function controller($scope, WidgetsFactory) {
        $scope.refresh = function () {
          WidgetsFactory.log('widgestHtmlFullButton refresh');
        };
      }

      function link(scope, element, attrs) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlFullButton/html.full.button.html',
        link: link,
        controller: controller
      };

    }])
    .directive('widgetHtmlFullButtonEdit', ['WidgetsFactory', function (WidgetsFactory) {
      function controller($scope, WidgetsFactory) {
        $scope.refresh = function () {
          //WidgetsFactory.log("widgetHtmlEdit refresh");
          //WidgetsFactory.log($scope.item);
        };

      }

      /*function link(scope, element, attrs, $compile) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }*/

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlFullButton/html.full.button.edit.html',
        controller: controller
      };

      /*return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlFullButton/html.full.button.edit.html',
        link: link,
        controller: controller
      };*/
    }]);
}());
