(function () {
  'use strict';
  angular.module('erpRetail')
    .run(['WidgetsFactory', function (WidgetsFactory) {
      WidgetsFactory.register('widget-contactos-info', 'widget-contactos-info-edit', 'Contactos');
    }])

    .directive('widgetContactosInfo', function () {
      /*@ngInject*/
      function controller($scope, WidgetsFactory, EmpresaFactory, emailService, Utils, PopAlert) {
        $scope.empresa = {};
        $scope.form = {};
        $scope.submited = false;

        $scope.refresh = function () {
          EmpresaFactory.get().then(function (empresa) {
            $scope.empresa = empresa;
          });
          WidgetsFactory.log('widgestContactosInfo refresh');
        };

        $scope.sendMail = function (form) {
          if (form.$valid) {
            emailService.sendContact($scope.form.from, $scope.form.email, $scope.form.message).then(function () {
                PopAlert.showInfo('widgets.contactosInfo.mensagens.emailSentSuccess');
                $scope.submited = true;
                $scope.form.message = '';
              },
              function () {
                PopAlert.showError('widgets.contactosInfo.mensagens.emailSentError');
              });
          }
        };
        $scope.refresh();
      }


      function link(scope, element, attrs) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/contactosInfo/contactos.info.html',
        link: link,
        controller: controller
      };

    })
    .directive('widgetContactosInfoEdit', function () {
      function controller($scope, WidgetsFactory) {
        //$scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgetContactosInfoEdit refresh');
          //WidgetsFactory.log($scope.item);
        };
      }

      function link(scope, element, attrs, $compile) {
        scope.$watch('item', function () {
          scope.refresh();
        });

      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/contactosInfo/contactos.info.edit.html',
        link: link,
        controller: controller
      };
    });
}());
