(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function (LightboxProvider) {
      LightboxProvider.templateUrl = 'app/components/directives/image/lightbox-cg.html';
    })
    .component('imageGallery', {
      bindings: {
        images: '='
      },
      templateUrl: 'app/components/directives/image/image.gallery.html',
      controllerAs: '$ctrl',
      controller: /*@ngInject*/ function (Lightbox) {
        var ctrl = this;

        ctrl.openLightboxWidgetModal = openLightboxWidgetModal;

        /////////////

        function openLightboxWidgetModal(index) {
          return Lightbox.openModal(ctrl.images, index, {templateUrl: 'app/components/directives/image/lightbox-cg.html'});
        }
      }
    });
})();
