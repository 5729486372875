(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('ModelosController', function ($rootScope, $scope, Auth, ModelosFactory, $filter, Utils, storage,
                                               configFactory, config, $transition$, $state, PopAlert, $translate) {
      $scope.config = config;
      $scope.classificadoresList = configFactory.classificadoresList;
      $scope.classificadores = configFactory.classificadores;
      $scope.texto = '';
      $scope.textotype = [];
      $scope.textoTipo = {
        name: '',
        type: $transition$.params().modelo
      };
      $scope.item = {};
      $scope.ctrl = {
        config: config,
        noimage: storage.noimage,
        getImagem: storage.getImagem,
        addBasket: function (item) {
          PopAlert.showSuccess('admin.config.modelos.mensagens.addToCart');
        },
        getDatetime: function () {
          return $rootScope.getDatetime;
        },
        showDetail: function (item) {
          PopAlert.showSuccess('admin.config.modelos.mensagens.seeDetail');
        },
        showDetailClassificador: function (item) {
          PopAlert.showSuccess('admin.config.modelos.mensagens.seeDetailClass');
        }

      };
      $scope.storage = storage;

      var editor = ace.edit('editor');
      editor.getSession().setUseWorker(false);
      editor.setTheme('ace/theme/monokai');
      //editor.getSession().setMode("ace/mode/html");
      editor.getSession().setUseWrapMode(true);
      editor.$blockScrolling = Infinity;

      var editorj = ace.edit('editor_json');
      editorj.setTheme('ace/theme/monokai');
      editorj.getSession().setMode('ace/mode/json');
      editorj.getSession().setTabSize(2);
      editorj.getSession().setUseWrapMode(true);
      editorj.$blockScrolling = Infinity;


      $scope.editorChanged = function (data) {
        if ($scope.textoTipo.type === 'TEXT_PAGE_CSS') {

          $rootScope.setCss($scope.texto.html);
        }

      };

      $scope.loadModelo = function () {
        if ($scope.textoTipo !== null && !Utils.isNullOrEmpty($scope.textoTipo.type)) {
          ModelosFactory.getTexto($scope.textoTipo.type).then(function (texto) {

            $scope.texto = texto;
            $scope.item = texto.options.vars;
            $scope.jsonData = {item: $scope.item, config: config};
            $scope.jsonData = JSON.stringify($scope.jsonData, null, 4);
            if ($scope.textoTipo.type === 'TEXT_PAGE_CSS') {
              editor.getSession().setMode('ace/mode/css');
              $scope.myDynamicClass = 'form-group col-md-12';
            } else {
              editor.getSession().setMode('ace/mode/html');
              $scope.myDynamicClass = 'form-group col-md-8';
            }
          });
        }
      };

      $scope.refresh = function () {
        ModelosFactory.getTipos().then(function (tipos) {
          $scope.textotype = [];
          angular.forEach(tipos, function (item) {
            var type = {name: $filter('translate')(item), type: item};
            $scope.textotype.push(type);
            if ($scope.textoTipo === null || Utils.isNullOrEmpty($scope.textoTipo.type) ||
              (Utils.isNullOrEmpty($scope.textoTipo.name)) && $scope.textoTipo.type === type.type) {
              $scope.textoTipo = type;
            }
          });
          $scope.loadModelo();
        });

      };

      $scope.$watch('textoTipo', function () {
        if ($scope.textoTipo === null || $scope.textoTipo.type === $transition$.params().modelo) return;
        $state.go('admin.modelos', {modelo: $scope.textoTipo.type});

      });

      $scope.jsonDataChanged = function () {
        $scope.item = angular.fromJson($scope.jsonData);
      };

      $scope.getDefault = function () {
        ModelosFactory.getOriginal($scope.textoTipo.type).then(function (data) {
          $scope.texto.html = data;
        });
      };

      $scope.getPreview = function (html, modelo) {
        var replaceAll = function (find, replace, str) {
          if (str === null || str === '') return '';
          return str.replace(new RegExp(find, 'g'), replace);
        };
        var ret = modelo;

        if (Utils.isNullOrEmpty(ret)) {
          ret = html;
          if (Utils.isNullOrEmpty(ret)) return '';
          if (angular.isDefined($scope.texto.vars)) {
            $scope.texto.vars.texto = $translate.instant('admin.config.modelos.textSub');
          }
        }
        else if (angular.isDefined($scope.texto.vars)) {
          $scope.texto.vars.texto = html;
        }

        angular.forEach($scope.texto.vars, function (value, key) {
          //html += key +"= "+value+"<br>";
          ret = replaceAll('@' + key, value, ret);
        });
        return ret;
      };

      $scope.save = function () {
        ModelosFactory.save($scope.textoTipo.type, $scope.texto.subject, $scope.texto.html).then(function () {
          PopAlert.showSuccess($filter('translate')('TEXT_SAVED'));
          configFactory.refresh(true);
        });
      };

      $scope.refresh();

    });
})();
