/**
 * Created by Pedro Lopes on 08/10/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('documentoService', function ($resource, $q, $http, $window, REST_API, storage, Auth, Utils, configFactory) {
      return {
        getDoc: function (mbfaccbid) {
          var deferred = $q.defer();
          if (Utils.isNullOrUndefined(mbfaccbid)) {
            deferred.reject();
          } else {
            $http.get(REST_API.HOST + REST_API.DOCUMENTO + '/' + mbfaccbid)
              .then(function (response) {
                deferred.resolve(response);
              })
              .catch(function (reason) {
                deferred.reject(reason);
              });
          }
          return deferred.promise;
        },
        getMeiosPagamento: function () {
          var deferred = $q.defer();

          $http.get(REST_API.HOST + REST_API.MEIOS_PAGAMENTO)
            .then(function (data) {
              deferred.resolve(data.data);
            });
          return deferred.promise;
        },
        getReferenciasMB: function (mbfaccbid) {
          var deferred = $q.defer();
          if (Utils.isNullOrUndefined(mbfaccbid)) {
            deferred.reject();
          } else {
            $http.get(REST_API.HOST + REST_API.REFERENCIASMB + '/' + mbfaccbid)
              .then(function (response) {
                deferred.resolve(response);
              })
              .catch(function (reason) {
                deferred.reject(reason);
              });
          }
          return deferred.promise;
        },
        getDocOnline: function (faccbid) {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.DOCUMENTOS_ONLINE + '/' + faccbid).then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        gerarDoc: function () {
          var deferred = $q.defer();
          configFactory.getConfigSite().then(function (configSite) {
              var lista = [];
              for (var i = 0; i < storage.cesto.length; i++) {
                var linha = {
                  qtd: storage.cesto[i].qtd,
                  nArtigo: storage.cesto[i].nArtigo
                };
                if (configSite.checkout.mostraDataEntregaPrev) {
                  linha.dataEntregaPrev = storage.cesto[i].dataEntregaPrev;
                }
                lista.push(linha);
              }
              var param = {
                'cab': storage.cesto.cab,
                'linhas': lista
              };
              $http.post(REST_API.HOST + REST_API.CHECKOUT, param)
                .then(function (data) {
                  storage.clear();
                  deferred.resolve(data.data);
                }, function (err) {
                  deferred.reject(err);
                });
            },
            deferred.reject);
          return deferred.promise;
        },
        getPdfUrl: function (id) {
          return REST_API.HOST +
            REST_API.DOCUMENTOS_EXPORT +
            '/' + id +
            '?nvias=1' +
            '&segundavia=0' +
            '&api_key=' + Auth.getToken();
        }
      };
    });
})();
