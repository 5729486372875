(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('PaginasController', /*@ngInject*/  function ($scope, PaginasFactory, $state, $filter, PopAlert, Utils) {
      var ctrl = this;
      ctrl.updateFilter = updateFilter;
      ctrl.deletePagina = deletePagina;
      ctrl.refresh = refresh;
      ctrl.edit = edit;

      ctrl.dtformat = 'dd-MM-yyyy HH:mm:ss';
      ctrl.search = '';
      ctrl.grid = {
        list: [],
        config: {
          itemsPerPage: 10,
          maxPages: 10,
          fillLastPage: true,
          orderBy: 'AIOrderBy'
        }
      };

      ctrl.filtered = [];

      ctrl.refresh();

      function updateFilter() {
        ctrl.filtered = $filter('AIFilter')(ctrl.grid.list, ctrl.search);
      }

      function deletePagina(pagina) {
        PopAlert.confirm(Utils.translate('PAGINAS_MSG_ASK_DELETED'), undefined, function (e) {
          if (e) {
            PaginasFactory.deletePagina(pagina).then(
              function () {
                PopAlert.showInfo(Utils.translate('PAGINAS_MSG_DELETED'));
                ctrl.refresh();
              },
              function (err) {
                PopAlert.showError(Utils.translate(err));
              });
          }
        });
      }

      function refresh() {
        PaginasFactory.getAll().then(function (paginas) {
          ctrl.grid.list = paginas;
          ctrl.updateFilter();
        });
      }

      function edit(item) {
        $state.go('^.edit', {id: item.id});
      }
    })
    .controller('PaginasEditController', /*@ngInject*/  function ($scope, PaginasFactory, $state, $transition$, Utils, WidgetsFactory, PopAlert, $translate) {
      var ctrl = this;
      ctrl.apply = apply;
      ctrl.save = save;
      ctrl.deletePagina = deletePagina;
      ctrl.cancel = cancel;
      ctrl.load = load;
      ctrl.init = init;

      ctrl.widgests = WidgetsFactory;
      ctrl.componentes = ['carroucel', 'Texto'];
      ctrl.componentes1 = ['Promoções'];
      ctrl.dtformat = 'dd-MM-yyyy HH:mm:ss';
      ctrl.showOrder = true;
      ctrl.pagina = {
        id: 0,
        title: $translate.instant('admin.config.paginas.newPage'),
        description: '',
        publishedOn: new Date(),
        publishedAfter: new Date(),
        items: []
        //items:[{"name":"widget-carousel","description":"carousel widget"},
        //       {"name":"widget-produtos","description":"products widget"},
        //       {"name":"widget-html","description":"html widget"}]
      };

      ctrl.init();

      function apply() {
        PaginasFactory.save(ctrl.pagina).then(
          function (pagina) {
            ctrl.pagina = pagina;
            PopAlert.showInfo(Utils.translate('PAGINAS_MSG_SAVED'));
          },
          function (err) {
            PopAlert.showError(Utils.translate('PAGINAS_MSG_SAVE_ERROR'));
          });
      }

      function save() {
        PaginasFactory.save(ctrl.pagina).then(
          function () {
            PopAlert.showInfo(Utils.translate('PAGINAS_MSG_SAVED'));
            $state.go('^.list');
          },
          function (err) {
            PopAlert.showError(Utils.translate(err));
          });
      }

      function deletePagina() {
        PopAlert.confirm(Utils.translate('PAGINAS_MSG_ASK_DELETED'), function (e) {
          if (e) {
            PaginasFactory.deletePagina(ctrl.pagina).then(
              function () {
                PopAlert.showInfo(Utils.translate('PAGINAS_MSG_DELETED'));
                $state.go('^.list');
              },
              function (err) {
                PopAlert.showError(Utils.translate(err));
              });
          }
        });
      }

      function cancel() {
        $state.go('^.list');
      }

      function load(id) {
        PaginasFactory.load(id).then(
          function (pagina) {
            ctrl.pagina = pagina;
            return pagina;
          },
          function (err) {
            PopAlert.showError(Utils.translate(err));
            $state.go('^.list');
          }
        );
      }

      function init() {
        var id = $transition$.params().id;
        if (!Utils.isNullOrUndefined(id)) {
          ctrl.load(id);
        }
      }
    });
})();
