/**
 * Created by Pedro Lopes on 08/10/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('ProdutosFactory', function ($q, $http, REST_API) {
      return {
        getCatalogos: function () {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.PRODUTOS_CATALOGOS, {cache: true})
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              deferred.reject(err.data);
            });
          return deferred.promise;
        },
        getProdutosCatalogo: function (catalogoId) {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.PRODUTOS_CATALOGO + '/' + catalogoId, {cache: true})
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              deferred.reject(err.data);
            });
          return deferred.promise;
        },
        getAnexos: function (classificador, chave) {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.PRODUTO_ANEXOS + '/' + classificador + '/' + chave).then(
            function (response) {
              deferred.resolve(response);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        getAnexoUrl: function (id) {
          return REST_API.HOST + REST_API.PRODUTO_DOCUMENTO + '/' + id;
        }
      };
    });
}());
