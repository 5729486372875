/**
 * Created by Pedro Lopes on 08/10/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('emailService', /*@ngInject*/ function ($rootScope, $q, $http, REST_API, plCompsService) {
      return {
        sendContact: function (name, email, message) {
          var deferred = $q.defer();

          $http.post(REST_API.HOST + REST_API.EMAIL_CONTACT, {
            name: name,
            email: email,
            message: message
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (data) {
              deferred.reject(data.data);
            });

          return deferred.promise;
        },

        validatorEmailExists: function (oldEmail) {
          return {
            value: null,
            message: 'utilizador.errorEmailExists',
            validate: function (name, email) {
              var deferred = $q.defer();

              if (oldEmail && oldEmail === email) {
                deferred.resolve(true);
              }
              else {
                plCompsService.debounce(function () {
                  var url = REST_API.HOST + REST_API.USER_EMAIL_EXISTS + '/' + email;
                  $http.get(url).then(
                    function () {
                      // Invalid
                      deferred.resolve(false);
                    },
                    function () {
                      // Valid
                      deferred.resolve(true);
                    }
                  );
                }, 500, 'validatorEmailExists');
              }

              return deferred.promise;
            }
          };
        }
      };
    });
})();
