(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function (LightboxProvider) {
      LightboxProvider.templateUrl = 'app/components/lightbox/lightbox.html';
    })
    .component('lightbox', {
      bindings: {
        src: '<',
        caption: '@',
        items: '<?',
        index: '<?',
        properties: '<?'
      },
      template: '<img class="artimage-detalhe" fallback-src="{{$ctrl.storage.noimage}}" ng-src="{{$ctrl.src}}" ng-click="$ctrl.enlargeImage()">',
      controllerAs: '$ctrl',
      controller: function (storage, Lightbox) {
        var ctrl = this;

        ctrl.storage = storage;

        ctrl.enlargeImage = enlargeImage;

        /////////////

        function enlargeImage() {
          var items = ctrl.items || [{
            url: ctrl.src || ctrl.storage.noimage,
            caption: ctrl.caption || ''
          }];
          var index = 0;
          if (angular.isNumber(ctrl.index)) {
            index = ctrl.index;
          }
          var options = angular.extend({
            templateUrl: 'app/components/lightbox/lightbox.html'
          }, ctrl.properties);
          Lightbox.openModal(items, index, options);
        }
      }
    });
})();
