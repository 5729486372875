(function () {
  'use strict';

  angular
    .module('erpRetail', [
      'ngAnimate',
      'ngCookies',
      'ngLocale',
      'ngMessages',
      'ngResource',
      'ngSanitize',
      'ngStorage',
      'ngTouch',
      'angular-table',
      'angular.filter',
      'angularFileUpload',
      'angularUtils.directives.dirPagination',
      'angularValidator',
      'as.sortable',
      'bootstrapLightbox',
      'CookieComponent',
      'gridster',
      'jlareau.pnotify',
      'oc.lazyLoad',
      'pascalprecht.translate',
      'truncate',
      'ui.ace',
      'ui.bootstrap',
      'ui.cgdevtools',
      'ui.CGImageCache',
      'ui.router',
      'ui.sortable',
      'ui.tinymce',
      'ui.tree',
      'xeditable',
      'cgReservasEstadias',
      'pl.comps'
    ])
    .factory('customLanguageLoader', function ($http, $q, ModelosFactory) {
      return function (options) {
        var deferred = $q.defer();

        options.prefix = 'i18n/locale-';
        options.suffix = '.json';
        var arrayTranslations = [];
        var translations = {};

        $http.get(options.prefix + options.key + options.suffix)
          .then(function (result) {
            arrayTranslations.push(result.data);

            ModelosFactory.getTexto('FICHEIRO_TRADUCOES')
              .then(function (result) {
                // eslint-disable-next-line angular/json-functions
                var resultDataParsed = JSON.parse(result.html);
                if (resultDataParsed[options.key]) {
                  arrayTranslations.push(resultDataParsed[options.key]);
                }

                arrayTranslations.forEach(function (item) {
                  for (var key in item) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (item.hasOwnProperty(key)) {
                      translations[key] = item[key];
                    }
                  }
                });

                deferred.resolve(translations);
              });
          });
        return deferred.promise;
      };
    })
    .config(function ($translateProvider, plFormatProvider, KEYCODE, $stateProvider, $urlServiceProvider) {
      $translateProvider.useLoader('customLanguageLoader');
      // $translateProvider.useStaticFilesLoader({
      //   prefix: 'i18n/locale-',
      //   suffix: '.json'
      // });
      $translateProvider.preferredLanguage('pt-pt');
      // $translateProvider.useCookieStorage();
      $translateProvider.useLocalStorage();
      $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

      moment.locale('pt-pt');

      plFormatProvider.setFormat({
        decimalsSeparator: ',',
        decimalsSeparatorCode: KEYCODE.COMMA,
        decimalsLimit: 2,
        thousandsSeparator: '.'
      });

      $stateProvider
        .state('home', {
          url: '/',
          title: 'Home',
          abstract: true,
          templateUrl: 'app/index.html',
          controller: function (configSite) {
          },
          controllerAs: 'ctrl',
          resolve: {
            configSite: ['configFactory', function (configFactory) {
              return configFactory.getConfigSite();
            }]
          }
        });

      $urlServiceProvider.rules.initial({state: 'loja.home', params: {id: 'inicio'}});
      $urlServiceProvider.rules.otherwise({state: 'loja.home', params: {id: 'inicio'}});
    })
    .run(function ($rootScope, $window, $transitions, $state, $translate, Auth, editableOptions, CONFIG, configFactory, ENV, ImagensFactory,
                   $rootElement, $sce, $location, plTranslate, $uiRouterGlobals, $log, $q) {
      $rootScope.ENV = ENV;
      $rootScope.getDatetime = new Date();
      $rootScope.$config = {loaded: false, getTemplate: configFactory.getTemplate, metaTitle: null};
      $rootScope.app = {};
      $rootScope.getImagem = ImagensFactory.getImagem;
      $rootScope.lang = 'pt-pt';

      $rootScope.getLogo = getLogo;
      $rootScope.getLogoLogin = getLogoLogin;
      $rootScope.getFavicon = getFavicon;
      $rootScope.setCss = setCss;
      $rootScope.globalGoTo = globalGoTo;
      $rootScope.globalTranslate = globalTranslate;
      $rootScope.goBack = goBack;
      $rootScope.home = home;

      init();

      /////////////

      function init() {
        plTranslate.setTranslateFn($rootScope.globalTranslate);
        editableOptions.theme = 'bs3';

        configFactory.getConfigSite().then(function (data) {
          $rootScope.setCss(data.modelos.maincss);
        });

        $transitions.onStart({}, function ($transition) {
          var toState = $transition.$to();
          var toStateParams = $transition.params();
          return Auth.isLoggedInAsync(function (loggedIn) {
            var stateService = $transition.router.stateService;
            if ((toStateParams.authenticate === true || toStateParams.authenticate === 'admin') && loggedIn === false) {
              return stateService.target('login', {returnstate: toState.self.name, returnstateparams: toStateParams});
            }
            return configFactory.getConfigSite().then(function (response) {
                if (response.siteFixedTitle) {
                  $rootScope.$config.data.metaTitle = plTranslate.translate(response.siteFixedTitle);
                } else if (toState.self.title) {
                  // Set meta title
                  var pageTitle = toState.self.title.toString().trim() !== '' ? ' - ' + plTranslate.translate(toState.self.title) : '';
                  $rootScope.$config.data.metaTitle = $rootScope.$config.data.siteName + pageTitle;
                }

                // Trying to access sign up but it is disabled
                if (toState.self.name === 'signup' && parseInt(response.siteSignupEnabled) === 0) {
                  return stateService.target('login', {returnstate: toState.self.name, returnstateparams: toStateParams});
                }

                // Trying to access sign up but it is disabled
                if (toState.self.name !== 'user.recover_email' && toState.self.name !== 'user.confirm_token' &&
                  toState.self.name !== 'user.nova' && toState.self.name !== 'signup' && response.siteLojaRegisteredOnly && !Auth.isLoggedIn()) {
                  return stateService.target('login');
                }
              }
            );
          });
        });
      }

      function getLogo() {
        if ($rootScope.$config.loaded) {
          return ImagensFactory.getImagem($rootScope.$config.data.siteLogo, 'assets/images/online-store-logo.png');
        }
      }

      function getLogoLogin() {
        if ($rootScope.$config.loaded) {
          return ImagensFactory.getImagem($rootScope.$config.data.loginLogo, 'assets/images/online-store-logo.png');
        }
      }

      function getFavicon() {
        if ($rootScope.$config.loaded) {
          return $sce.trustAsResourceUrl(ImagensFactory.getImagem($rootScope.$config.data.sitefavicon, 'assets/images/favicon.ico'));
        }
      }

      function setCss(css) {
        if (angular.isUndefined($rootScope.css)) {
          $rootScope.css = angular.element('<style>');
          $rootElement.append($rootScope.css);
        }
        $rootScope.css.text('\n' + css + '\n');
      }

      function globalGoTo(name, params, options) {
        $state.go(name, params, options);
      }

      function globalTranslate(key, params, config) {
        return $translate.instant(key, params, config);
      }

      function goBack() {
        var lastSuccessfulTransition = $uiRouterGlobals.successfulTransitions.peekTail();
        var transitionPromise;
        if (lastSuccessfulTransition) {
          var fromState = lastSuccessfulTransition.from();
          if (fromState.name) {
            transitionPromise = $q(function (resolve, reject) {
              $state.go(fromState, lastSuccessfulTransition.params('from')).then(resolve, function (reason) {
                $log.error(reason);
                return $rootScope.home().then(resolve, reject);
              });
            });
          }
        }
        if (!transitionPromise) {
          transitionPromise = $rootScope.home();
        }
        return transitionPromise;
      }

      function home() {
        return $state.go('loja.home', {id: 'inicio'});
      }
    });
})();
