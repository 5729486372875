/**
 * Created by pedro on 20/04/2017.
 */

(function () {
  'use strict';

  angular.module('erpRetail')
    .service('plStorage', function ($location, $log, $sessionStorage) {
      var location = function (pre) {
        pre = pre ? pre + '.' : '';
        var obj = {
          set: function (name, value) {
            if (angular.isObject(name)) {
              obj.setObject(name);
              return;
            }
            if (angular.isArray(value)) {
              var v = '';
              value.forEach(function (item) {
                if (v !== '') v += ';';
                v += item;
              });
              if (v !== '') $location.search(pre + name, v);
              return;
            }
            if (angular.isObject(value)) {
              for (var propertyName in value) {
                if (Object.prototype.hasOwnProperty.call(value, propertyName)) {
                  obj.set(name + '.' + propertyName, value[propertyName]);
                }
              }
              return;
            }
            return $location.search(pre + name, value);
          },
          setObject: function (myobj) {
            for (var propertyName in myobj) {
              if (Object.prototype.hasOwnProperty.call(myobj, propertyName)) {
                obj.set(propertyName, myobj[propertyName]);
              }
            }
          },
          getArray: function (name, myobj) {
            return [];
          },
          getObject: function (name, myobj) {
            for (var propertyName in myobj) {
              if (Object.prototype.hasOwnProperty.call(myobj, propertyName)) {
                var aux = myobj[propertyName];
                myobj[propertyName] = obj.get(name + '.' + propertyName, aux);
              }
            }
            return myobj;
          },
          get: function (name, myobj) {
            if (angular.isArray(myobj)) {
              return obj.getArray(name, myobj);
            } else if (angular.isObject(myobj)) {
              return obj.getObject(name, myobj);
            } else
              return $location.search()[pre + name];
          }
        };
        return obj;
      };
      var ss = function (pre) {
        pre = pre ? pre + '.' : '';
        var s = $sessionStorage.$default({});
        if (!s[pre]) s[pre] = {};
        s = s[pre];
        return {
          get: function (name, obj) {
            return s[name] || obj;
          },
          set: function (name, obj) {
            s[name] = obj;
          }
        };
      };

      return function (scope) {
        if (scope === 'query') {
          return location;
        }
        return ss;
      };
    });
}());
