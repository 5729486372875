(function () {
  'use strict';

  angular.module('erpRetail')
    .component('widgetsEdit', {
      bindings: {
        items: '=',
        order: '='
      },
      templateUrl: 'app/components/widgets/widgets.edit.html',
      controller: /*@ngInject*/ function ($uibModal, WidgetsFactory) {
        var ctrl = this;

        ctrl.list = WidgetsFactory.list;
        ctrl.sortOptions = {
          accept: function (sourceItemHandleScope, destSortableScope) {
            return false;
          },
          itemMoved: function (event) {
            //$log.debug(event);
          },
          orderChanged: function (event) {
            //$log.debug(event);
          },
          containment: '#board'
        };

        ctrl.add = add;
        ctrl.delete = fnDelete;
        ctrl.edit = edit;

        /////////////

        function add(item) {
          var aux = angular.copy(item);
          aux.data = {};
          ctrl.items.push(aux);
        }

        function fnDelete(item) {
          var idx = ctrl.items.indexOf(item);
          ctrl.items.splice(idx, 1);
        }

        function edit(item) {
          var modalInstance = $uibModal.open({
            size: 'lg',
            templateUrl: 'app/components/widgets/widget.edit.html',
            controllerAs: '$ctrl',
            controller: 'widgetEditController',
            resolve: {
              item: function () {
                return item;
              }
            }
          });

          modalInstance.result.then(
            function (itemSave) {
              var index = ctrl.items.indexOf(itemSave);
              if (index !== -1) {
                ctrl.items[index] = itemSave;
              }
            },
            function (itemRevert) {
              var index = ctrl.items.indexOf(itemRevert);
              if (index !== -1) {
                ctrl.items[index] = itemRevert;
              }
            }
          );
        }
      }
    });
})();
