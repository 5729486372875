/**
 * Created by Pedro Lopes on 29/07/15.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .config(function ($stateProvider) {
      $stateProvider
        .state('admin.config', {
          url: '/config',
          title: 'Configuração',
          params: {authenticate: 'admin'},
          templateUrl: 'app/admin/config/config.html',
          controllerAs: '$ctrl',
          controller: 'ConfigController'
        });
    });
})();
