/**
 * Created by Luis on 09/02/2015.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('MenuService', function (Utils, $log, $state) {
      var that = this;

      this.menuHome = {id: 0, state: 'loja.home', active: 'loja.home', title: 'Home'};
      this.menuLoja = {id: -1, state: 'loja.produtos', active: 'loja.produtos', title: 'Loja'};
      this.menuCarrinho = {id: -2, state: 'loja.carrinho', active: 'loja.carrinho', title: 'Carrinho'};
      this.menuContatos = {id: -3, state: 'loja.contatos', active: 'loja.contatos', title: 'Contactos'};
      this.menuAdmin = {id: -4, state: 'admin.config', active: 'admin', title: 'Admin'};
      this.menuLogin = {id: -5, state: 'login', active: 'login', title: 'Autenticação'};
      this.menuUser = {id: -6, state: 'user', active: 'user', title: 'Utilizador'};
      this.menuUserPerfil = {id: -7, state: 'loja.perfil.mostra', active: 'loja.perfil.mostra', title: 'Perfil'};
      this.menuUserDocs = {id: -8, state: 'loja.documentos', active: 'loja.documentos', title: 'Documentos'};
      this.menuEncomendas = {id: -10, state: 'loja.encomendas', active: 'loja.encomendas', title: 'Encomendas'};
      this.menuFaturas = {id: -11, state: 'loja.faturas', active: 'loja.faturas', title: 'Faturas'};
      this.menuMovimentosAbertos = {id: -12, state: 'loja.movimentosAberto', active: 'loja.movimentosAberto', title: 'Movimentos em Aberto'};
      this.menuUserSair = {id: -9, state: 'logout', active: '', title: 'Sair'};
      this.menuClassificadores = {id: -13, state: 'loja.produtos', active: 'loja.produtos', title: 'Classificadores'};

      this.menuSystem = [];

      this.hasNodes = function (item) {
        return !Utils.isNullOrUndefined(item) && !Utils.isNullOrUndefined(item.nodes) && item.nodes.length > 0;
      };

      this.getMenu = function (id) {

        var aux = parseInt(id);
        if ((aux > 0) || isNaN(aux)) {
          return {id: id, state: 'loja.home', active: 'loja.home', title: ''};
        }
        for (var i = 0; i < that.menuSystem.length; i++) {
          if (that.menuSystem[i].id === id)
            return that.menuSystem[i];
        }
        return that.menuHome;
      };

      this.getState = function (id) {
        var aux = parseInt(id);
        if ((aux >= 0) || isNaN(aux)) {
          return 'loja.home({id: \'' + id + '\'})';
        }

        for (var i = 0; i < that.menuSystem.length; i++) {
          if (that.menuSystem[i].id === id)
            return that.menuSystem[i].state;
        }
        return that.menuHome.state;
      };

      this.go = function (item) {
        var aux = parseInt(item.pageId);
        if ((aux > 0) || isNaN(aux)) {
          if (Utils.isNullOrUndefined(item.url)) {
            $state.go(that.getState(item.pageId));
          }
          else {
            $state.go('loja.home', {id: item.url});
          }
        }
        else {
          var id = parseInt(item.pageId);
          if (id) {
            item.pageId = id;
          }
          $state.go(that.getState(item.pageId));
        }
      };

      this.getUrl = function (item) {
        var url = '';
        var aux = parseInt(item.pageId);
        if (aux > 0 || isNaN(aux)) {
          if (Utils.isNullOrUndefined(item.url)) {
            url = that.getState(item.pageId);
          }
          else {
            url = 'loja.home({id: \'' + item.url + '\'})';
          }
        }
        else {
          url = that.getState(item.pageId);
        }
        return url;
      };

      this.refresh = function () {
        that.menuSystem = [];
        that.menuSystem.push(that.menuHome);
        that.menuSystem.push(that.menuLoja);
        that.menuSystem.push(that.menuCarrinho);
        that.menuSystem.push(that.menuContatos);
        that.menuSystem.push(that.menuAdmin);
        that.menuSystem.push(that.menuLogin);
        that.menuSystem.push(that.menuUser);
        that.menuSystem.push(that.menuUserPerfil);
        that.menuSystem.push(that.menuUserDocs);
        that.menuSystem.push(that.menuEncomendas);
        that.menuSystem.push(that.menuFaturas);
        that.menuSystem.push(that.menuMovimentosAbertos);
        that.menuSystem.push(that.menuUserSair);
        that.menuSystem.push(that.menuClassificadores);
        return that.menuSystem;
      };

      this.getMenuSystem = function () {
        return that.menuSystem;
      };

      this.refresh();

      return this;
    });
}());
