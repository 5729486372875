/**
 * Created by Pedro Lopes on 08/10/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('documentosService', function ($resource, $q, $http, REST_API, apiService) {
      return {
        getAll: function () {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.DOCUMENTOS_ALL).then(
            function (data) {
              deferred.resolve(data.data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        getCliente: function (search, page, perpage, order) {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.DOCUMENTOS_CLIENTE, {params: apiService.buildQuery(search, page, perpage, order)}).then(
            function (response) {
              var obj = {
                list: response.data.data,
                total: response.data.page.records
              };
              deferred.resolve(obj);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        getComerciais: function () {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.DOCUMENTOS_COMERCIAIS).then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        getEncomendas: function () {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.DOCUMENTOS_ENCOMENDAS).then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        getMovimentos: function () {
          var deferred = $q.defer();
          $http.get(REST_API.HOST + REST_API.DOCUMENTOS_MOVIMENTOS).then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        getStatus: function (status) {
          switch (status) {
            case 'ssNaoSincronizado':
              return 'Não sincronizado';
            case 'ssSincronizado':
              return 'Sincronizado';
            case 'ssComErroBackOffice':
              return 'Erro no BackOffice';
            case 'ssProcessadoBackOffice':
              return 'Processado no BackOffice';
            default:
              return status;
          }
        }
      };
    });
}());
