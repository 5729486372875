(function () {
  'use strict';

  angular.module('erpRetail')

    .controller('SincronizacaoController', ['$scope', '$http', '$interval', 'Utils', 'REST_API', 'PopAlert', function ($scope, $http, $interval, Utils, REST_API, PopAlert) {

      var stopTime;

      $scope.syncInitialState = {
        count: 0,
        state: false,
        info: null,
        tableTotal: 0,
        tableRegistos: 0,
        step: 0,
        stepMax: 0
      };


      $scope.syncState = $scope.syncInitialState;
      updateTime();

      $scope.sincroniza = function () {
        if (angular.isDefined(stopTime) || $scope.syncState === true) {
          PopAlert.showSuccess(Utils.translate('SYNC_ERROR_IS_RUNNING'));
          return;
        }

        startTimer();
        $http.get(REST_API.HOST + REST_API.ADMIN_SYNC_ALL)
          .then(function (data) {

            //$scope.estado="Sincronização concluida";
            //stopTimer();
          }, function (data, status, headers, config) {
            $scope.estado = 'SYNC_ERROR_STATE';
            stopTimer();
          });
      };


      $scope.sincronizaImagens = function (all) {
        if (angular.isDefined(stopTime) || $scope.syncState === true) {
          PopAlert.showSuccess('SYNC_ERROR_IS_RUNNING');
          return;
        }
        startTimer();
        var url = REST_API.HOST + REST_API.ADMIN_SYNC_IMAGES;
        if (all) url = REST_API.HOST + REST_API.ADMIN_SYNC_IMAGES_ALL;
        $http.get(url)
          .then(function (data) {

            //$scope.estado="Sincronização concluida";
            //stopTimer();
          }, function (data, status, headers, config) {
            $scope.estado = 'SYNC_ERROR_STATE';
            stopTimer();
          });
      };

      $scope.sincronizaTudoCompleto = function () {
        if (angular.isDefined(stopTime) || $scope.syncState === true) {
          PopAlert.showSuccess('SYNC_ERROR_IS_RUNNING');
          return;
        }
        startTimer();
        var url = REST_API.HOST + REST_API.ADMIN_SYNC_ALL_COMPLETE;
        $http.get(url)
          .then(function (data) {

            //$scope.estado="Sincronização concluida";
            //stopTimer();
          }, function (data, status, headers, config) {
            $scope.estado = 'SYNC_ERROR_STATE';
            stopTimer();
          });
      };


      $scope.estado = '';

      function startTimer() {
        if (angular.isUndefined(stopTime)) {
          stopTime = $interval(updateTime, 500);
        }
        $scope.syncState = $scope.syncInitialState;
        $scope.estado='SYNC_ERROR_START';
      }

      function stopTimer() {
        if (angular.isDefined(stopTime)) {
          $interval.cancel(stopTime);
          stopTime = undefined;
        }
        $scope.syncState.state = false;
      }

      function updateTime() {
        
        $http.get(REST_API.HOST + REST_API.ADMIN_SYNC_STATUS)
          .then(function (data) {

            $scope.syncState = angular.copy(data.data);
            $scope.estado = data.data;
            if (data.data.count === 0) $scope.estado = data.data.info;
            if (data.data.state === false) {
              if (angular.isDefined(stopTime)) {
                $scope.estado = data.data.info;
              }
              stopTimer();
            } else
              startTimer();
          }, function (data, status, headers, config) {
            $scope.estado = 'SYNC_ERROR_STATE_LOST_LOG';
            stopTimer();
          });
      }

      $scope.$on('$destroy', function () {
        // Make sure that the interval nis destroyed too
        stopTimer();
      });

    }]);
}());
