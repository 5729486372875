(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('authInterceptor', function ($rootScope, $q, $cookies, $location, $log) {
      return {
        // Add authorization token to headers
        request: function (config) {
          config.headers = config.headers || {};
          var token = $cookies.get('token');
          if (token) {
            config.headers.authToken = token;
          }
          return config;
        },
        // Intercept 401s and redirect you to login
        responseError: function (response) {
          if (response.status === 401 || response.status === 403) {
            // remove any stale tokens
            $cookies.remove('token');
            $location.path('/login');
            return $q.reject(response);
          }
          if (response.status === 0) {
            return $q.reject(response);
          }
          else {
            return $q.reject(response);
          }
        }
      };
    });
})();
