(function () {
  'use strict';
  angular.module('erpRetail')
    .config(/*@ngInject*/ function ($stateProvider) {
      $stateProvider
        .state('admin.utilizadores', {
          url: '/utilizadores',
          abstract: true,
          params: {authenticate: {value: 'admin'}},
          title: 'utilizador.states.users',
          templateUrl: 'app/admin/utilizadores/utilizadores.html'
        })
        .state('admin.utilizadores.list', {
          url: '/list',
          title: 'utilizador.states.usersList',
          params: {authenticate: {value: 'admin'}},
          templateUrl: 'app/admin/utilizadores/utilizadores.list.html',
          controller: 'UtilizadoresListController',
          controllerAs: 'ctrl'
        })
        .state('admin.utilizadores.new', {
          url: '/novo',
          title: 'utilizador.states.newUser',
          params: {
            authenticate: {value: 'admin'},
            type: {value: 'new'},
            isEdit: {value: false}
          },
          templateUrl: 'app/admin/utilizadores/utilizadores.edit.html',
          controller: 'UtilizadoresEditController',
          controllerAs: 'ctrl',
          resolve: {
            item: function () {
              return null;
            },
            translatePartialLoader: /*@ngInject*/ function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('utilizador.perfil');
              return $translate.refresh();
            }
          }
        })
        .state('admin.utilizadores.edit', {
          url: '/edit/:id',
          title: 'utilizador.states.editUser',
          params: {
            authenticate: {value: 'admin'},
            type: {value: 'edit'},
            isEdit: {value: true}
          },
          templateUrl: 'app/admin/utilizadores/utilizadores.edit.html',
          controller: 'UtilizadoresEditController',
          controllerAs: 'ctrl',
          resolve: {
            item: /*@ngInject*/ function ($transition$, User) {
              return User.getUser($transition$.params().id);
            }
          }
        });
    });
}());



