(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('User', function ($q, $http, REST_API, $log) {
      return {
        get: function () {
          var deferred = $q.defer();

          $http.get(REST_API.HOST + REST_API.USER_ME)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              $log.debug(err);
              deferred.reject(err.data);
            });
          return deferred.promise;
        },
        getUser: function (id) {
          var deferred = $q.defer();

          $http.get(REST_API.HOST + REST_API.USER + '/' + id)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              $log.debug(err.data);
              deferred.reject(err.data);
            });
          return deferred.promise;
        },
        saveUser: function (id, user) {
          var deferred = $q.defer();

          $http.post(REST_API.HOST + REST_API.USER + '/' + id, user)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              $log.debug(err.data);
              deferred.reject(err.data);
            });
          return deferred.promise;
        },
        createUser: function (user) {
          var deferred = $q.defer();
          $http.post(REST_API.HOST + REST_API.USER_ME, user)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              $log.debug(err.data);
              deferred.reject(err.data);
            });
          return deferred.promise;
        },
        getAll: function () {
          var deferred = $q.defer();

          $http.get(REST_API.HOST + REST_API.USER_ALL)
            .then(function (data) {
              deferred.resolve(data.data);
            }, function (err) {
              $log.debug(err.data);
              deferred.reject(err.data);
            });
          return deferred.promise;
        },
        mudarPassword: function (id, password) {
          var deferred = $q.defer();
          $http.post(REST_API.HOST + REST_API.USER + '/' + id + REST_API.USER_MUDAR_PASSWORD, {password: password})
            .then(
              function (response) {
                deferred.resolve(response.data);
              },
              function (error) {
                deferred.reject(error.data);
              }
            );
          return deferred.promise;
        }
      };
    });
}());
