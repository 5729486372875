(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('WidgetsFactory', function ($log, Utils) {
      var widgets = [];
      var factory = {
        run: run,
        register: register,
        log: log,
        getWidget: getWidget,
        getEdit: getEdit
      };

      factory.run();

      return factory;

      /////////////

      function run() {
        Object.defineProperty(factory, 'list', {
          get: function () {
            return widgets;
          }
        });
      }

      function register(name, edit, description) {
        widgets.push({name: name, edit: edit, description: description});
      }

      function log(msg) {
        $log.log(msg);
      }

      function getWidget(name) {
        for (var i = 0; i < widgets.length; i++) {
          if (Utils.sameText(widgets[i].name, name)) {
            return widgets[i];
          }
        }
        return null;
      }

      function getEdit(name) {
        var aux = factory.getWidget(name);
        if (aux === null) {
          return null;
        }
        return aux.edit;
      }
    });
})();
