(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('cgexception', function () {
      var fatalMessages = [
        'FireDAC',
        'Access violation',
        'Invalid pointer operation'
      ];

      var factory = {
        isFatal: isFatal,
        get: get
      };
      return factory;

      /////////////

      function isFatal(exception) {
        for (var i = 0; i < fatalMessages.length; i++) {
          var fatalMessage = fatalMessages[i];
          if (exception.message.indexOf(fatalMessage) !== -1) {
            return true;
          }
        }
        return false;
      }

      function get(data) {
        var error = {
          class: 'unknown',
          message: 'error #' + data.status,
          fields: []
        };
        var exception;
        if (data) {
          exception = data.headers('exception');
        }
        if (exception) {
          exception = angular.fromJson(exception);
          error = {
            class: exception.exception.class,
            message: exception.exception.message,
            status: exception.exception.status,
            fields: []
          };
        }
        else {
          exception = {
            exception: {
              class: 'unknown',
              message: 'error #' + data.status,
              fields: []
            }
          };
          error = exception.exception;
        }

        if (data.status === 400 || data.status === 404 || (data.status === 500 && !factory.isFatal(exception.exception))) {
          if (exception && exception.exception && exception.exception.fields) {
            for (var i = 0; i < exception.exception.fields.length; i++) {
              error.fields.push(exception.exception.fields[i]);
            }
          }
          else {
            error.message = exception.exception.message;
            error.class = exception.exception.class;
          }
          return error;
        }
        else {
          return error;
        }
      }
    });
})();
