(function () {
  'use strict';


  function menuDrawController($scope, $rootScope, $state, $q, $timeout, Auth, MenuService, configFactory, $translate, $window, ImagensFactory) {
    var ctrl = this;

    ctrl.ms = MenuService;

    ctrl.classificadoresList = configFactory.classificadoresList;
    ctrl.classificadores = configFactory.classificadores;

    ctrl.lang = $translate.preferredLanguage();
    if ($window.localStorage.getItem('lan') !== null) {
      ctrl.lang = $window.localStorage.getItem('lan');
    }

    $scope.changeLanguage = function (key) {
      $rootScope.lang = key;
      $translate.use(key);
      ctrl.lang = key;
      $window.localStorage.setItem('lan', key);
    };

    ctrl.getMenuActiveClass = getMenuActiveClass;
    ctrl.menuCaption = menuCaption;
    ctrl.menuHide = menuHide;
    ctrl.menuBuild = menuBuild;
    ctrl.menuClick = menuClick;
    ctrl.menuClickEvent = menuClickEvent;
    ctrl.menuClickEventLojaClassificadores = menuClickEventLojaClassificadores;
    ctrl.refreshUser = refreshUser;
    ctrl.run = run;
    ctrl.getImagem = ImagensFactory.getImagem;

    ctrl.menuBuild();

/////////////

    function getMenuActiveClass(item) {
      var s = MenuService.getMenu(item.pageId);
      if (s.state === 'loja.home') {
        return (s.id === $state.params.id) ? 'active' : '';
      }
      if (s.state === 'logout') {
        return false;
      }
      return $state.includes(s.active) ? 'active' : '';
    }

    function menuCaption(item) {
      var s = MenuService.getMenu(item.pageId);
      if (s.id === MenuService.menuUser.id) {
        return Auth.getCurrentUser().name;
      }
      return item.title;

    }

    function menuHide(item) {
      var s = MenuService.getMenu(item.pageId);
      if (s.id === MenuService.menuUser.id) {
        return !Auth.isLoggedIn();
      }
      if (s.id === MenuService.menuLogin.id) {
        return Auth.isLoggedIn();
      }
      if (s.id === MenuService.menuAdmin.id) {
        return !Auth.isAdmin();
      }
    }

    function menuBuild() {
      configFactory.getMenuBuild().then(function (data) {
        ctrl.menuProdutos = data;
      });
    }

    function menuClick(item1) {

      ctrl.currentState = $state.current.name;
      if (ctrl.currentState === 'loja.produtos') {
        ctrl.menuClickEvent(item1);
      } else if (ctrl.currentState === 'loja.classificadores') {
        ctrl.menuClickEventLojaClassificadores(item1);
      } else {

        if (item1.classificadorTipo) {
          $state.go('loja.classificadores',
            {
              classificadorTipo: item1.classificadorTipo,
              id: item1.id
            });
        } else {
          $state.go('loja.produtos', {paramMenu: item1});

        }

      }
    }

    function menuClickEvent(item1) {
      $rootScope.$broadcast('menu-produtos', item1);
    }

    function menuClickEventLojaClassificadores(item1) {
      $rootScope.$broadcast('menu-produtos-classificadores', item1);
    }

    function refreshUser() {
      ctrl.$apply();
    }

    function run() {
      $scope.$on('event-login', function (event, args) {
        ctrl.refreshUser();
      });

      $scope.$on('event-user', function (event, args) {
        ctrl.refreshUser();
      });

      $scope.$on('event-logout', function (event, args) {
        ctrl.refreshUser();
      });
    }
  }

  function siteMenu() {
    return {
      restrict: 'E',
      scope: {
        logo: '=logo',
        menu: '=menu'
      },
      bindToController: true,
      templateUrl: 'menu',
      // templateUrl: 'app/components/directives/menu/menu.html',
      controller: menuDrawController,
      controllerAs: 'ctrl'
    };
  }

  function siteSubMenu() {
    return {
      restrict: 'EA',
      scope: {
        menu: '=menu'
      },
      bindToController: true,
      templateUrl: 'app/components/directives/menu/menu.submenu.html',
      controller: menuDrawController,
      controllerAs: 'ctrl'
    };
  }

  function siteCarrinhoSubMenu() {
    return {
      restrict: 'EA',
      scope: {
        menu: '=menu'
      },
      bindToController: true,
      templateUrl: 'carrinho.submenu', //template
      // templateUrl: 'app/components/directives/menu/menu.submenu.carrinho.html',
      controller: menuDrawController,
      controllerAs: 'ctrl'
    };
  }

  function siteClassificadoresSubMenu() {
    return {
      restrict: 'EA',
      scope: {
        menu: '=menu'
      },
      bindToController: true,
      templateUrl: 'app/components/directives/menu/menu.submenu.classificadores.html',
      controller: menuDrawController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('erpRetail')
    .directive('siteMenu', siteMenu)
    .directive('siteSubMenu', siteSubMenu)
    .directive('siteCarrinhoSubMenu', siteCarrinhoSubMenu)
    .directive('siteClassificadoresSubMenu', siteClassificadoresSubMenu);
})
();
