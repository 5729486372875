(function () {
  'use strict';
  angular.module('erpRetail')
    .run(['WidgetsFactory', function (WidgetsFactory) {
      WidgetsFactory.register('widget-tabs', 'widget-tabs-edit', 'Tabs');
    }])

    .directive('widgetTabs', function () {

      var controller = function ($scope, configFactory, WidgetsFactory, ImagensFactory) {

        $scope.refresh = function () {
          WidgetsFactory.log('widgestTabs refresh');
        };

        $scope.getImagem = ImagensFactory.getImagem;

      };

      function link(scope, element, attrs, $compile) {
        //scope.$watch('id', function () {
        //    scope.refresh();
        //});

      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlTabs/html.tabs.html',
        link: link,
        controller: controller
      };

    })

    .directive('widgetTabsEdit', function () {

      function controller($scope, WidgetsFactory, Utils, imagePick, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;

        $scope.refresh = function () {
          WidgetsFactory.log('widgestTabs refresh');
        };
        $scope.addItem = function () {
          $scope.item.data.tab.items.push({titulo: '', text: '', image: ''});
        };
        $scope.removeItem = function (item) {
          var index = $scope.item.data.tab.items.indexOf(item);
          if (index === -1) return;
          $scope.item.data.tab.items.splice(index, 1);
        };
        $scope.addImage = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.image = folder + img;
          });
        };

        if (Utils.isNullOrUndefined($scope.item.data.tab)) {
          $scope.item.data = {
            tab: {
              items: []
            }
          };
        }

      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlTabs/html.tabs.edit.html',
        controller: controller
      };

    });
}());

