(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('ConfigVariaveisController', function ($http, $state, $uibModal, configFactory, REST_API, Utils, $filter, PopAlert) {
      var ctrl = this;

      ctrl.config = {
        itemsPerPage: 10,
        maxPages: 10,
        fillLastPage: true
      };

      ctrl.configlist = [];
      ctrl.configlistFiltered = [];
      ctrl.searchConfig = null;

      $http.get(REST_API.HOST + REST_API.ADMIN_CONFIG_ALL).then(
        function (data) {
          ctrl.configlist = data.data;
          ctrl.updateFilteredList();
        }
      );

      ctrl.updateFilteredList = function () {
        if (Utils.isNullOrEmpty(ctrl.searchConfig)) {
          ctrl.configlistFiltered = ctrl.configlist;
        } else {
          ctrl.configlistFiltered = $filter('filter')(ctrl.configlist, ctrl.searchConfig);
        }
      };

      ctrl.save = function (value, item) {
        if (!item) {
          PopAlert.showError('admin.errors.invalidValue');
          return;
        }
        configFactory.saveValue(item, function (ok) {
          if (ok) {
            PopAlert.showSuccess('admin.success.valueUpdate');
          }
          else {
            PopAlert.showError('admin.errors.valueUpdate');
          }
        });
      };
    });
})();
