/* jshint ignore:start */
 (function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('erpRetail')
        .constant('VERSION', "1.0.0.0")
        .constant('ENV', "PROD")
        .constant('API_URL', "")
;
})();
/* jshint ignore:end */
