(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('paisesService', /*@ngInject*/ function ($resource, $q, $http, REST_API) {
      return {
        getPaises: function () {
          var deferred = $q.defer();

          $http.get(REST_API.HOST + REST_API.PAISES)
            .then(function (data) {
              deferred.resolve(data.data);
            });
          return deferred.promise;
        }
      };
    });
})();
