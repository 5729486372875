(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('TransportadorasFactory', ['$q', '$http', 'REST_API', '$log', '$state',
      function ($q, $http, REST_API, $log, $state) {
        return {
          moradasAlternativas: function (codTransportadora) {
            var deferred = $q.defer();
            $http.get(REST_API.HOST + REST_API.TRANSPORTADORAS+'/'+codTransportadora+'/moradasalternativas')
              .then(function (data) {
                deferred.resolve(data.data);
              }, function (err) {
                $log.debug(err.data);
                deferred.reject(err.data);
              });
            return deferred.promise;
          },
          all: function () {
            var deferred = $q.defer();
            $http.get(REST_API.HOST + REST_API.TRANSPORTADORAS+'/all')
              .then(function (data) {
                deferred.resolve(data.data);
              }, function (err) {
                $log.debug(err.data);
                deferred.reject(err.data);
              });
            return deferred.promise;
          }
        };
      }]);
}());
