(function () {
  'use strict';

  angular.module('erpRetail')
    .config(/*@ngInject*/ function ($stateProvider) {
      $stateProvider
        .state('loja.produtos', {
          abstract: false,
          url: '/produtos',
          title: 'produtos.states.produtos',
          templateUrl: 'app/loja/produtos/produtos.html',
          resolve: {
            config: /*@ngInject*/ function (configFactory) {
              return configFactory.getConfigSite();
            }
          },
          params: {
            paramMenu: {}
          }
        })
        .state('loja.produto', {
          url: '/produto/:nartigo',
          title: 'produtos.states.loja',
          templateUrl: 'app/loja/produtos/produtos.detalhe.html',
          resolve: {
            config: /*@ngInject*/ function (configFactory) {
              return configFactory.getConfigSite();
            },
            produto: function (storage, $transition$) {
              return storage.getArtigo($transition$.params().nartigo);
            }
          },
          controller: /*@ngInject*/ function ($scope, $transition$, storage, configFactory, config, produto, Auth, clienteService) {
            $scope.item = produto;
            $scope.storage = storage;
            $scope.classificadores = configFactory.classificadores;
            $scope.config = config;

            $scope.cliente = {};
            $scope.user = Auth.getCurrentUser();

            if (angular.isDefined($scope.user.token)) {
              $scope.getUserInfo = function () {
                clienteService.getCliente().then(function (cliente) {
                  $scope.cliente = cliente;
                });
              };
              $scope.getUserInfo();
            }

            $scope.getFlatImagens = function (imagens) {
              return imagens.map(function (imagem) {
                return storage.imagePath + imagem.docId;
              });
            };
          }
        })
        .state('loja.classificadores', {
        abstract: false,
        url: '/produtosc',
        title: 'produtos.states.produtos',
        templateUrl: 'app/loja/produtos/produtos.classificadores.html',
        resolve: {
          config: /*@ngInject*/ function (configFactory) {
            return configFactory.getConfigSite();
          }
        },
          params:{
            classificadorTipo: '',
            id:0
          }
        })
        .state('loja.classificador', {
          url: '/classificador/:tabela/:nchave/:ordem',
          title: 'produtos.states.classificador',
          templateUrl: 'app/loja/produtos/produtos.classificador.detalhe.html',
          resolve: {
            config: ['configFactory', function (configFactory) {
              return configFactory.getConfigSite();
            }],
            classificador: function (storage, $transition$) {
              return storage.getTextoClassificador($transition$.params().tabela, $transition$.params().nchave, $transition$.params().ordem);
            }
          },
          params: {
            clearSearch: {
              type: 'bool',
              value: false
            }
          },
          controller: /*@ngInject*/ function ($scope, $transition$, storage, configFactory, config, classificador, ProdutosFactory) {
            $scope.classificador = classificador;
            $scope.storage = storage;
            $scope.classificadores = configFactory.classificadores;
            $scope.config = config;
            $scope.anexos = [];
            $scope.showAnexos = false;
            $scope.getFileUrl = ProdutosFactory.getAnexoUrl;
            $scope.getFlatImagens = function (imagens) {
              return imagens.map(function (imagem) {
                return storage.imagePath + imagem.docId;
              });
            };

            ProdutosFactory.getAnexos($transition$.params().tabela, $transition$.params().nchave).then(
              function (response) {
                if (response.data.length) {
                  angular.forEach(response.data, function (ficheiro) {
                    if (angular.isUndefined(ficheiro.dodGDocClassifica) || ficheiro.dodGDocClassifica === null) {
                      return;
                    }
                    if (!$scope.anexos[ficheiro.dodGDocClassifica]) {
                      $scope.anexos[ficheiro.dodGDocClassifica] = {
                        classificador: ficheiro.dodGDocClassifica,
                        descricao: ficheiro.descricaoClassificador,
                        files: []
                      };
                    }
                    $scope.anexos[ficheiro.dodGDocClassifica].files.push({
                      id: ficheiro.docId,
                      folderId: ficheiro.folderId,
                      nome: ficheiro.nomeFicheiro,
                      deleted: ficheiro.deleted
                    });
                  });
                  $scope.showAnexos = true;
                }
              },
              function () {
                $scope.showAnexos = false;
              }
            );
          }
        });
    });
})();
