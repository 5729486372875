(function () {
    'use strict';

    angular.module('erpRetail')
      .controller('Erro404Controller', ['$rootScope', '$scope', '$state', '$window', 'Utils',
            function ($rootScope, $scope, $state, $window, Utils) {

                $scope.goBack = function () {
                    if (Utils.isNullOrUndefined($rootScope.lastState)) {
                        $state.go($rootScope.lastState.name);
                    } else {
                        $window.history.back();
                    }
                };

            }]);
}());
