(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function (WidgetsFactory) {
      WidgetsFactory.register('widget-img-gallery', 'widget-img-gallery-edit', 'Galeria');
    })
    .component('widgetImgGallery', {
      bindings: {
        item: '='
      },
      templateUrl: 'app/components/widgets/imgGallery/imgGallery.html',
      controllerAs: '$ctrl',
      controller: /*@ngInject*/ function ($scope, WidgetsFactory) {
        var ctrl = this;

        ctrl.images = [];

        ctrl.$onInit = init;
        ctrl.refresh = refresh;

        /////////////

        function init() {
          ctrl.images = [{url: '', thumbUrl: '', caption: ''}];
        }

        function refresh() {
          WidgetsFactory.log('widgestGaleria refresh');
        }
      }
    })
    .component('widgetImgGalleryEdit', {
      bindings: {
        item: '='
      },
      templateUrl: 'app/components/widgets/imgGallery/imgGallery.edit.html',
      controllerAs: '$ctrl',
      controller: /*@ngInject*/ function (WidgetsFactory, imagePick, ImagensFactory) {
        var ctrl = this;

        ctrl.tableform = {};

        ctrl.$onInit = init;
        ctrl.refresh = refresh;
        ctrl.save = save;
        ctrl.edit = edit;
        ctrl.addItem = addItem;
        ctrl.removeItem = removeItem;
        ctrl.addImage = addImage;

        /////////////

        function init() {
          if (!ctrl.item.data.imgGallery) {
            ctrl.item.data.imgGallery = {
              items: []
            };
          }
        }

        function refresh() {
          WidgetsFactory.log('widgestGaleria refresh');
        }

        function edit() {
          if (ctrl.item.data.imgGallery && ctrl.item.data.imgGallery.items && !ctrl.item.data.imgGallery.items.length) {
            ctrl.addItem();
          }
          ctrl.tableform.$show();
        }

        function save() {
          ctrl.tableform.$setSubmitted();
          if (ctrl.tableform.$valid) {
            ctrl.tableform.$save();
          }
        }

        function addItem() {
          ctrl.item.data.imgGallery.items.push({url: '', thumbUrl: '', caption: ''});
        }

        function removeItem(item) {
          var index = ctrl.item.data.imgGallery.items.indexOf(item);
          if (index !== -1) {
            ctrl.item.data.imgGallery.items.splice(index, 1);
          }
        }

        function addImage(item) {
          return imagePick.pick('', item.image, function (folder, img) {
            var url = ImagensFactory.getUrlImagem(folder, img);
            item.url = url;
            item.thumbUrl = url;
            item.caption = img;
          });
        }
      }
    });
})();
