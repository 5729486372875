(function () {
  'use strict';

  angular.module('erpRetail')
    .directive('fallbackSrc', function () {
      return {
        link: function postLink(scope, iElement, iAttrs) {
          iElement.bind('error', function () {
            angular.element(this).attr('src', iAttrs.fallbackSrc);
          });
        }
      };
    })
    .directive('mongooseError', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
          element.on('keydown', function () {
            return ngModel.$setValidity('mongoose', true);
          });
        }
      };
    })

    .directive('nifValidator', function (clienteService) {
      return {
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          ctrl.$validators.nif = function (modelValue, viewValue) {
            var value = modelValue || viewValue;
            return clienteService.validateNif(value);
          };
        }
      };
    })

    .directive('myNavscroll', function ($window) {
      return function ($scope, element, attrs) {
        angular.element($window).bind('scroll', function () {
          if (!$scope.scrollPosition) {
            $scope.scrollPosition = 10;
          }
          $scope.boolChangeClass = this.pageYOffset > $scope.scrollPosition;
          $scope.$apply();
        });
      };
    })

    .directive('disableNgAnimate', ['$animate', function ($animate) {
      return {
        restrict: 'A',
        link: function (scope, element) {
          $animate.enabled(false, element);
        }
      };
    }])

    .directive('isolateScrolling', function () {
      return {
        restrict: 'A',
        link: function (scope, element, attr) {
          element.bind('mousewheel', function (e) {
            if ((e.deltaY > 0 && this.clientHeight + this.scrollTop === this.scrollHeight) ||
              (e.deltaY < 0 && this.scrollTop === 0)) {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }

            return true;
          });
        }
      };
    })

    .directive('myEnter', function () {
      return function (scope, element, attrs) {
        element.bind('keydown keypress', function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.$eval(attrs.myEnter);
            });
            event.preventDefault();
          }
        });
      };
    });
})();
