(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('Auth', ['$location', '$rootScope', '$http', 'User', '$cookies', '$q', '$state', '$log', 'REST_API', '$localStorage',
      function ($location, $rootScope, $http, User, $cookies, $q, $state, $log, REST_API, $localStorage) {
        var that = {
          currentUser: {},
          userCall: null,
          confirmemail: {},
          resettoken: ''
        };

        that.refreshUser = function () {
          that.userCall = User.get();
          that.userCall.then(function (user) {
            that.currentUser = user;
            that.confirmemail = {};
            that.userCall = null;
            $rootScope.$broadcast('event-user');
            return user;
          }, function (error) {
            that.currentUser = {};
            that.userCall = null;
            $rootScope.$broadcast('event-user');
          });
        };

        /**
         * Authenticate user and save token
         *
         * @param  {Object}   user     - login info
         * @param  {Function} callback - optional
         * @return {Promise}
         */
        that.login = function (user, callback) {
          var cb = callback || angular.noop;
          var deferred = $q.defer();
          //$log.info('login');
          $http.post(REST_API.HOST + REST_API.LOGIN, {
            email: user.email,
            password: user.password
          }).then(function (obj) {
            $cookies.put('token', obj.data.user.token);
            that.userCall = null;
            that.currentUser = obj.data.user;
            $rootScope.$broadcast('event-user');
            deferred.resolve(obj.data);
            return cb();
          }, function (err) {
            deferred.reject(err);
            this.logout();
            return cb(err);
          }.bind(this));

          return deferred.promise;
        };

        /**
         * Delete access token and user info
         */
        that.logout = function () {
          $cookies.remove('token');
          that.currentUser = {};
          that.userCall = null;
          $localStorage.cesto = [];
          $rootScope.$broadcast('event-logout');
          if (that.getToken() !== null) {
            $http.get(REST_API.HOST + REST_API.LOGOUT);
          }
        };

        /**
         * Gets all available info on authenticated user
         *
         * @return {Object} user
         */
        that.getCurrentUser = function () {
          return that.currentUser;
        };

        that.isLoggedIn = function () {
          return Object.prototype.hasOwnProperty.call(that.currentUser, 'roles');
        };

        /**
         * Waits for currentUser to resolve before checking if user is logged in
         */
        that.isLoggedInAsync = function (cb) {
          if (that.userCall !== null) {
            that.userCall.then(function (user) {
              that.currentUser = user;
              that.userCall = null;
              cb(that.isLoggedIn(true));
            }, function () {
              that.currentUser = {};
              that.userCall = null;
              cb(false);
            });
          } else if (that.isLoggedIn()) {
            cb(true);
          } else {
            cb(false);
          }
        };

        /**
         * Check if a user is an admin
         *
         * @return {Boolean}
         */
        that.isAdmin = function () {
          if (!that.isLoggedIn()) return false;
          if (that.currentUser === null) return false;
          for (var i = 0; i < that.currentUser.roles.length; i++) {
            var item = that.currentUser.roles[i];
            if (item.name === 'admin') return true;
          }

          return false;
        };

        /**
         * Get auth token
         */
        that.getToken = function () {
          return $cookies.get('token');
        };

        /**
         * Criar utilizador
         */
        that.createUser = function (u) {
          var u1 = User.createUser(u);
          that.confirmemail.email = u.email;
          that.confirmemail.state = 'LOGIN_EMAIL_SENT';
          return u1;
        };

        that.baseUrl = function () {
          var url = $location.protocol() + '://' + $location.host();
          var port = $location.port();
          if (port) {
            url += ':' + port;
          }
          url += '/#!';
          return url;
        };

        that.sendEmailToConfirm = function (email, send) {
          that.confirmemail.email = email;
          that.confirmemail.state = 'LOGIN_EMAIL_SENDING';

          $http.post(REST_API.HOST + REST_API.USER_EMAIL_CONFIRM, {
            email: email, baseUrl: that.baseUrl()
          }).then(function (obj) {
            that.confirmemail.state = 'LOGIN_EMAIL_SENT';
          }, function (err) {
            $log.error(err);
            that.confirmemail.state = 'LOGIN_EMAIL_SENT_ERROR';
          });
        };

        that.getEmailToConfirm = function () {
          return that.confirmemail;
        };

        that.validatetoken = function (token) {

          var deferred = $q.defer();

          $http.post(REST_API.HOST + REST_API.USER_EMAIL_VALIDATION, {
            token: token
          }).then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            deferred.reject(err);
            $log.debug(err);
          });
          return deferred.promise;
        };

        that.recuperar = function (email) {
          var deferred = $q.defer();

          $http.post(REST_API.HOST + REST_API.USER_EMAIL_RECOVER, {
            email: email, baseUrl: that.baseUrl()
          }).then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            deferred.reject(err);
          });
          return deferred.promise;
        };

        that.setRecover = function (token) {
          that.resettoken = token;
        };

        that.getRecover = function () {
          return that.resettoken;
        };

        that.passwordValidator = function (password) {

          if (!password) {
            return 'LOGIN_ERROR_PASS_INVALID';
          }

          if (password.length < 5) {
            return 'LOGIN_ERROR_PASS_VALIDATION';
          }
          /*if (!password.match(/[A-Z]/)) {
           return 'A palavra-passe dev ter pelo menos uma letra maiúscula!';
           }

           if (!password.match(/[0-9]/)) {
           return 'A palavra-passe deve ter pelo menos 1 número!';
           }*/

          return true;
        };

        that.novaPassword = function (token, password) {
          var deferred = $q.defer();

          $http.post(REST_API.HOST + REST_API.USER_NOVA_PASSWORD, {
            token: token,
            password: password,
            baseUrl: that.baseUrl()
          }).then(function (data) {
            deferred.resolve(data.data);
          }, function (err) {
            deferred.reject(err);
            $log.debug(err);
          });
          return deferred.promise;
        };

        if ($cookies.get('token')) {
          that.refreshUser();
        }

        return that;
      }]);
}());
