(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('PerfilController', /*@ngInject*/ function ($scope, $q, Auth, clienteService, configFactory, $state, $translate, PopAlert, paisesService,
                                                            $transition$, alteraModaraModal) {

      var ctrl = this;

      ctrl.user = {};
      ctrl.cliente = {};
      ctrl.clienteMoradasAlt = {};
      ctrl.perfil = {};
      ctrl.paises = {};

      ctrl.$onInit = init;
      ctrl.refresh = refresh;
      ctrl.editMoradaFaturacao = editMoradaFaturacao;
      ctrl.editMoradaEntrega = editMoradaEntrega;
      ctrl.deleteMoradaEntrega = deleteMoradaEntrega;
      ctrl.newMorada = newMorada;
      
      /////////////

      function init() {
        ctrl.refresh();
      }

      function refresh() {
        ctrl.user = Auth.getCurrentUser();
        ctrl.perfil.email = ctrl.user.email;

        clienteService.getCliente().then(function (cliente) {
          ctrl.cliente = cliente;

          ctrl.moradaFaturacao = {
            nconta: ctrl.cliente.nconta,
            moralid: '{00000000-0000-0000-0000-000000000000}',
            nome: 'Principal',
            contactoNome: ctrl.cliente.nome,
            morada: ctrl.cliente.morada,
            localidade: ctrl.cliente.localidade,
            cpost: ctrl.cliente.codPostal,
            paisIso: {
              codPaisIso: ctrl.cliente.paisIso.codPaisIso,
              nomePais: ctrl.cliente.paisIso.nomePais
            },
            nipc: ctrl.cliente.nifReal,
            contactoTelefone: ctrl.cliente.telefone,
            isPrincipal: true,
            completed: ctrl.cliente.completed,
            canChangeNIF : ctrl.cliente.canChangeNIF
          };

          ctrl.clienteMoradasAlt = [];
          if (angular.isDefined(ctrl.moradaFaturacao) && ctrl.moradaFaturacao.completed) {
            clienteService.getClienteMoradasAlternativas().then(function (clienteMoradasAlt) {
              ctrl.clienteMoradasAlt = clienteMoradasAlt;
            });
          }
        });

        paisesService.getPaises().then(function (paises) {
          ctrl.paises = paises;
        });

        $translate.refresh();
      }

      function newMorada() {
        return alteraModaraModal.alteraMorada().then(function (novaMorada) {
          ctrl.newMoradaAlt = novaMorada;

          var newMoradaAlt = {
            moralid: '{00000000-0000-0000-0000-000000000000}',
            nconta: ctrl.cliente.nconta,
            nome: ctrl.newMoradaAlt.nome,
            rua: '',
            cpost: ctrl.newMoradaAlt.cpost,
            localidade: ctrl.newMoradaAlt.localidade,
            nipc: ctrl.newMoradaAlt.nipc || '',
            observacoes: '',
            morada: ctrl.newMoradaAlt.morada,
            desativado: false,
            facidGuidId: ctrl.cliente.facidGuidId,
            contactoNome: ctrl.newMoradaAlt.contactoNome,
            contactoTelefone: ctrl.newMoradaAlt.contactoTelefone || '',
            codPaisIso: ctrl.newMoradaAlt.paisIso.codPaisIso
          };

          clienteService.newMoradaAlternativa(newMoradaAlt, function (data) {
            ctrl.clienteMoradasAlt.push(data);
            $state.go('^.mostra');
          });
        });
      }

      function editMoradaFaturacao(morada) {
        return alteraModaraModal.alteraMorada(morada).then(function (novaMorada) {
          ctrl.moradaFaturacao = novaMorada;

          var cliente = {
            nconta: ctrl.cliente.nconta,
            nome: ctrl.moradaFaturacao.contactoNome,
            morada: ctrl.moradaFaturacao.morada,
            codPostal: ctrl.moradaFaturacao.cpost,
            localidade: ctrl.moradaFaturacao.localidade,
            nif: ctrl.moradaFaturacao.nipc,
            facidGuidId: ctrl.cliente.facidGuidId,
            email: ctrl.cliente.email,
            telefone: ctrl.moradaFaturacao.contactoTelefone,
            nifReal: ctrl.moradaFaturacao.nipc,
            isContaIdentAlternativa: ctrl.cliente.isContaIdentAlternativa,
            paisIso: {
              codPaisIso: ctrl.moradaFaturacao.paisIso.codPaisIso,
              nomePais: ctrl.moradaFaturacao.paisIso.nomePais
            },
            codPaisIso: ctrl.moradaFaturacao.paisIso.codPaisIso
          };
          clienteService.save(cliente, function () {
            $state.go('^.mostra');
          });
        });
      }

      function editMoradaEntrega(morada) {
        return alteraModaraModal.alteraMorada(morada).then(function (novaMorada) {
          var index = ctrl.clienteMoradasAlt.findIndex(function (item) {
            return item.moralid === novaMorada.moralid;
          });
          _.merge(ctrl.clienteMoradasAlt[index], novaMorada);

          delete novaMorada.open;

          clienteService.updateMoradaAlternativa(novaMorada, novaMorada.moralid, false, function () {
            $state.go('^.mostra');
          });
        });
      }

      function deleteMoradaEntrega(morada) {
        return alteraModaraModal.deleteMorada(morada).then(function (novaMorada) {
          var index = ctrl.clienteMoradasAlt.findIndex(function (item) {
            return item.moralid === novaMorada.moralid;
          });
          _.merge(ctrl.clienteMoradasAlt[index], novaMorada);

          novaMorada.desativado = true;
          delete novaMorada.open;

          clienteService.updateMoradaAlternativa(novaMorada, novaMorada.moralid, true, function () {
            ctrl.refresh();
            $state.go('^.mostra');
          });
        });
      }

      $scope.$on('event-user', function (event, args) {
        ctrl.refresh();
      });
      $scope.$on('event-login', function (event, args) {
        ctrl.refresh();
      });

    });
}());
