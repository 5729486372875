(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('imagePickController', function (item, $uibModalInstance, $filter, Utils, ImagensFactory) {
      var ctrl = this;

      ctrl.folder = '';
      ctrl.searchimages = null;
      ctrl.searchartigos = null;
      ctrl.isLoading = true;
      ctrl.lastFolder = '';
      ctrl.grid = {};

      ctrl.$onInit = init;
      ctrl.refresh = refresh;
      ctrl.updateFilter = updateFilter;
      ctrl.getImagem = getImagem;
      ctrl.select = select;
      ctrl.cancel = cancel;

      /////////////

      function init() {
        ctrl.folder = item.folder || '';
        ctrl.grid = {
          filtered: [],
          list: [],
          page: 1,
          total: 0,
          config: {
            itemsPerPage: 10,
            maxPages: 10,
            fillLastPage: true,
            orderBy: 'orderBy'
          }
        };
        ctrl.refresh(ctrl.folder);
      }

      function refresh(folder) {
        ctrl.isLoading = true;
        ImagensFactory.getList(folder).then(function (returns) {
          var aux = ctrl.folder.split('/');
          aux.splice(aux.length - 1, 1);
          ctrl.lastFolder = aux.join('/');
          ctrl.grid.list = returns;
          ctrl.updateFilter();
          ctrl.folder = folder;
        });
      }

      function updateFilter() {
        ctrl.isLoading = true;
        if (Utils.isNullOrEmpty(ctrl.searchimages)) {
          ctrl.grid.filtered = ctrl.grid.list;
        }
        else {
          ctrl.grid.filtered = $filter('filter')(ctrl.grid.list, ctrl.searchimages);
        }
        ctrl.isLoading = false;
      }

      function getImagem(item) {
        if (ctrl.isLoading || Utils.isNullOrUndefined(item) || item.type === 'folder') {
          return;
        }
        return ImagensFactory.getUrlImagem(ctrl.folder, item.name);
      }

      function select(item) {
        if (item.type === 'folder') {
          var folder = ctrl.folder + '/' + item.name;
          ctrl.refresh(folder);
          return;
        }
        if (!Utils.isNullOrEmpty(ctrl.folder)) {
          ctrl.folder += ctrl.folder + '/';
        }
        $uibModalInstance.close({folder: ctrl.folder, name: item.name});
      }

      function cancel() {
        $uibModalInstance.dismiss('cancel');
      }
    });
})();
