/**
 * Created by cg on 30/09/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('ContatosController', /*@ngInject*/ function ($scope, EmpresaFactory, emailService, Utils, PopAlert) {

      var ctrl = this;

      ctrl.empresa = {};
      ctrl.formContact = {};
      ctrl.form = {};
      ctrl.submited = false;

      ctrl.sendMail = sendMail;
      ctrl.refresh = refresh;
      ctrl.init = init;

      ctrl.init();

      ////////////////

      function init() {
        ctrl.refresh();
      }

      function refresh() {
        EmpresaFactory.get().then(function (empresa) {
          ctrl.empresa = empresa;
        });
      }

      function sendMail(form) {
        if (form.$valid) {
          emailService.sendContact(ctrl.form.from, ctrl.form.email, ctrl.form.message).then(function () {
              PopAlert.showInfo('contactos.mensagens.emailSentSuccess');
              ctrl.submited = true;
              ctrl.form.message = '';
            },
            function () {
              PopAlert.showError('contactos.mensagens.emailSentFailed');
            });
        }
      }
    });
}());
