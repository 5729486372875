(function () {
  'use strict';

  angular.module('erpRetail')
    .directive('compile', function ($compile) {
      // Directive factory creates a link function
      return function ($scope, $element, $attrs) {
        $attrs.$observe('compile', function (value) {
          // When the 'compile' expression changes assign it into the current DOM
          $element.html($scope.$eval(value));

          /* Compile the new DOM and link it to the current $scope.
           * NOTE: we only compile .childNodes so that
           * we don't get into infinite loop compiling ourselves */
          $compile($element.contents())($scope);
        });
      };
    });
})();
