(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('alteraModaraModal', function (CGModalService) {
      return {
        alteraMorada: alteraMorada,
        deleteMorada: deleteMorada
      };

      /////////////

      function alteraMorada(morada) {
        return CGModalService.show({
          templateUrl: 'app/components/alteraMoradaModal/altera.morada.modal.html',
          controller: 'AlteraMoradaModalController',
          resolve: {
            item: function () {
              return morada;
            }
          }
        });
      }

      function deleteMorada(morada) {
        return CGModalService.show({
          templateUrl: 'app/components/alteraMoradaModal/delete.morada.modal.html',
          controller: 'AlteraMoradaModalController',
          resolve: {
            item: function () {
              return morada;
            }
          }
        });
      }
    });
})();
