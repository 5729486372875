(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-col6-image', 'widget-html-col6-image-edit', 'Html Col-6 w/ image');
    })

    .directive('widgetHtmlCol6Image', function () {
      function controller($scope, WidgetsFactory, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgestHtmlCol6Image refresh');
        };
        $scope.img = $scope.img || [{imgUrl: ''}];
        $scope.item.data.html = $scope.item.data.html || {};
      }

      function link(scope) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '='
        },
        templateUrl: 'app/components/widgets/htmlCol6Image/html.col6Image.html',
        link: link,
        controller: controller
      };
    })

    .directive('widgetHtmlCol6ImageEdit', function () {
      function controller($scope, WidgetsFactory, imagePick, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgetHtmlCol6ImageEdit refresh');
        };
        $scope.addImage = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl = folder + img;
          });
        };
      }

      function link(scope) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlCol6Image/html.col6Image.edit.html',
        link: link,
        controller: controller
      };
    });
})();
