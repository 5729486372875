(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('cgClass', function () {
      var factory = {
        CGKeyCount: CGKeyCount,
        accentFold: accentFold,
        accentSplit: accentSplit
      };
      return factory;

      /////////////

      function CGKeyCount() {
        this.items = {};

        this.push = function (key) {
          if (key === '' || key === null || key.length === 1) {
            return;
          }
          var num = this.items[key];
          if (angular.isUndefined(num)) {
            this.items[key] = 1;
            return true;
          }
          else {
            num++;
            this.items[key] = num;
            return false;
          }
        };

        this.pushWord = function (frase) {
          if (frase === null) {
            return;
          }
          var words = accentSplit(frase);
          for (var i = 0; i < words.length; i++) {
            var word = words[i];
            this.push(word);
          }
        };

        this.toArray = function () {
          var result = [];
          for (var key in this.items) {
            if (Object.prototype.hasOwnProperty.call(this.items, key)) {
              var item = {};
              item.key = key;
              item.value = this.items[key];
              result.push(item);
            }
          }
          return result;
        };

        this.toArrayText = function () {
          var result = [];
          for (var key in this.items) {
            if (Object.prototype.hasOwnProperty.call(this.items, key)) {
              var item = {};
              item.text = key;
              result.push(item);
            }
          }
          return result;
        };
      }

      function accentFold(newStringComAcento) {
        var string = ('' + newStringComAcento).toLowerCase();
        var mapaAcentosHex = {
          a: /[\xE0-\xE6]/g,
          e: /[\xE8-\xEB]/g,
          i: /[\xEC-\xEF]/g,
          o: /[\xF2-\xF6]/g,
          u: /[\xF9-\xFC]/g,
          c: /\xE7/g,
          n: /\xF1/g,
          A: /[\xC0-\xC6]/g,
          E: /[\xC9-\xCB]/g,
          I: /[\xCC-\xCF]/g,
          O: /[\xD2-\xD6]/g,
          U: /[\xD9-\xDC]/g,
          C: /\xC7/g,
          N: /\xD1/g
        };

        for (var letra in mapaAcentosHex) {
          if (Object.prototype.hasOwnProperty.call(mapaAcentosHex, letra)) {
            var expressaoRegular = mapaAcentosHex[letra];
            string = string.replace(expressaoRegular, letra);
          }
        }

        return string.toLowerCase();
      }

      function accentSplit(value) {
        value = value.toLowerCase();
        var aux = factory.accentFold(value);
        var spl = '[]#$%&"\'(), *\\/-';
        var r = [];
        var p = 0;
        var s = '';
        for (var i = 0; i < aux.length; i++) {
          var c = aux.charAt(i);

          if (spl.indexOf(c) !== -1) {
            if (p === i) {
              p = i + 1;
              continue;
            }
            s = value.substring(p, i);
            r.push(s);
            p = i + 1;
          }
        }
        s = value.substring(p, i);
        r.push(s);
        return r;
      }
    });
})();
