(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('timePickerModalController', function (item, $uibModalInstance, Utils) {
      var ctrl = this;
      ctrl.item=[];
      if (Utils.isNullOrUndefined(item.item)) {
        item.item={          
            from: new Date(),
            to: new Date('1/1/2050 12:00:00 PM')
        };
      }
      ctrl.item.dateFrom=item.item.from;
      ctrl.item.timeFrom=item.item.from;
      ctrl.item.dateTo=item.item.to;
      ctrl.item.timeTo=item.item.to;

      ctrl.isLoading = true;

      ctrl.$onInit = init;
      ctrl.select = select;
      ctrl.cancel = cancel;

      /////////////

      function init() {

      }

      function select(item) {
        var ret={from: Utils.combineDateTime(ctrl.item.dateFrom, ctrl.item.timeFrom), to: Utils.combineDateTime(ctrl.item.dateTo, ctrl.item.timeTo)};
        $uibModalInstance.close(ret);
      }

      function cancel() {
        $uibModalInstance.dismiss('cancel');
      }
    });
})();
