(function () {
  'use strict';
  angular.module('erpRetail')
    .run(['WidgetsFactory', function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-half', 'widget-html-half-edit', 'Html 2xCol-6');
    }])

    .directive('widgetHtmlHalf', function () {

      function controller($scope, WidgetsFactory, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgestHtmlHalf refresh');
        };

        $scope.img = [{imgUrl: ''}];

      }

      function link(scope, element, attrs) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlHalf/html.half.html',
        link: link,
        controller: controller
      };
    })
    .directive('widgetHtmlHalfEdit', function () {

      function controller($scope, WidgetsFactory, imagePick, ImagensFactory) {
        $scope.getImagem = ImagensFactory.getImagem;
        $scope.refresh = function () {
          WidgetsFactory.log('widgetHtmlEdit refresh');
          //WidgetsFactory.log($scope.item);
        };
        $scope.addImage = function (item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl = folder + img;
          });
        };
        $scope.tinymceOptions = {
          resize: false,
          toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | insertfile  link image',
          'file_browser_callback': function (fieldName, url, type, win) {
            win.document.getElementById(fieldName).value = 'my browser value';
          }
        };
      }

      function link(scope, element, attrs, $compile) {
        scope.$watch('item', function () {
          scope.refresh();
        });
      }

      return {
        restrict: 'E',
        scope: {
          item: '=item'
        },
        templateUrl: 'app/components/widgets/htmlHalf/html.half.edit.html',
        link: link,
        controller: controller
      };

    });
}());
