(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('UtilizadorConfirmaTokenController', /*@ngInject*/ function ($rootScope, $scope, Auth, $state, Utils, PopAlert, configFactory, $transition$) {
      var ctrl = this;
      ctrl.token = '';
      ctrl.state = '';
      ctrl.enableSignUp = true;

      ctrl.$onInit = init;
      ctrl.notOk = notOk;
      ctrl.enviar = enviar;

      /////////////

      function init() {
        ctrl.token = $transition$.params().token;
        configFactory.getConfigSite().then(
          function (response) {
            // Trying to access sign up but it is disabled
            if (parseInt(response.siteSignupEnabled) === 0) {
              ctrl.enableSignUp = false;
            }
          }
        );

        ctrl.state = 'LOGIN_TOKEN_WAIT';
        Auth.validatetoken(ctrl.token).then(function (result) {
          ctrl.state = result.state;
          ctrl.email = '';
          Auth.setRecover('');
          switch (ctrl.state) {
            case 'OK':
              ctrl.state = 'LOGIN_TOKEN_OK';
              PopAlert.showSuccess('account.confirmaToken.alerts.emailActivatedSuccess');
              $state.go('login');
              break;
            case 'NOT_FOUND':
              ctrl.state = 'LOGIN_TOKEN_NOT_FOUND';
              break;
            case 'EXPIRED':
              ctrl.state = 'LOGIN_TOKEN_EXPIRED';
              ctrl.email = result.email;
              break;
            case 'RECOVER':
              Auth.setRecover(ctrl.token);
              $state.go('user.nova');
              break;
            default :
              ctrl.state = 'LOGIN_TOKEN_UNKNOWN';
              break;
          }
        });
      }


      function notOk() {
        return ctrl.state !== 'LOGIN_TOKEN_OK' && ctrl.state !== 'LOGIN_TOKEN_WAIT';
      }

      function enviar() {
        Auth.sendEmailToConfirm(ctrl.email);
        return $state.go('user.confirm_email');
      }
    });
})();
