(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('MeioPagamentoController', function ($state, $transition$, documentoService, Utils, PagamentosFactory) {
      var ctrl = this;

      ctrl.meiosPagamento = [];
      ctrl.doc = {};

      ctrl.refresh = refresh;
      ctrl.setMeioPagamento = setMeioPagamento;

      function refresh() {
        if (Utils.isNullOrUndefined($transition$.params().MBFaccbId)) {
          $state.go('home');
        }
        documentoService.getDoc($transition$.params().MBFaccbId).then(function (response) {
          ctrl.doc = response.data;
        });
        documentoService.getMeiosPagamento().then(function (data) {
          ctrl.meiosPagamento = data;
          if (ctrl.meiosPagamento.length === 0) {
            $state.go('loja.documentos', {mbfaccbId: $transition$.params().MBFaccbId});

          } else if (ctrl.meiosPagamento.length === 1) {
            PagamentosFactory.goPagamento(data[0].tipo, $transition$.params().MBFaccbId);
          }
        });
      }

      function setMeioPagamento(item) {
        ctrl.meioPagamento = item;
        PagamentosFactory.goPagamento(ctrl.meioPagamento.tipo, $transition$.params().MBFaccbId, 'pagar', 'novo');
      }

      ctrl.refresh();
    })
    .controller('PayPalController', function ($state, $transition$, documentoService, $rootScope, Utils, PagamentosFactory, $log, $window) {
      var ctrl = this;

      ctrl.message = $rootScope.globalTranslate('pagamento.paypal.waiting');
      ctrl.init = init;

      function init() {
        if (Utils.isNullOrUndefined($transition$.params().MBFaccbId)) {
          $state.go('home');
          return;
        }
        if (Utils.sameText($transition$.params().action, 'Cancelado')) {
          ctrl.message = $rootScope.globalTranslate('pagamento.paypal.canceled');
          PagamentosFactory.paypal($transition$.params().MBFaccbId, $transition$.params().action, $transition$.params().payKey);
          $state.go('loja.documentos', {mbfaccbId: $transition$.params().MBFaccbId});
          return;
        }
        if (Utils.sameText($transition$.params().action, 'ok')) {
          ctrl.message = $rootScope.globalTranslate('pagamento.paypal.success');
          PagamentosFactory.paypal($transition$.params().MBFaccbId, $transition$.params().action, $transition$.params().payKey);
          $state.go('loja.documentos', {mbfaccbId: $transition$.params().MBFaccbId});
          return;
        }

        ctrl.message = $rootScope.globalTranslate('pagamento.paypal.redireting');

        PagamentosFactory.paypal($transition$.params().MBFaccbId, 'pagar', '').then(function (data) {
          $log.debug(data);
          if (data.status === 0) {
            $window.location = data.url;
          } else {
            $state.go('loja.documentos');
          }
        });
      }

      ctrl.init();
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('loja.pagamento', {
          url: '/pagamento',
          title: 'pagamento.pagamento',
          templateUrl: 'app/loja/pagamento/pagamento.html',
          abstract: true
        })
        .state('loja.pagamento.paypal', {
          url: '/paypal/:MBFaccbId/:payKey/:action',
          title: 'pagamento.pagamentoPorPaypal',
          templateUrl: 'app/loja/pagamento/paypal.html',
          controller: 'PayPalController',
          controllerAs: 'paypal'
        });
    });
}());
