(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('ClientesController', /*@ngInject*/ function ($q, $state, $http, $filter, $log, REST_API, cgClass, clienteService) {
      var ctrl = this;

      ctrl.list = [];
      ctrl.filteredList = [];
      ctrl.configClientes = {};

      ctrl.$onInit = init;
      ctrl.onLoad = onLoad;
      ctrl.updateFiltered = updateFiltered;

      /////////////////

      function init() {
        ctrl.configClientes = {
          itemsPerPage: 10,
          maxPages: 10,
          fillLastPage: true,
          orderBy: 'AIOrderBy'
        };
        ctrl.onLoad();
      }

      function onLoad() {
        var deferred = $q.defer();
        clienteService.getClienteAll()
          .then(function (response) {
            ctrl.list = response.data;
            ctrl.filteredList = ctrl.list;
            deferred.resolve();
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
        return deferred.promise;
      }

      function updateFiltered() {
        ctrl.filteredList = [];
        var search = ctrl.searchText;
        if (search === '' || search === null || angular.isUndefined(search)) {
          ctrl.filteredList = ctrl.list;
          return;
        }
        search = cgClass.accentFold(search.toLowerCase()).split(' ');
        angular.forEach(ctrl.list, function (item) {
          var ok = true;
          for (var j = 0; j < search.length; j++) {
            var key = search[j];
            ok = ok && (
              (item.nconta !== null && cgClass.accentFold(item.nconta.toLowerCase()).indexOf(key) >= 0) ||
              (item.nome !== null && cgClass.accentFold(item.nome.toLowerCase()).indexOf(key) >= 0) ||
              (item.ncontribuint !== null && item.ncontribuint.toLowerCase().indexOf(key) >= 0) ||
              (item.lugar !== null && cgClass.accentFold(item.lugar.toLowerCase()).indexOf(key) >= 0) ||
              (item.codPostal !== null && item.codPostal.toLowerCase().indexOf(key) >= 0) ||
              (item.localidade !== null && cgClass.accentFold(item.localidade.toLowerCase()).indexOf(key) >= 0) ||
              (item.telefone !== null && item.telefone.toLowerCase().indexOf(key) >= 0) ||
              (item.telemovel !== null && item.telemovel.toLowerCase().indexOf(key) >= 0) ||
              (item.rua !== null && cgClass.accentFold(item.rua.toLowerCase()).indexOf(key) >= 0) ||
              (item.email !== null && item.email.toLowerCase().indexOf(key) >= 0));
            if (!ok) {
              break;
            }
          }
          if (ok) {
            ctrl.filteredList.push(item);
          }
        });
      }
    });
})();
