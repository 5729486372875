/**
 * Created by Pedro Lopes on 08/10/14.
 */
(function () {
  'use strict';

  angular.module('erpRetail')
    .factory('clienteService', /*@ngInject*/ function ($resource, $q, $http, REST_API, $log, Auth, PopAlert, $translate) {
      var service = {
        getCliente: getCliente,
        getClienteAll: getClienteAll,
        getClienteMoradasAlternativas: getClienteMoradasAlternativas,
        save: save,
        newMoradaAlternativa: newMoradaAlternativa,
        updateMoradaAlternativa: updateMoradaAlternativa,
        query: query,
        validateNif: validateNif,
        nifValidator: nifValidator
      };
      return service;

      function getCliente() {
        var deferred = $q.defer();

        $http.get(REST_API.HOST + REST_API.CLIENTE)
          .then(function (data) {
            deferred.resolve(data.data);
          });
        return deferred.promise;
      }

      function getClienteAll() {
        var deferred = $q.defer();

        $http.get(REST_API.HOST + REST_API.ADMIN_CLIENTES_ALL)
          .then(function (data) {
            deferred.resolve(data.data);
          });
        return deferred.promise;
      }

      function getClienteMoradasAlternativas() {
        var deferred = $q.defer();

        $http.get(REST_API.HOST + REST_API.CLIENTEMORADASALTERNATIVAS)
          .then(function (data) {
            deferred.resolve(data.data);
          });
        return deferred.promise;
      }

      function save(cliente, okfunc) {
        $http.post(REST_API.HOST + REST_API.CLIENTE, cliente)
          .then(function (data) {
            Auth.refreshUser();
            PopAlert.showInfo('Cliente atualizado');
            okfunc(data);
          }, function (data) {
            $log.error('erro a gravar cliente');
            $log.error(data);
            PopAlert.postError();
          });
      }

      function newMoradaAlternativa(morada, okfunc) {
        $http.post(REST_API.HOST + REST_API.CLIENTEMORADASALTERNATIVASEDIT, morada)
          .then(function (data) {
            PopAlert.showInfo('Morada criada');
            okfunc(data.data);
          }, function (data) {
            $log.error('erro a gravar morada');
            $log.error(data);
            PopAlert.postError();
          });
      }

      function updateMoradaAlternativa(morada, id, isDelete, okfunc) {
        $http.put(REST_API.HOST + REST_API.CLIENTEMORADASALTERNATIVASEDIT + '/' + id, morada)
          .then(function (data) {
            if (isDelete === true) {
              PopAlert.showInfo('Morada apagada');
            } else {
              PopAlert.showInfo('Morada atualizada');
            }

            okfunc();
          }, function (data) {
            $log.error('erro ao atualizar morada');
            $log.error(data);
            PopAlert.postError();
          });
      }

      function query(search, order, page, perpage) {
        var deferred = $q.defer();
        var config = {
          params: {
            pesquisa: search
          }
        };
        $http.get(REST_API.HOST + REST_API.CLIENTES, config).then(function (data) {

          var obj = {
            list: data.data.data,
            total: data.data.page.records
          };
          deferred.resolve(obj);
        });
        return deferred.promise;
      }

      function validateNif(contribuinte) {
        if (!angular.isString(contribuinte) || contribuinte.length !== 9) {
          return false;
        }
        // algoritmo de validação do NIF de acordo com
        // http://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal
        var temErro = false;
        if (
          contribuinte.substr(0, 1) !== '1' && // pessoa singular
          contribuinte.substr(0, 1) !== '2' && // pessoa singular
          contribuinte.substr(0, 1) !== '3' && // pessoa singular
          contribuinte.substr(0, 2) !== '45' && // pessoa singular não residente
          contribuinte.substr(0, 1) !== '5' && // pessoa colectiva
          contribuinte.substr(0, 1) !== '6' && // administração pública
          contribuinte.substr(0, 2) !== '70' && // herança indivisa
          contribuinte.substr(0, 2) !== '71' && // pessoa colectiva não residente
          contribuinte.substr(0, 2) !== '72' && // fundos de investimento
          contribuinte.substr(0, 2) !== '77' && // atribuição oficiosa
          contribuinte.substr(0, 2) !== '79' && // regime excepcional
          contribuinte.substr(0, 1) !== '8' && // empresário em nome individual (extinto)
          contribuinte.substr(0, 2) !== '90' && // condominios e sociedades irregulares
          contribuinte.substr(0, 2) !== '91' && // condominios e sociedades irregulares
          contribuinte.substr(0, 2) !== '98' && // não residentes
          contribuinte.substr(0, 2) !== '99' // sociedades civis
        ) {
          temErro = true;
        }
        var check1 = contribuinte.substr(0, 1) * 9;
        var check2 = contribuinte.substr(1, 1) * 8;
        var check3 = contribuinte.substr(2, 1) * 7;
        var check4 = contribuinte.substr(3, 1) * 6;
        var check5 = contribuinte.substr(4, 1) * 5;
        var check6 = contribuinte.substr(5, 1) * 4;
        var check7 = contribuinte.substr(6, 1) * 3;
        var check8 = contribuinte.substr(7, 1) * 2;

        var total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
        var divisao = total / 11;
        var modulo11 = total - parseInt(divisao) * 11;
        var comparador = 0;
        if (modulo11 === 1 || modulo11 === 0) {
          comparador = 0;
        } // excepção
        else {
          comparador = 11 - modulo11;
        }

        var ultimoDigito = contribuinte.substr(8, 1) * 1;
        if (ultimoDigito !== comparador) {
          temErro = true;
        }

        return !temErro;
      }

      function nifValidator(value) {
        return function () {
          return {
            value: angular.isFunction(value) ? value() : value,
            message: $translate.instant('validators.nif', {value: ''}),
            validate: function (name, ngModel) {
              if (this.value === false) {
                return true;
              }
              return ngModel && service.validateNif(ngModel);
            }
          };
        };
      }
    });
})();
