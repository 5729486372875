(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function ($templateRequest, $templateCache) {
      $templateRequest('app/components/directives/documento/documento.html').then(function (template) {
        $templateCache.put('template-documento-component', template);
      });
    })
    .component('documento', {
      bindings: {
        source: '<',
        targetId: '<',
        showPrint: '<?',
        showPdf: '<?',
        template: '@?',
        callback: '<',
        evtConfirmPayment: '&?'
      },
      template: '<div compile="$ctrl.currentTemplate"></div>',
      controllerAs: '$ctrl',
      controller: /*@ngInject*/ function ($q, documentoService, $timeout, $state, $templateCache, Utils, PopAlert, $rootScope, PagamentosFactory, confirmaPagamentoModal) {
        var ctrl = this;

        ctrl.templateDocumento = $templateCache.get('template-documento-component');
        ctrl.templateFatura = $templateCache.get('template-encomendas-fatura');

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.hasDoc = hasDoc;
        ctrl.reload = reload;
        ctrl.loadReferencias = loadReferencias;
        ctrl.sleep = sleep;
        ctrl.handleChanges = handleChanges;
        ctrl.changedShowPrint = changedShowPrint;
        ctrl.changedShowPdf = changedShowPdf;
        ctrl.canPay = canPay;
        ctrl.pay = pay;
        ctrl.confirmPayment = confirmPayment;
        ctrl.promise = null;
        ctrl.retryMB = 0;
        ctrl.stateName = $state.current.name;

        /////////////

        function init() {
          ctrl.currentTemplate = ctrl.template !== 'fatura' ? ctrl.templateDocumento : ctrl.templateFatura;
          ctrl.handleChanges();
          ctrl.reload();
        }

        function hasDoc() {
          return !!ctrl.targetId;
        }

        function onChanges(documento) {
          if (documento.showPrint && !documento.showPrint.isFirstChange()) {
            ctrl.changedShowPrint(documento.showPrint.currentValue);
          }
          if (documento.showPdf && !documento.showPdf.isFirstChange()) {
            ctrl.changedShowPdf(documento.showPdf.currentValue);
          }
          if (documento.targetId && !documento.targetId.isFirstChange() || documento.source && !documento.source.isFirstChange()) {
            ctrl.reload();
          }
        }

        function sleep() {
          var defered = $q.defer();
          $timeout(function () {
            defered.resolve();
          }, 3000);
          return defered.promise;
        }

        function loadReferencias() {
          var defered = $q.defer();

          documentoService.getReferenciasMB(ctrl.doc.faccb.mbfaccbId)
            .then(function (response) {
              ctrl.referenciasmb = response.data;
              return defered.resolve(ctrl.referenciasmb);
            })
            .catch(function (reason) {
              if (ctrl.retryMB > 10) {
                ctrl.erroMB = true;
                return defered.reject();
              } else {
                ctrl.retryMB = ctrl.retryMB + 1;
                ctrl.sleep().then(function () {
                  ctrl.loadReferencias();
                });
              }
            });

          ctrl.promise = defered.promise;
        }

        function reload() {
          if (!ctrl.hasDoc()) {
            return;
          }
          var value = ctrl.source;
          if (angular.isFunction(value)) {
            value = ctrl.source();
          }
          $q.when(value).then(function (response) {
            var doc = response.data || response;
            doc.clifo.nifReal = doc.clifo.nContribuint;
            if (doc.clifo.nifReal === '999999990' || !doc.clifo.nContribuint) {
              doc.clifo.nifReal = $rootScope.globalTranslate('admin.documento.consumidorFinal');
            }
            ctrl.doc = doc;

            if (ctrl.doc.caixa && ctrl.doc.caixa.tipo === 2) {
              ctrl.loadReferencias();
            }

            if (ctrl.doc.caixa && ctrl.doc.caixa.tipo === 9) {
              ctrl.canPay(doc);
            }

            if (ctrl.doc.faccb) {
              ctrl.pdfUrl = documentoService.getPdfUrl(ctrl.doc.faccb.faccbid > 0 ? ctrl.doc.faccb.faccbid : ctrl.doc.faccb.mbfaccbId);
            } else {
              ctrl.pdfUrl = documentoService.getPdfUrl(ctrl.doc.cab.faccbId);
            }
          });
        }

        function handleChanges() {
          ctrl.changedShowPrint();
          ctrl.changedShowPdf();
        }

        function changedShowPrint(value) {
          value = value || ctrl.showPrint;
          if (typeof value !== 'boolean') {
            value = false;
          }
          ctrl.showPrint = value;
        }

        function changedShowPdf(value) {
          value = value || ctrl.showPdf;
          if (typeof value !== 'boolean') {
            value = true;
          }
          ctrl.showPdf = value;
        }

        function canPay(doc) {
          if (doc && doc.mbdocLog.last) {
            return (!Utils.isNullOrEmpty(doc) && !Utils.isNullOrEmpty(doc.mbdocLog.last) && Utils.sameText(doc.mbdocLog.last.estado, 'PaypalSend') ||
              Utils.sameText(doc.mbdocLog.last.estado, 'EsperaPagamento'));
          }
        }

        function pay(doc) {
          PagamentosFactory.goPagamento(doc.caixa.tipo, doc.mbdocLog.last.mbfaccbid);
        }

        function confirmPayment(doc) {
          return confirmaPagamentoModal.confirmaPagamento(doc).then(function (data) {
            PagamentosFactory.setPayed(doc.mbdocLog.last.mbfaccbid, data.motivo).then(function (response) {
              ctrl.doc.mbdocLog.last.estado = response.estado;
              ctrl.doc.mbdocLog.last.descricao = response.descricao;
              if (angular.isFunction(ctrl.evtConfirmPayment)) {
                ctrl.evtConfirmPayment({doc: ctrl.doc});
              }
            });
          });
        }
      }
    });
})();
