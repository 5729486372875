(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('DocumentosController', function ($state, $transition$, $q, documentosService, documentoService, $translate, Auth) {
      var ctrl = this;

      ctrl.params = $transition$.params();
      ctrl.definition = {};
      ctrl.callback = {};

      ctrl.$onInit = init;
      ctrl.getSyncStatus = getSyncStatus;
      ctrl.getStatus = getStatus;
      ctrl.onLoad = onLoad;
      ctrl.onDocSelect = onDocSelect;
      ctrl.onDocDetail = onDocDetail;
      ctrl.confirmedPayment = confirmedPayment;

      /////////////
      function init() {
        ctrl.definition = {
          order: 'stampInsert desc',
          page: 1,
          options: {
            perPage: 10
          },
          fields: [
            {name: 'stampInsert', type: 'timestamp', visible: false, caption: ''},
            {name: 'ndocAsString', type: 'text', caption: 'ACOOUNT_DOCS_TABLE_TH_NDOC'},
            {name: 'dataDoc', type: 'date', caption: 'ACCOUNT_DOCS_TABLE_TH_DATA'},
            {name: 'statusSync', type: 'text', caption: 'ADMIN_DOCS_TABLE_TH_STATE'},
            {name: 'estado', caption: 'ACCOUNT_DOCS_TABLE_TH_ESTADO'},
            {name: 'descricao', caption: 'ADMIN_DOCS_TABLE_TH_MOTIVO'},
            {name: 'total', align: 'right', type: 'number', caption: 'ACCOUNT_DOCS_TABLE_TH_TOTAL'},
            {name: 'estadoLabel', align: 'center', caption: ' '}
          ]
        };
      }

      function getSyncStatus(item) {
        return documentosService.getStatus(item);
      }

      function getStatus(item) {
        if (item.estado === 'PaypalCompleted' || item.estado === 'PayedSettedByAdmin') {
          item.estadoLabel = '<i class="fa fa-check text-success"></i>';
        } else {
          item.estadoLabel = '<i class="fa fa-credit-card text-primary"></i>';
        }
        item.estado = $translate.instant('DOC_ESTADO_' + item.estado);
      }

      function onLoad(search, order, page, perpage) {
        var deferred = $q.defer();
        documentosService.getAll(ctrl.ndoc, page, perpage, order)
          .then(function (response) {
            var linhas = response.map(function (item) {
              item._$ctrl = ctrl;
              item.statusSync = ctrl.getSyncStatus(item.statusSync);
              ctrl.getStatus(item);
              return item;
            });
            deferred.resolve(linhas);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
        return deferred.promise;
      }

      function onDocSelect(item) {
        item._detailOpen = !item._detailOpen;
        if (item._detailOpen) {
          return ctrl.onDocDetail(item);
        }
      }

      function onDocDetail(item) {
        if (item._thedoc) {
          return;
        }
        var deferred = $q.defer();
        documentoService.getDoc(item.mbfaccbId)
          .then(function (response) {
            item._ctrl = ctrl;
            item._thedoc = response.data;
            item._thedoc.currentUser = Auth.currentUser;
            deferred.resolve();
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
        return deferred.promise;
      }

      function confirmedPayment(item, doc) {
        item.estado = doc.mbdocLog.last.estado;
        item.descricao = doc.mbdocLog.last.descricao;
        ctrl.getStatus(item);
      }
    });
})();
