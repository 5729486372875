(function () {
  'use strict';

  angular.module('erpRetail')
    .run(function (WidgetsFactory) {
      WidgetsFactory.register('widget-html-col4', 'widget-html-col4-edit', 'Html 3xCol-4');
    })
    .component('widgetHtmlCol4', {
      bindings: {
        item: '<'
      },
      templateUrl: 'app/components/widgets/htmlCol4/html.col4.html',
      controllerAs: '$ctrl',
      controller: /*@ngInject*/ function ($scope, WidgetsFactory, ImagensFactory) {
        var ctrl = this;

        ctrl.getImagem = ImagensFactory.getImagem;
        ctrl.img = [];

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.refresh = refresh;

        /////////////

        function init() {
          ctrl.img = [{imgUrl: ''}];
        }

        function onChanges(htmlCol4) {
          if(htmlCol4 && htmlCol4.item && !htmlCol4.item.isFirstChange()) {
            ctrl.refresh();
          }
        }

        function refresh() {
          WidgetsFactory.log('widgestHtmlCol4 refresh');
        }
      }
    })
    .component('widgetHtmlCol4Edit', {
      bindings: {
        item: '<'
      },
      templateUrl: 'app/components/widgets/htmlCol4/html.col4.edit.html',
      controllerAs: '$ctrl',
      controller: /*@ngInject*/ function ($scope, $compile, WidgetsFactory, imagePick, ImagensFactory) {
        var ctrl = this;

        ctrl.getImagem = ImagensFactory.getImagem;

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.refresh = refresh;
        ctrl.addImage1 = addImage1;
        ctrl.addImage2 = addImage2;
        ctrl.addImage3 = addImage3;

        /////////////

        function init() {
          ctrl.tinymceOptions = {
            resize: false,
            toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | insertfile  link image',
            'file_browser_callback': function (fieldName, url, type, win) {
              win.document.getElementById(fieldName).value = 'my browser value';
            }
          };
        }

        function onChanges(htmlCol4Edit) {
          if(htmlCol4Edit && htmlCol4Edit.item && !htmlCol4Edit.item.isFirstChange()) {
            ctrl.refresh();
          }
        }

        function refresh() {
          WidgetsFactory.log('widgetHtmlEdit refresh');
        }

        function addImage1(item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl1 = folder + img;
          });
        }

        function addImage2(item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl2 = folder + img;
          });
        }

        function addImage3(item) {
          imagePick.pick('', item.image, function (folder, img) {
            item.data.html.imgUrl3 = folder + img;
          });
        }

      }

    });
})();
