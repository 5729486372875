(function () {
  'use strict';

  angular.module('erpRetail')
    .controller('ConfirmaPagamentoModalController', function ($q, item) {
      var ctrl = this;

      ctrl.definition = {};
      ctrl.form = {};

      ctrl.$onInit = init;
      ctrl.submit = submit;
      ctrl.cancel = cancel;

      /////////////

      function init() {
        ctrl.model = {};
        ctrl.definition = {
          fields: []
        };

        ctrl.definition = {
          fields: [
            {
              name: 'motivo',
              caption: 'admin.documento.confirmaPagamentoModal.motivo',
              placeholder: 'admin.documento.confirmaPagamentoModal.motivo',
              validators: {required: {value: false}}
            }
          ]
        };
      }

      function submit() {
        ctrl.form.$setSubmitted();
        if (ctrl.form.$invalid) {
          return;
        }
        ctrl.$close(ctrl.model);
      }

      function cancel() {
        ctrl.$dismiss();
      }
    });
})();
