(function () {
  'use strict';

  angular.module('erpRetail')
    .config(/*@ngInject*/ function ($stateProvider) {
      $stateProvider
        .state('login', {
          url: '/login?returnstate',
          title: 'Autenticação',
          templateUrl: 'app/account/login/login.html',
          controller: 'LoginController',
          controllerAs: 'ctrl',
          params: {
            returnstate: {
              type: 'string',
              dynamic: true,
              squash: true,
              value: ''
            },
            returnstateparams: {
              type: 'any',
              dynamic: true,
              value: null
            }
          }
        })
        .state('logout', {
          url: '/logout',
          controller: function (Auth, $state, PopAlert) {
            Auth.logout();
            $state.go('loja.home', {id: 'inicio'});
            PopAlert.showSuccess('Sessão foi terminada');
          }
        })
        .state('signup', {
          url: '/signup',
          title: 'Registar',
          templateUrl: 'app/account/signup/signup.html',
          controller: 'SignupController'
        })
        .state('user', {
          abstract: true,
          url: '/utilizador',
          template: '<ui-view/>'
        })
        .state('user.confirm_email', {
          url: '/confirmaemail',
          templateUrl: 'app/account/confirma_email/confirma_email.html',
          controller: 'UtilizadorConfirmaEmailController'
        })
        .state('user.confirm_token', {
          url: '/confirmatoken/:token',
          templateUrl: 'app/account/confirma_token/confirma_token.html',
          controller: 'UtilizadorConfirmaTokenController',
          controllerAs: 'ctrl'
        })
        .state('user.recover_email', {
          url: '/recuperar',
          templateUrl: 'app/account/recuperar/recuperar.html',
          controllerAs: '$ctrl',
          controller: 'UtilizadorRecuperarController'
        })
        .state('user.nova', {
          url: '/nova',
          title: 'Nova palavra-passe',
          templateUrl: 'app/account/nova_palavra_passe/nova_palavra_passe.html',
          controller: 'UtilizadorNovaPalavraPasseController'
        });
    });
}());
